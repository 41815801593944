import styled from 'styled-components/macro';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { MFMedia } from 'app/styles/media-breakpoints';
import { plug } from '../../../../assets';

export const SliderImg = styled.div<{ img: string }>`
  height: 210px;
  border-radius: 8px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: move;
`;

export const ServiceTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px 0 14px 0;
`;

export const ServiceTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #202939;
  text-align: start;
  margin: 0;
`;

export const ServiceCardBlock = styled.div`
  position: relative;
  width: calc(100vw - 32px);
  min-height: 190px;
  padding: 8px;
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgb(75 85 101 / 4%);
  border-radius: 16px;
  overflow: hidden;

  .alice-carousel__stage-item {
    &:not(:nth-child(1)) {
      padding-left: 8px;
    }
  }

  .alice-carousel__dots-item {
    border-radius: 0;
    width: 40px;
    height: 4px;
  }

  .alice-carousel__dots-item {
    background-color: #f7f8fa;
    opacity: 0.65;
    border-radius: 24px;

    &:hover {
      background-color: #ffffff !important;
    }
  }

  .__active {
    opacity: 1;
    background-color: #ffffff !important;
  }

  .alice-carousel__dots {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ServiceDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #4b5565;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 12px;
`;

// export const Star = styled.button`
//   cursor: pointer;
//   color: #49994c;
//   background: #f4f5f7;
//   border-radius: 8px;
//   border: none;
//   width: 24.3px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &:before {
//     font-size: 11px;
//   }
// `;

export const EmptyImg = styled.div`
  background-image: url(${plug});
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  height: 210px;
  width: 100%;
  border-radius: 8px;

  @media (${MFMedia.tablet}) {
    margin: 0 12px 0 0;
    border-radius: 5px;
    width: 158px;
    height: 158px;
  }
`;

export const VideoBlock = styled.div`
  height: 210px;
`;

export const StyledAliceCarousel = styled(AliceCarousel)``;

export const ContactButton = styled.button`
  font-family: SF Pro normal, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;
`;

export const ContactPhoneBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;
`;

export const ToCreateBtn = styled(ContactButton)`
  width: 100%;
`;

export const ContactPhoneBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactButtonsBlock = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #e3e8ef;
`;

export const ServiceCompanyExtraInfoBlock = styled.div`
  display: flex;
  margin: 2px 0;
`;
