import React from 'react';
import { Button, PayerStatus } from '../components';
import { Wrapper, AddressArea } from './stages.styled';
import { getQrPaymentSelector } from '../selectors';
import { useSelector } from 'react-redux';

/** ШАГ ПЕРЕД РЕДИРЕКТОМ НА ЭКВАЙЕР (ИКОНКА, СУММА, ВЫБРАННЫЕ АДРЕС И НАПРАВЛЕНИЕ) **/
/** ../#/qr/payment/finish/ **/
export const Stage7 = () => {
  const { paymentInfo, selectedAddress } = useSelector(getQrPaymentSelector);

  const handlePay = () => {
    //IBillingInfo
    const data = paymentInfo.params as any;
    const form = document.createElement('form');
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.setAttribute('name', key);
        input.setAttribute('value', data[key]);
        input.setAttribute('type', 'hidden');
        form.append(input);
      }
    }
    form.setAttribute('method', 'POST');
    form.setAttribute('action', paymentInfo.processing_url);
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Wrapper>
      <AddressArea>{selectedAddress.address_str}</AddressArea>
      <PayerStatus balance={paymentInfo.params.debt_amount} />
      <Button onClick={() => handlePay()}>
        {Number(paymentInfo.params.debt_amount) === 0
          ? 'Внести аванс'
          : 'Перейти к оплате'}
      </Button>
    </Wrapper>
  );
};
