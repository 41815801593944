import styled from 'styled-components/macro';

export const Group = styled.div`
  width: 100%;
  text-align: center;
  button {
    max-width: 290px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
