import { RootState } from '../../store/store';

/* Полноценный селектор для получения всего */
export const getQrPaymentSelector = ({ qrPayment: state }: RootState) => state;

export const getPhoneSelector = ({ qrPayment: state }: RootState) =>
  state.phone;

export const getEmailSelector = ({ qrPayment: state }: RootState) =>
  state.email;

export const getSelectedAddressSelector = ({ qrPayment: state }: RootState) =>
  state.selectedAddress;

export const getSelectedSector = ({ qrPayment: state }: RootState) =>
  state.selectedSector;
