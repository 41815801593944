import { API } from './api';
import { IBasketItem, interfaceParamsPayment, IOrderPayload } from "../pages/requests/types";

export const GetTemplateRequests = () => API.get('/cabinet/template_request/');

// получение всех корзин в каталоге (у каждой категории своя корзина)
export const GetServerBasket = () => API.get('/cabinet/template_request/receiving_services/');

export const CreateOrder = (order: IOrderPayload) =>
  API.post(`/cabinet/template_request/make_request/`, order);

export const GetRequisites = (params: interfaceParamsPayment) =>
  API.get(`/acquiring/tenant_request_info/`, params);

export const PostToBasket = ({category, services}: {category: string, services: IBasketItem[]}) =>
  API.post(`/cabinet/template_request/service_amount/`, {category, services})