import { PayloadAction } from '@reduxjs/toolkit';

import { hideLoader, showLoader } from '../root-slice';

// Совпадения в названиях экшена, при которых не будет отображаться лоадер
// Можно писать частичное совпадение, например, payments
// Временное решение
const actionsWithoutLoaderMatches = ['accruals/printAccrualReceipt'];

const checkWrongTypes = (actionType: string) =>
  !!actionsWithoutLoaderMatches.filter((name) => actionType.includes(name))
    .length;

export const loaderMiddleware = (store: any) => (next: any) => (
  action: PayloadAction
) => {
  // console.log('store: ', store);
  // console.log('next: ', next);
  // console.log('action: ', action);
  const { type } = action;
  if (
    (type.includes('/pending') &&
      !type.includes('auth') &&
      !checkWrongTypes(type)) ||
    (type.includes('/pending') && type.includes('getUserInfo'))
  ) {
    store.dispatch(showLoader());
  } else if (type.includes('/fulfilled') || type.includes('/rejected')) {
    store.dispatch(hideLoader());
  }
  next(action);
};
