import React, { FC, useState } from 'react';
import usePortal from 'react-useportal';

let resolveFunc: (value: string | PromiseLike<any>) => void;
let rejectFunc: (reason?: any) => void;

/*
  Обязательное условие - наличие подтверждающей функции onConfirm, которую принимает всплывающее окно
 */
export const useAsyncPopup = (
  Popup: FC<{ onConfirm: () => void; onReject?: () => void }>
): [FC, () => Promise<string>] => {
  const { Portal } = usePortal();
  const [show, setShow] = useState(false);

  const handleConfirm = () => {
    setShow(false);
    resolveFunc('');
  };

  const handleReject = () => {
    setShow(false);
    rejectFunc();
  };

  const showPopup = () => {
    setShow(true);
    return new Promise<string>((resolve, reject) => {
      resolveFunc = resolve;
      rejectFunc = reject;
    });
  };

  return [
    () => (
      <>
        {show && (
          <Portal>
            <Popup onConfirm={handleConfirm} onReject={handleReject} />
          </Portal>
        )}
      </>
    ),
    showPopup,
  ];
};
