import styled from 'styled-components/macro';
import { MFMedia } from '../../../../../../styles/media-breakpoints';

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5c7695;

  a {
    color: #4682c7;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ServiceCardBlock = styled.div`
  height: 160px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(174, 189, 206, 0.15);
  border-radius: 16px;
  cursor: pointer;
`;

export const FloatImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ServiceCardContent = styled.div`
  width: 100%;
  margin-left: 24px;
  overflow: hidden;
`;

export const ServiceCoverContainer = styled.div`
  width: 136px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
`;

export const ServiceCardTitleBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const ServiceCardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0b2646;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
`;
export const ServiceCardDescriptionBlock = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px !important;
  min-height: 60px;
  margin-top: 8px;

  @media (${MFMedia.smallPC}) {
    min-height: 63px;
  }
`;

// export const ServicePrice = styled.div`
//   margin: 6px 0;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 22px;
//   color: #0b2646;
// `;

// пока попросили убрать
// export const ServicePriceSale = styled.div`
//   height: 24px;
//   background: #4682c7;
//   border-radius: 4px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 20px;
//   display: flex;
//   align-items: center;
//   color: #ffffff;
//   padding: 4px;
//   margin-right: 8px;
// `;

// export const ActualPrice = styled.div<{ color?: string }>`
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 20px;
//   color: ${(props) => (props.color ? props.color : '#0B2646')};
// `;

// export const Star = styled.button`
//   cursor: pointer;
//   color: #49994c;
//   background: #f4f5f7;
//   border-radius: 8px;
//   border: none;
//   width: 24.3px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &:before {
//     font-size: 11px;
//   }
// `;
