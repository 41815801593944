import {
  Header,
  Name,
  NameRow,
  Wrapper,
} from 'app/components/meters-chart/meters-chart.styled';
import { IMeter } from 'app/pages/meters/types';
import React from 'react';
import { useSelector } from 'react-redux';

import { useConstants } from '../../hooks';
import { getConstantsSelector } from '../../pages/auth/selectors';
import { getMeterReadingNameStr } from '../../pages/meters/utils';
import { MetersAreaChart } from './components/meters-area-chart/meters-area-chart';
import { MetersLineChart } from './components/meters-line-chart/meters-line-chart';
import { getStrokeColor } from './utils';

interface Props {
  meters: IMeter[];
  chartType: 'line' | 'area';
  showMeterNames?: boolean;
  className?: string;
}

export const MetersChart = React.memo(
  ({ meters, showMeterNames, chartType, className }: Props) => {
    const constants = useSelector(getConstantsSelector);
    const { getConstantName } = useConstants('MeterTypeNamesShort');

    return (
      <Wrapper className={className} data-testid={'meters-chart'}>
        <Header>График расхода по счётчикам</Header>
        <NameRow>
          {showMeterNames &&
            meters.map((meter) =>
              meter.first_values.map((value, index) => (
                <Name
                  color={getStrokeColor(meter.type, index)}
                  key={meter.id + index}
                >
                  <div />
                  <span>
                    {getConstantName(meter.type)} {meter.serial_number}{' '}
                    {getMeterReadingNameStr(meter.type, index)}
                  </span>
                </Name>
              ))
            )}
        </NameRow>
        {chartType === 'line' ? (
          <MetersLineChart meters={meters} constants={constants} />
        ) : (
          <MetersAreaChart meters={meters} constants={constants} />
        )}
      </Wrapper>
    );
  }
);
