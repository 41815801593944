import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  color: #071f3b;
  margin-top: 8px;
`;

export const StyledInput = styled.input.attrs({
  type: 'checkbox',
  //id: 'customCheckbox',
})`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    line-height: 20px;
    cursor: pointer;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #dddddd;
    background-color: #ffffff;
    border-radius: 5px;
  }

  &:checked:disabled + label:before,
  &:not(:checked):disabled + label:before {
    background-color: #eff1f5;
    opacity: 0.5;
  }

  &:checked:disabled + label:after,
  &:not(:checked):disabled + label:after {
    opacity: 0.5;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 7px;
    border-radius: 1px;
    border-left: 4px solid #3e78e9; //#ffffff; //#e145a3;
    border-bottom: 4px solid #3e78e9; //#ffffff; //#e145a3;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &:not(:checked) + label:after {
    opacity: 0;
  }

  &:checked + label:after {
    opacity: 1;
  }
`;

export const InputLabel = styled.label.attrs({
  //htmlFor: 'customCheckbox',
})``;
