import styled from 'styled-components/macro';
import { MFMedia } from '../../../../../../styles/media-breakpoints';

export const Message = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4b5565;
  margin: 0 auto;
  text-align: center;

  @media (${MFMedia.tablet}) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
