import styled from 'styled-components/macro';

export const ServiceCompanyWorkTime = styled.div`
  color: #4b5565;
  margin-right: 28px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  div {
    white-space: nowrap;
  }
`;