export const mainTheme = {
  colors: {
    white: '#ffffff',
    darkestBlue: '#0f375e',
    darkBlue: '#0367cc',
    orange: '#F54B18', // Бордер ошибок валидации формы
    darkGray: '#525252', // Лейблы формы
    lightBlueLink: '#3e9ddd', // Ссылка Зарегистрироваться
  },
  gradients: {
    aquaBlue: 'linear-gradient(94.67deg, #54D8E0 -16.74%, #2389AD 102.66%)',
  },
};
