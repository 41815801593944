export const ACCRUAL_ALERT_TEXT =
  'К сожалению, Вы пока не можете внести аванс, т. к. бухгалтерия не выставила ни одной квитанции на Ваш адрес. \n' +
  'Пожалуйста, дождитесь получения первой квитанции и повторите попытку.';

// export const GPB_PAYMENT_ALLOWED_PROVIDERS = [
//   '526234b4e0e34c47438221f9',
//   '61b06a693b6d6e0019260942',
//   '54004843f3b7d41adcce8673',
//   '526234b1e0e34c4743821cb0',
//   '5fc67e2ee04bde00013035c0',
//   '526234b3e0e34c4743822066',
//   '5b2d1167a503b200309b389e',
//   '538f2424f3b7d446df39080b',
//   '526234b3e0e34c4743821fac',
//   '54d9dce8f3b7d439807990f8',
//   '5ba50fa49bc1300034129061',
//   '526234b4e0e34c47438221db',
//   '526234b2e0e34c4743821ee2',
//   '54c2421cf3b7d436e9ea92ec',
//   '60afac227574a9000e097549',
//   '526234b3e0e34c4743821f84',
//   '526234b4e0e34c4743822152',
//   '526234b4e0e34c4743822194',
//   '526234b0e0e34c4743821ae1',
//   '526234b0e0e34c4743821aeb',
//   '526234b0e0e34c4743821af5',
//   '526234b0e0e34c4743821aff',
//   '526234b0e0e34c4743821b06',
//   '526234b0e0e34c4743821b07',
//   '526234b0e0e34c4743821b0f',
//   '526234b0e0e34c4743821b97',
//   '526234b1e0e34c4743821bcd',
//   '526234b1e0e34c4743821be7',
//   '526234b1e0e34c4743821c17',
//   '526234b1e0e34c4743821c26',
//   '526234b1e0e34c4743821c59',
//   '526234b1e0e34c4743821c61',
//   '526234b1e0e34c4743821c76',
//   '526234b1e0e34c4743821cab',
//   '526234b1e0e34c4743821cd1',
//   '526234b1e0e34c4743821cd9',
//   '526234b1e0e34c4743821cf0',
//   '526234b1e0e34c4743821cfb',
//   '526234b1e0e34c4743821d0f',
//   '526234b1e0e34c4743821d1a',
//   '526234b1e0e34c4743821d32',
//   '526234b1e0e34c4743821d46',
//   '526234b1e0e34c4743821d69',
//   '526234b2e0e34c4743821e44',
//   '526234b2e0e34c4743821e4f',
//   '526234b2e0e34c4743821edc',
//   '526234b2e0e34c4743821f0c',
//   '526234b2e0e34c4743821f38',
//   '526234b2e0e34c4743821f49',
//   '526234b2e0e34c4743821f50',
//   '526234b2e0e34c4743821f57',
//   '526234b3e0e34c4743821f6a',
//   '526234b3e0e34c4743821fb2',
//   '526234b4e0e34c47438221b3',
//   '526234b3e0e34c4743821ff9',
//   '526234b3e0e34c4743822021',
//   '526234b3e0e34c4743822029',
//   '526234b3e0e34c474382203f',
//   '526234b3e0e34c4743822043',
//   '526234b3e0e34c474382205a',
//   '526234b3e0e34c4743822063',
//   '526234b3e0e34c4743822072',
//   '526234b3e0e34c4743822084',
//   '526234b3e0e34c4743822087',
//   '526234b3e0e34c474382208d',
//   '526234b3e0e34c47438220ab',
//   '526234b3e0e34c47438220b4',
//   '526234b3e0e34c47438220c6',
//   '526234b3e0e34c47438220cd',
//   '526234b3e0e34c47438220de',
//   '526234b3e0e34c47438220ef',
//   '526234b3e0e34c4743822123',
//   '526234b3e0e34c474382212f',
//   '526234b4e0e34c4743822133',
//   '526234b4e0e34c4743822188',
//   '526234b4e0e34c47438221af',
//   '526234b4e0e34c47438221b5',
//   '526234b4e0e34c47438221bc',
//   '526234b4e0e34c47438221c1',
//   '526234b4e0e34c47438221d7',
//   '526234b4e0e34c47438221fb',
//   '526234b4e0e34c474382220b',
//   '526234b4e0e34c474382222a',
//   '526234b4e0e34c4743822254',
//   '526234b4e0e34c4743822264',
//   '526234b4e0e34c47438222a6',
//   '526234b4e0e34c47438222a9',
//   '526234b4e0e34c47438222b1',
//   '526234b4e0e34c47438222b3',
//   '526234b4e0e34c47438222c6',
//   '526234b4e0e34c47438222e0',
//   '52cfb85db6e69761a2e9a932',
//   '52cadfecb6e69764b20e55a6',
//   '52ca58a7b6e6976511ea6558',
//   '52c9e121b6e697631c2c8487',
//   '52c9acbeb6e697631c2c846c',
//   '52c99085b6e6976440e3d1da',
//   '52c7d1ccb6e697623ee8d9b5',
//   '52c264dfb6e69763dcfa7e09',
//   '52c25c7fb6e69763dcfa7e04',
//   '52c01ae0b6e6974cf7200203',
//   '52b136a1b6e69724052c0953',
//   '526234b4e0e34c474382231c',
//   '526234b4e0e34c4743822319',
//   '526234b4e0e34c4743822312',
//   '526234b4e0e34c47438222fb',
//   '526234b4e0e34c47438222f6',
//   '54005c0ef3b7d41ba8495b7e',
//   '53ba8db5f3b7d40fe2cc142f',
//   '53ba8b04f3b7d40fe2cc1428',
//   '53ba87fef3b7d40d5b7f9738',
//   '53ba7c6ff3b7d40ed2bcca4a',
//   '537617b9f3b7d4579fbd8686',
//   '53070d78b6e6972ca09c18cd',
//   '52e00659b6e697798059dd1a',
//   '53bbb2fdf3b7d467b6b0096b',
//   '54005249f3b7d41a7b425bd4',
//   '5400591ef3b7d41966c4d638',
//   '57e41dec3a6605003d753100',
//   '57f187334e6567003ef40944',
//   '5bf6a46a01e457002ddff3da',
// ]
