/* проверка валидности адреса электронной почты */
export const emailValidator = (email: string): string => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase()) ? email : '';
};

/* преобразования номера телефона из "масочного" вида в строку типа 79993332211 */
export const preparePhone = (ph: string): string => {
  return ph.replace(/\s/g, '').slice(1);
};
