import styled from 'styled-components/macro';

import { ResetButtonMixin } from '../../styles/mixins';

export const Number = styled.span`
  color: #5c6876;
  font-size: 18px;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #89929b;
`;

export const Row = styled.div<{mt?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.mt};
`;

export const Dismiss = styled.button<{ disabled?: boolean; orange?: boolean }>`
  ${ResetButtonMixin};
  color: ${(props) =>
    props.disabled ? 'lightgray' : props.orange ? '#f54b18' : '#56c9fa'};
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) =>
      props.disabled ? 'lightgray' : props.orange ? '#e33b11' : '#2ea9d9'};
  }
`;

export const AddBtn = styled.button<{requests?: boolean}>`
  color: #9dd95c;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: ${(props) => props.requests && '8px !important'};

  & > img {
    margin-right: 10px;
  }

  ${ResetButtonMixin};
`;

export const ConfirmBtn = styled.button<{ disabled: boolean }>`
  margin-top: 4px;
  color: #9dd95c;
  font-size: 16px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${ResetButtonMixin};
`;

export const Input = styled.input`
  border: none;
  outline: none;
  font-size: 18px;
  width: 160px;
  padding-left: 0;
`;

export const Wrapper = styled.div`
  padding: 20px 15px;
  background-color: #ffffff;
`;
