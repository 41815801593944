import calendar from 'assets/svg/calendar.svg';
import styled from 'styled-components/macro';
import { space } from 'styled-system';
import { ResetButtonMixin } from '../../styles/mixins';

export const CalendarIcon = styled.img.attrs({
  src: calendar,
})`
  width: 21px;
`;

export const Calendar = styled.button`
  position: absolute;
  right: 15px;
  ${ResetButtonMixin};
`;

export const Arrow = styled.button`
  position: absolute;
  left: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #4b506d;
  cursor: pointer;

  ${ResetButtonMixin};
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 60px;
`;

export const Nav = styled.div`
  position: relative;
  display: flex;
  color: #89929b;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  ${space};
`;
