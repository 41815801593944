import React from 'react';
import usePortal from 'react-useportal';
import icons from './assets/icons';
import {
  Panel,
  Header,
  Title,
  ElexnetLogo,
  SubTitle,
  Block,
  BlockHeader,
  BlockBody,
  Icon,
  ContentBlock,
  ElexnetConditions,
  StyledButton,
  ButtonsWrapper,
  Fade,
  GazpromLogo,
  LogoHeader,
} from './autopay-info.styled';

interface AutoPayInfoProps {
  onClose: () => any;
  nextStage?: () => any;
  infoWindow: boolean;
}

export const AutoPayInfo = React.memo(
  ({ onClose, nextStage, infoWindow }: AutoPayInfoProps) => {
    const { Portal } = usePortal();
    const linkForPay = 'https://eis24.me/wp-content/uploads/2024/03/elexnet_0324_1.pdf'
    return (
      <Portal>
        <Fade />
        <Panel infoWindow={infoWindow} data-testid={'autopay-info'}>
          <Header>
            <Title>Подключение автоплатежа</Title>
            <LogoHeader>
              <GazpromLogo />
              <ElexnetLogo />
            </LogoHeader>
            <SubTitle>Реализовано совместно с Банк ГПБ (АО) и НКО «Элекснет»</SubTitle>
          </Header>
          <Block>
            <BlockHeader>
              Автоплатежи через Личный кабинет жителя или мобильное приложение —
              это:
            </BlockHeader>
            <BlockBody>
              <ContentBlock>
                <Icon src={icons.Shield} />
                100% гарантия списания спустя несколько дней после выпуска
                квитанций
              </ContentBlock>
              <ContentBlock>
                <Icon src={icons.BigUp} />В случае некорректного списания
                возмещаем все расходы
              </ContentBlock>
              <ContentBlock>
                <Icon src={icons.Clock} />
                Экономия времени на оплате
              </ContentBlock>
              <ContentBlock>
                <Icon src={icons.Attention} />
                Контроль ошибок платежа
              </ContentBlock>
              <ContentBlock>
                <Icon src={icons.Wheel} />
                Автоматическое отключение автоплатежей других банков
              </ContentBlock>
              <ContentBlock>
                <Icon src={icons.Card} />
                Поддержка карт любого банка, работающего с платёжными системами
                MasterCard, VISA, МИР
              </ContentBlock>
            </BlockBody>
          </Block>
          <ElexnetConditions
            href={linkForPay}
            target="_blank"
          >
            Условия предоставления сервиса «Автоплатеж»
          </ElexnetConditions>
          <ButtonsWrapper>
            {nextStage && (
              <StyledButton
                onClick={nextStage}
                variant={'lightblue'}
                size={'sm'}
              >
                Продолжить
              </StyledButton>
            )}
            <StyledButton onClick={onClose} variant={'cancel'} size={'sm'}>
              Назад
            </StyledButton>
          </ButtonsWrapper>
        </Panel>
      </Portal>
    );
  }
);
