import { IRegisterDetails } from '../pages/auth/types';
import { API } from './api';

export const GetConstants = () => API.get('/cabinet/constants/');

export const LogIn = (credentials: any) =>
  API.post('/auth/login/', credentials);

export const LogOut = () => API.post('/auth/logout/');

export const SignUp = (data: IRegisterDetails) =>
  API.post('/registration/sign_in/', {
    last_name: data.surname,
    number: data.accountNumber,
    email: data.email,
  });

export const ResetPassword = (username: string) =>
  API.post('/registration/reset_password/', { username });

export const ActivateAccount = ({
  areaNumber,
  accountId,
}: {
  areaNumber: string;
  accountId: string;
}) =>
  API.post('/registration/second_step_activation/', {
    account: accountId,
    area_number: areaNumber,
  });

export const GetUserInfo = () => API.get('/auth/current/');



export const SwitchAccount = (accountId: string) =>
  API.patch('/auth/switch/', accountId);
