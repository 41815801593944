import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  getCurrentCatalogServiceOrderSelector,
  getCurrentCatalogServiceSelector,
} from '../../../../selectors';
import {
  ServicePrice,
  // ServiceVideo,
  Executor,
  SendOrderBlock,
} from '../../../list/components';
import { formatPhone, replaceTextAreaNewLine } from 'app/utils/getters';
import { useAppDispatch } from 'app/hooks';
import { getPreviewService } from '../../../../catalog-slice';
import { getPriceWithSale, hideCompany } from '../../../../utils';
import {
  ServiceWindow,
  ServiceNavTitle,
  SliderImg,
  ServiceTitle,
  ServiceCardBlock,
  BlurContainter,
  ServiceWindowContainer,
  // VideoBlock,
  EmptyImg,
  NavWrapper,
  DescriptionTitle,
  ContactPhone,
  ContactPhoneBlock,
  ContactPhoneBtnPC,
  ToCreteBtn,
  ContactButtonsBlock,
  ContentBlock,
  ServiceTitleBlock,
  ServiceDescription,
  AliceCarouselPC,
  AliceDotsBlock,
  AliceDot,
  ArrowShortLeft,
  ArrowShortRight,
  SlideNext,
  SlidePrev,
} from './pc-preview.styled';
import { Arrow } from '../../../../catalog.styled';

export const PCPreview = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const refCarousel = useRef<AliceCarousel>(null);
  const { srv } = useParams<{ srv?: string }>();
  const currentOrder = useSelector(getCurrentCatalogServiceOrderSelector);
  const currentService = useSelector(getCurrentCatalogServiceSelector);
  const [emptyCompany, setEmptyCompany] = useState<boolean>(true);
  const [showContactPhone, setShowContactPhone] = useState<boolean>(false);
  const { value, sale, sale_type } = currentService.price;

  useEffect(() => {
    dispatch(getPreviewService({ srv: srv || '' }));
  }, [srv]);

  useEffect(() => {
    if (currentService.company) {
      setEmptyCompany(hideCompany(currentService.company));
    }
  }, [currentService.company]);

  const handleClick = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ServiceWindowContainer>
      {Object.keys(currentService).length && (
        <>
          <BlurContainter />
          <ServiceWindow>
            <Scrollbars
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{ ...props.style, overflowX: 'hidden' }}
                />
              )}
            >
              <NavWrapper>
                <Arrow style={{ left: '7px' }} />
                <ServiceNavTitle>Вернуться</ServiceNavTitle>
              </NavWrapper>
              <ServiceCardBlock>
                {!!currentService.images?.length ||
                !!currentService.media?.length ||
                !!currentService.image_cover?.file ? (
                  <AliceCarouselPC>
                    <AliceCarousel
                      ref={refCarousel}
                      renderDotsItem={() => (
                        <AliceDotsBlock>
                          <AliceDot />
                        </AliceDotsBlock>
                      )}
                      disableButtonsControls
                      autoPlayStrategy={'default'}
                      controlsStrategy={'responsive'}
                    >
                      {[
                        currentService.image_cover?.file && (
                          <SliderImg
                            img={`/api/v4/compendium/preview/${currentService.id}/get_image/?image_cover=${currentService.image_cover.file}`}
                          />
                        ),
                        ...currentService.images.map(
                          (file: any, index: number) => (
                            <SliderImg
                              key={index}
                              img={`/api/v4/compendium/preview/${srv}/get_image/?image_name=${file}`}
                            />
                          )
                        ),
                        ...currentService.media?.map(
                          (file: any, index: number) => (
                            <SliderImg
                              key={index}
                              img={`/api/v4/compendium/preview/${currentService.id}/get_image/?media=${file.file}`}
                            />
                          )
                        ),
                      ].filter(Boolean)}
                    </AliceCarousel>
                    <SlidePrev
                      onClick={(e) => refCarousel?.current?.slidePrev(e)}
                    >
                      <ArrowShortLeft />
                    </SlidePrev>
                    <SlideNext
                      onClick={(e) => refCarousel?.current?.slideNext(e)}
                    >
                      <ArrowShortRight />
                    </SlideNext>
                  </AliceCarouselPC>
                ) : (
                  <EmptyImg />
                )}
                <ContentBlock>
                  <ServiceTitleBlock>
                    <ServiceTitle>{currentService.title}</ServiceTitle>
                  </ServiceTitleBlock>
                  {getPriceWithSale(value, sale, sale_type) >= 0 && (
                    <ServicePrice service={currentService} />
                  )}
                  <ContactButtonsBlock>
                    {!!currentService.company?.phone_number && (
                      <ContactPhoneBlock>
                        <ContactPhoneBtnPC
                          onClick={() => {
                            setShowContactPhone(true);
                          }}
                        >
                          Показать телефон
                        </ContactPhoneBtnPC>
                        {showContactPhone && (
                          <ContactPhone
                            onClick={() => setShowContactPhone(false)}
                          >
                            <span>
                              {formatPhone(currentService.company.phone_number)}
                            </span>
                          </ContactPhone>
                        )}
                      </ContactPhoneBlock>
                    )}
                    <ToCreteBtn onClick={handleClick}>
                      Оставить заявку
                    </ToCreteBtn>
                  </ContactButtonsBlock>
                  {currentService.description && (
                    <>
                      <DescriptionTitle>Описание услуги</DescriptionTitle>
                      <ServiceDescription
                        dangerouslySetInnerHTML={{
                          __html: replaceTextAreaNewLine(
                            currentService.description
                          ),
                        }}
                      />
                    </>
                  )}
                </ContentBlock>
              </ServiceCardBlock>
              {!emptyCompany && (
                <Executor executor={currentService.company} preview />
              )}
              {currentService.contact_executor && (
                <SendOrderBlock
                  ref={ref}
                  currentService={currentService}
                  currentOrder={currentOrder}
                  visibleServiceFullCard
                  preview
                />
              )}
            </Scrollbars>
          </ServiceWindow>
        </>
      )}
    </ServiceWindowContainer>
  );
};
