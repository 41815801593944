import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { getPreviewService } from '../../../../catalog-slice';
import { useAppDispatch } from 'app/hooks';
import { getPriceWithSale, hideCompany } from '../../../../utils';
import {
  getCurrentCatalogServiceOrderSelector,
  getCurrentCatalogServiceSelector,
} from '../../../../selectors';
import {
  Executor,
  SendOrderBlock,
  ServicePrice,
  // ServiceVideo,
} from '../../../list/components';
import { replaceTextAreaNewLine } from 'app/utils/getters';
import {
  ServiceWindow,
  SliderImg,
  ServiceTitle,
  ServiceCardBlock,
  ServiceWindowContainer,
  // VideoBlock,
  EmptyImg,
  DescriptionTitle,
  ContactPhoneBlock,
  ContactPhoneBtnMobile,
  ToCreteBtn,
  ContactButtonsBlock,
  ContentBlock,
  ServiceTitleBlock,
  ServiceDescription,
  ReturnBtn,
  AliceCarouselMobile,
  MobileFraming,
  Background
} from './mobile-preview.styled';
import { ArrowLeft } from '../../../../catalog.styled';

export const MobilePreview = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const { srv } = useParams<{ srv?: string }>();
  const currentOrder = useSelector(getCurrentCatalogServiceOrderSelector);
  const currentService = useSelector(getCurrentCatalogServiceSelector); // открытая услуга
  const [emptyCompany, setEmptyCompany] = useState<boolean>(true);
  const { value, sale, sale_type } = currentService.price;

  useEffect(() => {
    dispatch(getPreviewService({ srv: srv || '' }));
  }, [srv]);

  useEffect(() => {
    if (currentService.company) {
      setEmptyCompany(hideCompany(currentService.company));
    }
  }, [currentService.company]);

  const handleClick = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Background>
      <MobileFraming>
        <ServiceWindowContainer>
          {Object.keys(currentService).length && (
            <ServiceWindow>
              <Scrollbars
                autoHide
                renderView={(props) => (
                  <div
                    {...props}
                    style={{ ...props.style, overflowX: 'hidden' }}
                  />
                )}
              >
                <ReturnBtn>
                  <ArrowLeft />В каталог
                </ReturnBtn>
                <ServiceCardBlock>
                  {!!currentService.images?.length ||
                  !!currentService.media?.length ||
                  !!currentService.image_cover?.file ? (
                    <AliceCarouselMobile>
                      <AliceCarousel
                        disableButtonsControls
                        disableDotsControls
                        mouseTracking
                        autoPlayStrategy={'default'}
                        // 1.05 для небольшого предпросмотра следующего элемента карусели в текущем stage.
                        // Необходимо учесть, что пагинация через DotsControls и ButtonsControls (если она нужна) будет нарушена
                        responsive={{ 0: { items: 1.05 } }}
                      >
                        {[
                          currentService.image_cover?.file && (
                            <SliderImg
                              img={`/api/v4/compendium/preview/${currentService.id}/get_image/?image_cover=${currentService.image_cover.file}`}
                            />
                          ),
                          ...currentService.images.map(
                            (file: any, index: number) => (
                              <SliderImg
                                key={index}
                                img={`/api/v4/compendium/preview/${srv}/get_image/?image_name=${file}`}
                              />
                            )
                          ),
                          ...currentService.media?.map(
                            (file: any, index: number) => (
                              <SliderImg
                                key={index}
                                img={`/api/v4/compendium/preview/${currentService.id}/get_image/?media=${file.file}`}
                              />
                            )
                          ),
                        ].filter(Boolean)}
                      </AliceCarousel>
                    </AliceCarouselMobile>
                  ) : (
                    <EmptyImg />
                  )}
                  <ContentBlock>
                    <ServiceTitleBlock>
                      <ServiceTitle>{currentService.title}</ServiceTitle>
                    </ServiceTitleBlock>
                    {getPriceWithSale(value, sale, sale_type) >= 0 && (
                      <ServicePrice service={currentService} />
                    )}
                    <ContactButtonsBlock>
                      {!!currentService.company?.phone_number && (
                        <ContactPhoneBlock>
                          <ContactPhoneBtnMobile>Позвонить</ContactPhoneBtnMobile>
                        </ContactPhoneBlock>
                      )}
                      <ToCreteBtn onClick={handleClick}>
                        Оставить заявку
                      </ToCreteBtn>
                    </ContactButtonsBlock>
                    {currentService.description && (
                      <>
                        <DescriptionTitle>Описание услуги</DescriptionTitle>
                        <ServiceDescription
                          dangerouslySetInnerHTML={{
                            __html: replaceTextAreaNewLine(
                              currentService.description
                            ),
                          }}
                        />
                      </>
                    )}
                  </ContentBlock>
                </ServiceCardBlock>
                {!emptyCompany && (
                  <Executor executor={currentService.company} preview />
                )}
                {currentService.contact_executor && (
                  <SendOrderBlock
                    ref={ref}
                    currentService={currentService}
                    currentOrder={currentOrder}
                    visibleServiceFullCard
                    preview
                  />
                )}
              </Scrollbars>
            </ServiceWindow>
          )}
        </ServiceWindowContainer>
      </MobileFraming>
    </Background>
  );
};
