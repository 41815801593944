import styled from 'styled-components/macro';
import benefit from '../../layouts/navigation-layout/assets/benefit.svg';
import { Media } from '../../styles/media-breakpoints';

export const Image = styled.img.attrs({
  src: benefit,
})`
  @media (${Media.tablet}) {
    width: 150px;
    height: 150px;
  }
`;

export const Title = styled.div`
  color: #418df9;
  font-size: 22px;
`;

export const SmallText = styled.div`
  color: #939393;
  font-size: 15px;

  & > a {
    text-decoration: none;
    color: #418df9;
  }
`;

export const TopSection = styled.div`
  text-align: center;
`;

export const Wrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  padding: 0 15px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  z-index: 9999;

  ${Title} {
    margin-top: 30px;
  }

  ${SmallText} {
    margin-top: 15px;
  }

  & > button {
    @media (${Media.tablet}) {
      height: 80px;
    }
  }
`;
