import React, { useEffect } from 'react';
import { Button, SectorSelection } from '../components';
import { Wrapper, Header, Explanation } from './stages.styled';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { getQrPaymentSelector } from '../selectors';
import {
  getPaymentInfo,
  getRemainingPaymentInfo,
  selectSector,
} from '../qr-payment-slice';
import { useAppDispatch } from '../../../hooks';
import { unwrapResult } from '@reduxjs/toolkit';

/** ШАГ ВЫБОРА НАПРАВЛЕНИЯ ОПЛАТЫ **/
/** ../#/qr/payment/sector/ **/
export const Stage6 = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    selectedSector,
    selectedAddress,
    tenant_addresses,
    fromRedirect,
    payActionId,
  } = useSelector(getQrPaymentSelector);

  useEffect(() => {
    if (selectedAddress.sectors.length === 1) {
      dispatch(selectSector(selectedAddress.sectors[0]));
    } else {
      dispatch(selectSector({}));
    }
  }, [selectedAddress.sectors]);

  const handleNext = () => {
    if (fromRedirect) {
      dispatch(
        getRemainingPaymentInfo({
          id: payActionId,
          data: {
            tenant_id: selectedAddress.tenant_id,
            sector: selectedSector.value,
          },
        })
      )
        .then(unwrapResult)
        .then(
          (response) => {
            history.push(qrRedirectRoutes.qr.payment.finish.link);
          },
          (error) => {
            //ERROR
          }
        );
    } else {
      dispatch(
        getPaymentInfo({
          tenant_id: selectedAddress.tenant_id,
          sector: selectedSector.value,
          tenant_addresses,
        })
      )
        .then(unwrapResult)
        .then(
          (response) => {
            history.push(qrRedirectRoutes.qr.payment.finish.link);
          },
          (error) => {
            //ERROR
          }
        );
    }
  };

  return (
    <Wrapper>
      <Header>Выберите услугу</Header>
      <Explanation>
        Выберите услугу, по которой Вы хотите произвести оплату.
      </Explanation>
      <SectorSelection />
      <Button disabled={!selectedSector.value} onClick={() => handleNext()}>
        Далее
      </Button>
    </Wrapper>
  );
};
