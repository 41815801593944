import clock from './svg/icn_clock.svg';
import shield from './svg/icn_shield.svg';
import bigup from './svg/icn_bigup.svg';
import attention from './svg/icn_attention.svg';
import wheel from './svg/icn_wheel.svg';
import card from './svg/icn_card.svg';

const Icons = {
  Clock: clock,
  Shield: shield,
  BigUp: bigup,
  Attention: attention,
  Wheel: wheel,
  Card: card,
};

export default Icons;
