import styled from 'styled-components/macro';

export const StyledLoader = styled.label`
  width: 100%;
  height: 83px;
  border: 1px dashed #9DADBF;
  border-radius: 8px;
  color: #56c9fa;
  font-size: 16px;
  //border: 1px solid #56c9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 24px 10px;

  input {
    position: absolute;
    z-index: -999999;
  }
`;

export const FilesRow = styled.div`
  padding: 16px 0;
  span {
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #5C7695;

    button {
      border: 1px solid #f54b18;
      border-radius: 30%;
      background-color: transparent;
      color: #f54b18;
      outline: none;
      padding: 0px 4px;
      cursor: pointer;
    }
  }
`;

export const BtnTextSmall = styled.div`
  color: #9DADBF;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
`

export const BtnText = styled.div`
  color: #9DADBF;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  
  span {
    color: #4682C7;
    margin: 0 4px 0 8px;
  }
`