import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #d5f2fc;
  padding: 8px;
  width: 285px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  z-index: 100;
  position: absolute;
  text-align: left;
  border-radius: 5px;
  top: 0;
  right: 50px;

  & > span {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
`;

export const StyledBr = styled.br``;

export const Title = styled.div`
  font-weight: 500;
  padding-bottom: 5px;
`;

export const LinkArea = styled.div`
  padding-bottom: 20px;
`;

export const Text = styled.div`
  & > p {
    margin-top: 0;
  }
`;
