import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  text-align: center;
  color: #f6c335;
  font-size: 24px;
  letter-spacing: 10px;

  span {
    cursor: pointer;
  }
`;
