import { RootState } from './store';

export const getAuthLoaderState = ({ root }: RootState) => root.authLoader;

export const getLoaderState = ({ root }: RootState) => root.loader;

export const getWarningState = ({ root }: RootState) => root.warning;

export const getTimeWarningState = ({ root }: RootState) => root.timeWarning;

export const getBenefitState = ({ root }: RootState) => root.benefit;

export const getServerErrorState = ({ root }: RootState) => root.serverError;

export const getStartRequestsState = ({ root }: RootState) =>
  root.startRequestsComplete;

export const getBankRedirectMessageState = ({ root }: RootState) =>
  root.redirectFromBank;

export const getShowReceiptImageState = ({ root }: RootState) =>
  root.showReceiptImage;

export const getShowAuthTitle = ({ root }: RootState) => root.showAuthTitle;

export const getShowServicesTitle = ({ root }: RootState) =>
  root.showServicesTitle;
