import styled from 'styled-components/macro';
import { MFMedia } from 'app/styles/media-breakpoints';
import { map_point } from '../../../../assets';

export const CompanyRow = styled.div<{ preview?: boolean }>`
  display: block;
  @media (${MFMedia.tablet}) {
    display: ${(props) => (props.preview ? 'block' : 'flex')};
    align-items: ${(props) => (props.preview ? 'start' : 'center')};;
  }
`;

export const ServiceCompanyBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 16px;
  padding-top: 24px;
  border-top: 1px solid #e3e8ef;

  @media (${MFMedia.tablet}) {
    margin-right: 24px;
  }
`;

export const ServiceCompanyAddress = styled.div`
  color: #202939;
  background-image: url(${map_point});
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 22px;
`;

export const ServiceCompanyName = styled.div`
  color: #202939;
`;

export const ServiceExecutorExtraBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  @media (${MFMedia.tablet}) {
    width: 100%;
  }
`;
