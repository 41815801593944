import React from 'react';
import { useSelector } from 'react-redux';
import usePortal from 'react-useportal';
import { useAppDispatch } from 'app/hooks';
import {
  clearCurrentService,
  clearPostOrderResponse,
} from '../../../../catalog-slice';
import { getCurrentCatalogServiceSelector } from '../../../../selectors';
import {
  PopupFinish,
  PortalBtn,
  PortalCard,
  PortalCardFooter,
  PortalCardHeader,
  PortalImg,
  PortalSection,
  PortalSectionWrapper,
  PortalText,
} from './order-response-modal.styled';
import { finImg } from '../../../../../requests/assets';

export const OrderResponseModal = () => {
  const { Portal } = usePortal();
  const dispatch = useAppDispatch();
  const currentService = useSelector(getCurrentCatalogServiceSelector);

  return (
    <Portal>
      <PortalSectionWrapper>
        <PortalSection>
          <PopupFinish>
            <PortalCard>
              <PortalCardHeader>Заказ отправлен</PortalCardHeader>
              <PortalImg src={finImg} />
              <PortalText>
                Ваш заказ на услугу "{currentService.title}" успешно оформлен! В
                ближайшее время с Вами свяжутся для уточнения деталей по заказу.
              </PortalText>
              <PortalCardFooter>
                <PortalBtn
                  onClick={() => {
                    dispatch(clearCurrentService());
                    dispatch(clearPostOrderResponse());
                  }}
                >
                  Ок
                </PortalBtn>
              </PortalCardFooter>
            </PortalCard>
          </PopupFinish>
        </PortalSection>
      </PortalSectionWrapper>
    </Portal>
  );
};
