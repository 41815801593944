/* Слайс, хранящий и авторизацию, и регистрацию, и инфу о залогиненом текущем юзере */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  /* Лоадер с ключиком, работающий,
   * пока не появится первоначальная информация из auth/current
   */
  authLoader: {
    show: boolean;
  };
  /* Главный лоадер */
  loader: {
    show: boolean;
    amount: number; // Количество открытых лоадеров, нужно, что бы случайное событие закрытия лоадера не закрывало все остальные
  };
  /* Модалка "Упс... что-то пошло не так */
  warning: {
    show: boolean;
  };
  /* Модалка с ошибкой по частоте времени оплаты */
  timeWarning: {
    show: boolean;
  };
  /* Модалка "Оплата недоступна "Льготники" */
  benefit: {
    show: boolean;
  };
  /* Модалка с ошибкой, содержащей текст с сервера */
  serverError: {
    show: boolean;
    text: string[];
  };
  /* Стартовая вереница запросов отработала */
  startRequestsComplete: boolean;
  redirectFromBank: boolean;
  showReceiptImage: boolean;
  showAuthTitle: boolean;
  showServicesTitle: boolean;
}

export const initialState: InitialState = {
  authLoader: {
    show: false,
  },
  loader: {
    show: false,
    amount: 0,
  },
  warning: {
    show: false,
  },
  timeWarning: {
    show: false,
  },
  benefit: {
    show: false,
  },
  serverError: {
    show: false,
    text: [],
  },
  startRequestsComplete: false,
  redirectFromBank: true,
  showReceiptImage: false,
  showAuthTitle: true,
  showServicesTitle: true,
};

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    showAuthLoader: (state) => {
      state.authLoader.show = true;
    },
    hideAuthLoader: (state) => {
      state.authLoader.show = false;
    },
    showLoader: (state) => {
      // Сделать таким образом, что бы вызовы показа пушили в массив показы, а закрытия - их сплайсили
      // Что бы лоадер показывался 1 раз на вереницу запросов, и можно было бы его анимировать
      state.loader.show = true;
      state.loader.amount += 1;
    },
    hideLoader: (state) => {
      if (state.loader.amount !== 0) {
        state.loader.amount -= 1;
        if (state.loader.amount === 0) {
          state.loader.show = false;
        }
      }
    },
    showWarning: (state) => {
      state.warning.show = true;
    },
    hideWarning: (state) => {
      state.warning.show = false;
    },
    showTimeWarning: (state) => {
      state.timeWarning.show = true;
    },
    hideTimeWarning: (state) => {
      state.timeWarning.show = false;
    },
    showBenefit: (state) => {
      state.benefit.show = true;
    },
    hideBenefit: (state) => {
      state.benefit.show = false;
    },
    hideBankRedirectMessage: (state) => {
      state.redirectFromBank = false;
    },
    showServerError: (state, action: PayloadAction<string[]>) => {
      state.serverError = {
        ...state.serverError,
        show: true,
        text: action.payload,
      };
    },
    hideServerError: (state) => {
      state.serverError = {
        ...state.serverError,
        show: false,
      };
    },
    setStartRequestsComplete: (state) => {
      state.startRequestsComplete = true;
    },
    setShowReceiptImage: (state) => {
      state.showReceiptImage = !state.showReceiptImage;
    },
    setShowAuthTitle: (state) => {
      state.showAuthTitle = !state.showAuthTitle;
    },
    setShowServicesTitle: (state) => {
      state.showServicesTitle = !state.showServicesTitle;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  showAuthLoader,
  hideAuthLoader,
  showLoader,
  hideLoader,
  showWarning,
  hideWarning,
  showTimeWarning,
  hideTimeWarning,
  showBenefit,
  hideBenefit,
  showServerError,
  hideServerError,
  setStartRequestsComplete,
  hideBankRedirectMessage,
  setShowReceiptImage,
  setShowAuthTitle,
  setShowServicesTitle,
} = rootSlice.actions;

export default rootSlice.reducer;
