import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthLoaderState } from '../../store/root-selectors';

import { Dots, Logo, Section, Wrapper } from './auth-loader.styled';

export const AuthLoader = React.memo(() => {
  const { show } = useSelector(getAuthLoaderState);

  if (!show) {
    return <></>;
  }

  return (
    <Wrapper>
      <Section>
        <Logo />
        <Dots>
          <div></div>
          <div></div>
          <div></div>
        </Dots>
      </Section>
    </Wrapper>
  );
});
