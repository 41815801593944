import React, { memo, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  CalendarIcon,
  Period,
  Wrapper,
  RangePickerSection,
} from './date-range.styled';

export type IDateRangeDates = [Date, Date];

interface Props {
  dateFrom: Date;
  dateTill: Date;
  onChange: (dates: IDateRangeDates) => void;
}

const dateFormat = 'MM.yyyy';
const locale = 'ru';

export const DateRange = memo(({ dateFrom, dateTill, onChange }: Props) => {
  const handleChangeStartDate = (date: Date) => onChange([date, dateTill]);
  const handleChangeEndDate = (date: Date) => onChange([dateFrom, date]);
  const handleChangeRange = (dates: IDateRangeDates) => {
    onChange(dates);
  };

  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const [openPeriodCalendar, setOpenPeriodCalendar] = useState(false);

  return (
    <Wrapper>
      <Period>
        <DatePicker
          locale={locale}
          dateFormat={dateFormat}
          selected={dateFrom}
          onChange={handleChangeStartDate}
          selectsStart
          startDate={dateFrom}
          endDate={dateTill}
          showMonthYearPicker
          open={openStartDatePicker}
          onInputClick={
            !openPeriodCalendar && !openEndDatePicker
              ? () => setOpenStartDatePicker(!openStartDatePicker)
              : () => {}
          }
          onClickOutside={() => setOpenStartDatePicker(false)}
        />
        <span>&#8212;</span>
        <DatePicker
          locale={locale}
          dateFormat={dateFormat}
          selected={dateTill}
          onChange={handleChangeEndDate}
          selectsEnd
          startDate={dateFrom}
          endDate={dateTill}
          // minDate={dateFrom} Странно работает почему-то
          showMonthYearPicker
          open={openEndDatePicker}
          onInputClick={
            !openPeriodCalendar && !openStartDatePicker
              ? () => setOpenEndDatePicker(!openEndDatePicker)
              : () => {}
          }
          onClickOutside={() => setOpenEndDatePicker(false)}
        />
        <RangePickerSection>
          {openPeriodCalendar && (
            <DatePicker
              locale={locale}
              selected={dateFrom}
              onChange={handleChangeRange}
              startDate={dateFrom}
              endDate={dateTill}
              selectsRange
              inline
              showMonthYearPicker
              onClickOutside={() => setOpenPeriodCalendar(false)}
            />
          )}
        </RangePickerSection>
        <CalendarIcon
          onClick={() => setOpenPeriodCalendar(!openPeriodCalendar)}
        />
      </Period>
    </Wrapper>
  );
});
