import { Group } from 'app/components/button-group/button-group.styled';
import { Button } from 'app/components/';
import React from 'react';

interface Props {
  onSubmit?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  dismissText?: string;
  disableConfirm?: boolean;
  confirmButtonType?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
}

export const ButtonGroup = React.memo(
  ({
    confirmText = 'Подключить',
    dismissText = 'Отмена',
    disableConfirm = false,
    onSubmit,
    onCancel,
    confirmButtonType = 'button',
    className,
  }: Props) => {
    return (
      <Group className={className} data-testid={'button-group'}>
        <Button
          type={confirmButtonType}
          onClick={onSubmit}
          size={'lg'}
          variant={'lightblue'}
          disabled={disableConfirm}
        >
          {confirmText}
        </Button>
        <Button onClick={onCancel} size={'lg'} variant={'cancel'}>
          {dismissText}
        </Button>
      </Group>
    );
  }
);
