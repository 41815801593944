import styled from 'styled-components/macro';
import { Br } from './card';

enum borderRadius {
  'xl' = '20px',
  lg = '15px',
  md = '10px',
  sm = '6px',
}

export const Wrapper = styled.div<{ br: Br }>`
  border-radius: ${(props) => borderRadius[props.br]};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;
