export const Resolutions = {
  PC: 1380,
  tablet: 768,
  mobile: 480,
};

export const Media = {
  smallPC: `max-width: ${window.devicePixelRatio * Resolutions.PC}px`,
  tablet: `max-width: ${window.devicePixelRatio * Resolutions.tablet}px`,
  mobile: `max-width: ${window.devicePixelRatio * Resolutions.mobile}px`,
};

export const MFMedia = {
  pc: `min-width: ${window.devicePixelRatio * Resolutions.PC}px`,
  smallPC: `min-width: ${window.devicePixelRatio * Resolutions.tablet}px`,
  tablet: `min-width: ${window.devicePixelRatio * Resolutions.mobile}px`,
};
