import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useConfirm } from './use-confirm';
import { useConfirmHtml } from './use-confirm-html';
import { useHistory } from 'react-router-dom';
import { authorizedRoutes, unauthorizedRoutes } from '../routesConfig';
import { hideBankRedirectMessage } from '../store/root-slice';
import { useAppDispatch } from './redux-hooks';
import { getBankRedirectMessageState } from '../store/root-selectors';
import { useSelector } from 'react-redux';

export const useRedirectRoute = (
  paymentMessage: React.ReactNode | React.ReactChild,
  autopayMessage: React.ReactNode | React.ReactChild,
  auth: boolean
) => {
  const show = useSelector(getBankRedirectMessageState);
  const history = useHistory();
  // const [Alert, openAlert] = useConfirm();
  const [Alert, openAlert, closeAlert] = useConfirmHtml();
  const { where } = useParams<{ where: string }>();
  const dispatch = useAppDispatch();

  const asyncAlertPayment = async () => {
    await openAlert({
      title: 'Информация',
      children: paymentMessage,
       confirmText: 'Подробнее', //auth ? 'Подробнее' : 'Ок',
      type: 'alert',
    });
  };

  const asyncAlertAutopay = async () => {
    await openAlert({
      title: 'Автоплатёж успешно подключен',
      children: autopayMessage,
      confirmText: 'Ок',
      type: 'alert',
    });
  };

  //http://localhost:9000/#/main/from/bank

  //http://localhost:9000/#/main/from/bank_payment
  //http://localhost:9000/#/main/from/bank_autopay

  useEffect(() => {
    if (where === 'bank_payment' && show) {
      asyncAlertPayment().then(() => {
        if (auth) {
          //window.open('https://roscluster.ru/pay-and-win/');

          const a = document.createElement('a');
          a.setAttribute('href', 'https://roscluster.ru/pay-and-win/');
          a.setAttribute('target', '_blank');
          a.click();
          a.remove();

          history.push(authorizedRoutes.main.dashboard.link);
          dispatch(hideBankRedirectMessage());
        } else {
          //window.open('https://roscluster.ru/pay-and-win/');

          const a = document.createElement('a');
          a.setAttribute('href', 'https://roscluster.ru/pay-and-win/');
          a.setAttribute('target', '_blank');
          a.click();
          a.remove();

          history.push(unauthorizedRoutes.auth.login.link);
          dispatch(hideBankRedirectMessage());
        }
      });
    }
    if (where === 'bank_autopay' && show) {
      asyncAlertAutopay().then(() => {
        if (auth) {
          history.push(authorizedRoutes.main.dashboard.link);
          dispatch(hideBankRedirectMessage());
        } else {
          history.push(unauthorizedRoutes.auth.login.link);
          dispatch(hideBankRedirectMessage());
        }
      });
    }
    if (!where && show) {
      //TODO: НЕЛЬЗЯ ТАК ПОРТИТЬ УНИВЕРСАЛЬНЫЕ ЭЛЕМЕНТЫ
      dispatch(hideBankRedirectMessage());
      closeAlert();
    }
  }, [where]);

  return Alert;
};
