import { css } from 'styled-components/macro';

export const Flex = {
  rowNoWrap: `
    display: flex;
    flex-flow: now nowrap;
  `,
  columnNoWrap: `
    display: flex;
    flex-flow: column nowrap;
  `,
};

export const ResetButtonMixin = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
`;

export const ResetInputMixin = css`
  background-color: transparent;
  border: none;
  outline: none;
`;

export const AwesomeSliderDotsMixin = css`
  // Точечки
  &&& .awssld__bullets {
    bottom: 4px;
    z-index: 10;

    button {
      background: #c8c8c8;
      opacity: 0.5;
      width: 12px;
      height: 12px;
    }

    .awssld__bullets--active {
      transform: scale(1);
      width: 12px;
      height: 12px;
      opacity: 1;
      background-color: #56c9fa;
      box-shadow: 0 4px 7px rgba(41, 41, 41, 0.27);
    }
  }
`;

export const AwesomeSliderArrowsMixin = css`
  &&& .awssld__next,
  .awssld__prev {
    //top: unset;
    //bottom: 4px;
    //top: unset;
    //bottom: -10px;
    & > span {
      height: 14px;

      &:before,
      &:after {
        background-color: #cdcdcd;
        height: 14px;
        width: 2px;
      }
    }
  }

  &&& .awssld__next {
    & > span {
      &:before,
      &:after {
        right: 0;
      }
    }
  }

  &&& .awssld__prev {
    & > span {
      &:before,
      &:after {
        left: 0;
      }
    }
  }
`;

export const SmallCheckBoxToggleMixin = css`
  & > span {
    padding-right: 17px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(25, 42, 62, 0.4);
  }

  & > label {
    width: 43px;
    height: 20px;

    & > div {
      background: #ffffff;
      border: 0.7px solid #dadada;

      &:before {
        height: 16px;
        width: 16px;
        background: #a0a0a0;
      }
    }

    & > input:checked + div {
      background-color: #fff !important;
    }

    & > input:checked + div:before {
      transform: translateX(23px) !important;
      background: #3fbaff;
    }
  }
`;

export const AwesomeSliderArrowsWhiteMixin = css`
  &&& .awssld__next,
  .awssld__prev {
    & > span {
      &:before,
      &:after {
        background-color: #ffffff;
      }
    }
  }
`;

export const AwesomeSliderDotsWhiteMixin = css`
  &&&& .awssld__bullets {
    bottom: 4px;

    .awssld__bullets--active {
      opacity: 1;
      background-color: #ffffff;
    }
  }
`;

export const ButtonHoverMixin = {
  green: css`
    transition: background-color 0.3s ease;

    &:hover {
      background: #96d254;
    }
  `,
};
