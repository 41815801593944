import styled from 'styled-components/macro';
import { ResetButtonMixin } from 'app/styles/mixins';

export const StyledField = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 20px;
  `,
  Eye: styled.button`
    ${ResetButtonMixin};
    position: absolute;
    font-size: 17px;
    top: 13px;
    right: 38px;
    cursor: pointer;
    color: #334d6e;
    width: 26px;
    display: flex;
    justify-content: center;
  `,
  Input: styled.input`
    width: 100%;
    height: 42px;
    border: 1px solid #efeff4;
    background: #ffffff;
    border-radius: 9px;
    outline: none;
    padding: 13px 10px;
  `,
  Error: styled.div`
    color: #f54b18;
    font-size: 13px;
    height: 13px;
  `,
  Button: styled.button`
    ${ResetButtonMixin};
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 9px;

    img {
      width: 24px;
    }

    &:disabled {
      cursor: unset;
      opacity: 0.6;
    }
  `,
};
