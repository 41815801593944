import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showWarning } from 'app/store/root-slice';
import { RootState } from 'app/store/store';
import {
  GetAddresses,
  IGetAddresses,
  GetPaymentInfo,
  IGetPaymentInfo,
  GetRemainingAddresses,
  IGetRemainingAddresses,
  GetRemainingPaymentInfo,
  IGetRemainingPaymentInfo,
  InitCall,
  IInitCall,
  SendCode,
  ISendCode,
} from '../../api/qr';

interface IAddress {
  address_str: string;
  sectors: ISector[];
  tenant_id: string;
}

interface ISector {
  text: string;
  value: string;
}

interface IBillingInfo {
  bank_name: string;
  bank_number: string;
  bank_url: string;
  bic: string[];
  correspondent: string;
  inn: string;
  params: {
    account: string;
    amount: string;
    area_name: string;
    attr_0: null | string;
    attr_1: null | string;
    attr_2: null | string;
    attr_3: null | string;
    attr_4: null | string;
    debt_amount: string;
    debt_as_default: string;
    email: string;
    service: string;
    url_return: string;
  };
  processing_url: string;
  provider_name: string;
  sector: string;
  service_type: string;
}

export interface InitialState {
  phone: string; // Введённый номер телефона
  email: string; // Введйнный емейл
  code: string; // Введйнный код подтверждения
  timer: number; // Таймер
  tenant_addresses: IAddress[]; //Адреса и направления
  selectedAddress: IAddress; //Выбранный адрес
  selectedSector: ISector; //Выбранное направление оплаты
  paymentInfo: IBillingInfo; //Данные для эквайера
  fromRedirect: boolean; //Если был редирект с банка
  payActionId: string; //id текущей сессии оплаты (НЕ сессии в прямом смысле). Прихраняет данные шагов на бэке
  callTaskId: string; //Задача на дозвон
}

export const initialState: InitialState = {
  phone: '',
  email: '',
  code: '',
  timer: 30,
  tenant_addresses: [],
  selectedAddress: {} as IAddress,
  selectedSector: {} as ISector,
  paymentInfo: {} as IBillingInfo,
  fromRedirect: false,
  payActionId: '',
  callTaskId: '',
};

export const initCall = createAsyncThunk<any, IInitCall, any>(
  'qr-payment/initCall',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await InitCall(params);
    } catch (e) {
      // dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

interface ISendCodeWithId {
  id: string;
  data: ISendCode;
}

export const sendCode = createAsyncThunk<any, ISendCodeWithId, any>(
  'qr-payment/sendCode',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await SendCode(params.id, params.data);
    } catch (e) {
      // dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getAddresses = createAsyncThunk<any, IGetAddresses, any>(
  'qr-payment/getAddresses',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await GetAddresses(params);
    } catch (e) {
      // dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getRemainingAddresses = createAsyncThunk<
  any,
  IGetRemainingAddresses,
  any
>(
  'qr-payment/getRemainingAddresses',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await GetRemainingAddresses(params);
    } catch (e) {
      // dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getPaymentInfo = createAsyncThunk<any, IGetPaymentInfo, any>(
  'qr-payment/getPaymentInfo',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await GetPaymentInfo(params);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

interface IGetRemainingPaymentInfoWithId {
  id: string;
  data: IGetRemainingPaymentInfo;
}

export const getRemainingPaymentInfo = createAsyncThunk<
  any,
  IGetRemainingPaymentInfoWithId,
  any
>(
  'qr-payment/getRemainingPaymentInfo',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      return await GetRemainingPaymentInfo(params.id, params.data);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const qrPaymentSlice = createSlice({
  name: 'qr-payment',
  initialState,
  reducers: {
    makeRedirectState: (state, action) => {
      state.fromRedirect = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateCode: (state, action) => {
      state.code = action.payload;
    },
    selectAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    selectSector: (state, action) => {
      state.selectedSector = action.payload;
    },
    updateTimer: (state) => {
      //TODO: сделать иначе, чтобы при перезапуске таймера не было нуля вначале
      if (state.timer > 0) {
        state.timer = state.timer - 1;
      } else {
        state.timer = 30;
      }
    },
    clearTimer: (state) => {
      state.timer = 30;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initCall.fulfilled, (state, action) => {
      state.callTaskId = action.payload.call_task_id || '';
    });
    builder.addCase(getAddresses.fulfilled, (state, action) => {
      state.tenant_addresses = action.payload.tenant_addresses || [];
    });
    builder.addCase(getAddresses.rejected, (state, action) => {
      //...
    });
    builder.addCase(getPaymentInfo.fulfilled, (state, action) => {
      state.paymentInfo = action.payload;
    });
    builder.addCase(getPaymentInfo.rejected, (state, action) => {
      //...
    });
    builder.addCase(getRemainingAddresses.fulfilled, (state, action) => {
      state.tenant_addresses = action.payload.tenant_addresses || [];
      state.payActionId = action.payload.pay_action_id || '';
    });
    builder.addCase(getRemainingAddresses.rejected, (state, action) => {
      //...
    });
    builder.addCase(getRemainingPaymentInfo.fulfilled, (state, action) => {
      state.paymentInfo = action.payload || {};
    });
    builder.addCase(getRemainingPaymentInfo.rejected, (state, action) => {
      //...
    });
  },
});

export const {
  updatePhone,
  updateEmail,
  updateCode,
  updateTimer,
  clearTimer,
  selectAddress,
  selectSector,
  makeRedirectState,
} = qrPaymentSlice.actions;

export default qrPaymentSlice.reducer;
