import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useState,
} from 'react';

import {
  Area,
  ErrorMessage,
  Field,
  Input,
  Label,
  Note,
  StyledLink,
  MaxLengthIndicator,
} from './form-field.styled';
import placeholder from 'lodash/fp/placeholder';

type Props = {
  serverError?: any;
  length?: number;
  type?: any;
  register?: any;
  errors?: any;
  label: string;
  className?: string;
  link?: {
    href: string;
    title: string;
  };
  note?: string;
  maxLengthValue?: number;
};

const TextField = React.memo(
  ({
    serverError,
    length,
    type,
    register,
    name,
    label,
    errors,
    className,
    link,
    note,
    tabIndex,
    ...props
  }: Props & InputHTMLAttributes<any>) => (
    <Field className={className}>
      <Label htmlFor={name}>{label}</Label>
      {link?.href && <StyledLink to={link.href}>{link.title}</StyledLink>}
      {note?.length && <Note>{note}</Note>}
      <Input
        maxLength={length}
        type={type}
        error={errors}
        name={name}
        ref={register}
        tabIndex={tabIndex}
        {...props}
      />
      {serverError ? (
        <ErrorMessage>{serverError}</ErrorMessage>
      ) : (
        <ErrorMessage>{errors}</ErrorMessage>
      )}
    </Field>
  )
);

const AreaField = React.memo(
  ({
    register,
    name,
    label,
    errors,
    className,
    link,
    note,
    maxLengthValue,
    ...props
  }: Props & TextareaHTMLAttributes<any>) => {
    const [value, setValue] = useState('');
    return (
      <Field className={className}>
        <Label htmlFor={name}>{label}</Label>
        {link?.href && <StyledLink to={link.href}>{link.title}</StyledLink>}
        {note?.length && <Note>{note}</Note>}
        <Area
          maxLength={maxLengthValue}
          error={errors}
          name={name}
          ref={register}
          // Во избежание конфликтов с валидатором, закомментировано
          // value={value}
          onChange={(e) => setValue(e.target.value)}
          {...props}
        />
        <ErrorMessage>{errors}</ErrorMessage>
        {maxLengthValue && (
          <MaxLengthIndicator title={'Максимально допустимая длина сообщения'}>
            {value.length} / {maxLengthValue}
          </MaxLengthIndicator>
        )}
      </Field>
    );
  }
);

export const FormField = {
  Text: TextField,
  Area: AreaField,
};
