import { MFMedia } from 'app/styles/media-breakpoints';
import styled from 'styled-components/macro';
import { ResetButtonMixin } from '../../styles/mixins';

export const Title = styled.div`
  color: #334d6e;
  font-size: 20px;
  font-weight: 600;
`;

export const Number = styled.div`
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  flex: 1;
`;

export const Remove = styled.button`
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;

  ${ResetButtonMixin};
`;

export const Card = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  margin-bottom: 6px;
  background-color: ${(props) => (props.selected ? '#23A6F0' : 'transparent')};
  border-radius: 10px;

  ${Number} {
    color: ${(props) => (props.selected ? '#ffffff' : '#3E3E3E')};
  }

  ${Remove} {
    color: ${(props) => (props.selected ? '#ffffff' : '#FF794F')};
  }
`;

export const CardList = styled.div`
  margin-top: 25px;
`;

export const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  padding: 31px 15px 60px 15px;
  border-radius: 21px 21px 0 0;
  border-top: 1px solid #e8e8e8;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media (${MFMedia.smallPC}) {
    width: 500px;
    right: unset;
    bottom: unset;
    left: 58%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    padding: 65px 25px 40px 25px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  }
`;
