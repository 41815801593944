import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper, Section } from './address-selection.styled';
import {
  getSelectedAddressSelector,
  getQrPaymentSelector,
} from '../../selectors';
import { selectAddress } from '../../qr-payment-slice';
import { useAppDispatch } from '../../../../hooks';

interface IAddress {
  address_str: string;
  sectors: {
    text: string;
    value: string;
  }[];
  tenant_id: string;
}

export const AddressSelection = React.memo(() => {
  const dispatch = useAppDispatch();

  const selectedAddress = useSelector(getSelectedAddressSelector);
  const { tenant_addresses } = useSelector(getQrPaymentSelector);

  const handleSelect = (index: number) => {
    dispatch(selectAddress(tenant_addresses[index]));
  };

  return (
    <Wrapper>
      {tenant_addresses.map((item: IAddress, index: number) => (
        <Section
          key={index}
          selected={item.address_str === selectedAddress.address_str}
          onClick={() => handleSelect(index)}
        >
          {item.address_str}
        </Section>
      ))}
    </Wrapper>
  );
});
