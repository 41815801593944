import { RootState } from 'app/store/store';

export const getCatalogServiceListSelector = ({ catalog: state }: RootState) =>
  state.list;

export const getCatalogCategoriesListSelector = ({ catalog: state }: RootState) =>
  state.categories;

export const getCatalogServicesBySearchSelector = ({ catalog: state }: RootState) =>
  state.filtered;

export const getCurrentCatalogServiceSelector = ({ catalog: state }: RootState) =>
  state.currentService;

export const getSearchValueSelector = ({ catalog: state }: RootState) =>
  state.searchValue;

export const getCurrentCategorySelector = ({ catalog: state }: RootState) =>
  state.currentCategory;

export const getCurrentCatalogServiceOrderSelector = ({ catalog: state }: RootState) =>
  state.currentOrder;

export const getPostOrderResponseSelector = ({ catalog: state }: RootState) =>
  state.postOrderResponse;

export const getServiceItemFullCardSelector = ({ catalog: state }: RootState) =>
  state.serviceItemFullCard;

export const getCatalogStatusesSelector = ({ catalog: state }: RootState) =>
  state.statuses;
