import styled from 'styled-components/macro';
import { plug } from '../../../../assets';
import { mobile_framing } from '../../assets';

export const ServiceWindow = styled.div`
  width: 324px;
  margin: 48px auto 0 auto;
  background: #ffffff;
  padding-left: 16px;

  .alice-carousel__stage-item {
    &:not(:nth-child(1)) {
      padding-left: 8px;
    }
  }

  .__active {
    span {
      opacity: 1;
      background-color: #f7f8fa;
    }
  }

  .alice-carousel__dots-item {
    &:not(.__custom):not(:last-child) {
      margin-right: 5px;
    }
  }

  .alice-carousel__dots {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const SliderImg = styled.div<{ img: string }>`
  height: 234px;
  width: 100%;
  border-radius: 8px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: move;
`;

export const ServiceTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 24px 0 8px 0;
`;

export const ServiceTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #202939;
  text-align: start;
  margin: 0;
`;

export const ServiceCardBlock = styled.div`
  position: relative;
  min-height: 190px;
  border-radius: 16px;
  padding: 0 0 16px 0;

  .alice-carousel {
    padding-right: 16px;
  }
`;

// export const Star = styled.button`
//   cursor: pointer;
//   color: #49994c;
//   background: #f4f5f7;
//   border-radius: 8px;
//   border: none;
//   width: 24.3px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &:before {
//     font-size: 11px;
//   }
// `;

// export const VideoBlock = styled.div`
//   height: 234px;
// `;

export const ServiceWindowContainer = styled.div`
  display: flex;
  //height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 56px);
  width: 100%;
  z-index: 300;
`;

export const ContactButton = styled.button`
  font-family: SF Pro normal, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;
`;

export const ContactPhoneBtnMobile = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;
`;

export const ToCreteBtn = styled(ContactButton)`
  display: block;
  width: 100%;
`;

export const DescriptionTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: end;
  justify-content: start;
  margin: 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #202939;
`;

export const ContentBlock = styled.div`
  padding-right: 16px;
`;

export const ContactPhoneBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactButtonsBlock = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 24px;
`;

export const EmptyImg = styled.div`
  background-image: url(${plug});
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  height: 210px;
  width: 100%;
  border-radius: 8px;
`;

export const ServiceDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #202939;
`;

export const ReturnBtn = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  white-space: nowrap;
`;

export const AliceCarouselMobile = styled.div`
  display: block;
`;

export const MobileFraming = styled.div`
  background-image: url('${mobile_framing}');
  background-repeat: no-repeat;
  background-size: contain;
  width: 340px;
  height: 707px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 350;
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #FFFFFF;
  z-index: 999;
`