import React from 'react';
import {
  Title,
  Button,
  Note,
  Phone,
  Swipe,
  ImageContainer,
  Wrapper,
} from './iphone-file-notify.styled';

interface Props {
  onConfirm: () => void;
}

export const IPhoneFileNotify = ({ onConfirm }: Props) => (
  <Wrapper data-testid={'iphone-file-notify'}>
    <ImageContainer>
      <Swipe />
      <Phone />
    </ImageContainer>
    <Title>Подсказка</Title>
    <Note>
      Сейчас Вы откроете документ. Чтобы вернуться в приложение, смахните экран
      вправо.
    </Note>
    <Button onClick={onConfirm}>Понятно</Button>
  </Wrapper>
);
