import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Explanation,
  Header,
  Wrapper,
  FootNote,
  ConfidenceLink,
} from './stages.styled';
import { Button, EmailInput } from '../components';
import { getQrPaymentSelector } from '../selectors';
import { useHistory } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { useAppDispatch } from '../../../hooks';
import { getAddresses } from '../qr-payment-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { preparePhone, emailValidator } from '../utils';

/** ШАГ ВВОДА АДРЕСА ЭЛЕКТРОННОЙ ПОЧТЫ **/
/** ../#/qr/payment/email/ **/
export const Stage3 = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);
  const { phone, email } = useSelector(getQrPaymentSelector);

  useEffect(() => {
    if (!email.length) {
      setError(false);
    }
  }, [email]);

  const handleNext = () => {
    let a = emailValidator(email);
    if (!a.length) {
      setError(true);
    } else {
      setError(false);

      dispatch(getAddresses({ phone: preparePhone(phone), email: email }))
        .then(unwrapResult)
        .then(
          (response) => {
            if (response.tenant_addresses.length) {
              history.push(qrRedirectRoutes.qr.payment.address.link);
            } else {
              if (response.status === 'denied') {
                history.push(qrRedirectRoutes.qr.payment.blocked.link);
              } else {
                history.push(qrRedirectRoutes.qr.payment.register.link);
              }
            }
          },
          (error) => {
            //ERROR
          }
        );
    }
  };

  return (
    <Wrapper>
      <Header>Введите Ваш e-mail</Header>
      <Explanation>
        Нам необходимы эти данные, так как мы не нашли Ваш номер телефона в
        базе.
      </Explanation>
      <EmailInput isError={error} />
      <Button disabled={!email.length} onClick={() => handleNext()}>
        Далее
      </Button>
      <FootNote>
        Нажимая кнопку «Далее», я даю согласие на обработку своих персональных
        данных в соответствии с{' '}
        <ConfidenceLink
          href={'https://eis24.me/privacy-policy/'}
          target={'_blank'}
        >
          Политикой конфиденциальности
        </ConfidenceLink>
      </FootNote>
    </Wrapper>
  );
};
