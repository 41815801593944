import { IPhone } from 'app/types';
import { getPhoneCode, getPhoneNumber } from 'app/utils/getters';
import plusIcon from 'assets/svg/micro-plus.svg';
import React, { useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { cursorFinder } from 'app/utils/handlers';

import {
  AddBtn,
  Input,
  Number,
  Dismiss,
  Row,
  Title,
  Wrapper,
} from './add-phones.styled';

interface Props {
  phones: IPhone[];
  onRemove: (phone: IPhone, index: number) => void;
  onAdd: (phone: IPhone) => void;
  title?: string;
  className?: string;
  removeBtnText?: any;
  removeBtnTextActive?: any;
  requests?: boolean;
}

const cantRemoveTitle =
  'Этот номер ввели сотрудники Вашей управляющей организации (УО).\n' +
  'Для удаления обратитесь в Вашу УО.';

export const AddPhones = React.memo(
  ({ phones, onRemove, onAdd, title = 'Телефон', removeBtnText = 'Удалить', removeBtnTextActive = 'Удалить', requests, className }: Props) => {
    const ref = useRef<HTMLInputElement>(null);

    const [onCreate, setOnCreate] = useState(false);
    const [newNumber, setNewNumber] = useState({
      add: '',
      code: '',
      number: '',
      str_number: '',
      type: 'cell',
    });

    useEffect(() => {
      if (newNumber.str_number && !newNumber.str_number.includes('_')) {
        handleAddPhone();
      }
    }, [newNumber.str_number]);

    useEffect(() => {
      if (onCreate) {
        ref.current?.focus();
      }
    }, [onCreate]);

    const switchEdit = (value: boolean) => {
      setOnCreate(value);
    };

    const handleChangeNumber = ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) =>
      setNewNumber({
        ...newNumber,
        str_number: target.value,
        code: getPhoneCode(target.value),
        number: getPhoneNumber(target.value),
      });

    const handleAddPhone = () => {
      onAdd(newNumber);
      clear();
    };

    const clear = () => {
      setOnCreate(false);
      setNewNumber({
        add: '',
        code: '',
        number: '',
        str_number: '',
        type: 'cell',
      });
    };

    return (
      <Wrapper className={className} data-testid={'add-phones'}>
        <Title className={'add_phones__title'}>{title}</Title>
        {phones.map((phone, index) => (
          <Row className={'add_phones__row'} key={index}>
            <Number className={'add_phones__number'}>
              +7 {phone.code} {phone.number}
            </Number>
            <Dismiss
              type={'button'}
              onClick={() => onRemove(phone, index)}
              disabled={!phone.added_by_tenant}
              title={!phone.added_by_tenant ? cantRemoveTitle : ''}
            >
              {!phone.added_by_tenant ? removeBtnText : removeBtnTextActive}
            </Dismiss>
          </Row>
        ))}
        <Row mt={10}>
          {!onCreate && (
            <AddBtn
              className={'add_phones__add-btn'}
              type={'button'}
              onClick={() => switchEdit(true)}
              requests={requests}
            >
              <img src={plusIcon} alt={'+'} />
              Новый номер
              {/*&#8853;*/}
            </AddBtn>
          )}
          {onCreate && (
            <>
              <ReactInputMask
                placeholder={'+7 999 999 99 99'}
                mask={'+7 999 999 99 99'}
                onChange={handleChangeNumber}
                onFocus={(e) => cursorFinder(e)}
                onBlur={(e) => clear()}
              >
                {(inputProps: any) => (
                  <Input
                    className={'add_phones__input'}
                    type={'tel'}
                    onClick={(e) => cursorFinder(e)}
                    ref={ref}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
              <Dismiss
                orange
                onClick={clear}
                className={'add_phones__dismiss-btn'}
              >
                Отмена
              </Dismiss>
              {/*<ConfirmBtn*/}
              {/*  disabled={*/}
              {/*    newNumber.str_number.includes('_') ||*/}
              {/*    newNumber.str_number.length < 2*/}
              {/*  }*/}
              {/*  onClick={handleAddPhone}*/}
              {/*>*/}
              {/*  Добавить*/}
              {/*</ConfirmBtn>*/}
            </>
          )}
        </Row>
      </Wrapper>
    );
  }
);
