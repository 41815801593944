import styled from 'styled-components/macro';
import { MFMedia } from 'app/styles/media-breakpoints';
import { phone_black, plug } from '../../../../assets';

export const BlurContainter = styled.div`
  background: rgba(1, 9, 23, 0.15);
  backdrop-filter: blur(2.5px);
  min-height: calc(100% - 54px);
  display: none;

  @media (${MFMedia.tablet}) {
    display: block;
    min-height: 100%;
    width: 52%;
  }
`;
export const ServiceWindow = styled.div`
  width: 100%;
  margin: 48px auto 0 auto;
  background: #ffffff;
  padding-left: 16px;

  @media (${MFMedia.tablet}) {
    padding-left: 24px;
    width: 48%;
    margin: 0;
    height: 100vh;
  }

  .alice-carousel__stage-item {
    &:not(:nth-child(1)) {
      padding-left: 8px;

      @media (${MFMedia.tablet}) {
        padding-left: 0;
      }
    }
  }

  .__active {
    span {
      opacity: 1;
      background-color: #f7f8fa;
    }
  }

  .alice-carousel__dots-item {
    &:not(.__custom):not(:last-child) {
      margin-right: 5px;

      @media (${MFMedia.tablet}) {
        margin-right: 8px;
      }
    }
  }

  .alice-carousel__dots {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: calc(50% - 16px);
    transform: translateX(-50%);
    z-index: 1;
  }
`;

export const AliceDotsBlock = styled.div`
  width: 48px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AliceDot = styled.span`
  width: 40px;
  height: 4px;
  border-radius: 24px;
  background-color: #f7f8fa;
  opacity: 0.65;
`;

export const ServiceNavTitle = styled.div`
  color: #89929b;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (${MFMedia.tablet}) {
    font-weight: 500;
    line-height: 30px;
    margin-left: 19px;
    color: #5c7695;
    font-size: 16px;
    position: static;
    transform: none;
  }
`;

export const SliderImg = styled.div<{ img: string }>`
  height: 234px;
  width: 100%;
  border-radius: 8px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (${MFMedia.tablet}) {
    height: 600px;
  }
`;

export const SliderImgInner = styled.div<{ img: string }>`
  height: inherit;
  width: inherit;
  border-radius: 8px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  backdrop-filter: blur(10px);
`;

export const ServiceTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 24px 0 8px 0;
`;

export const ServiceTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #202939;
  text-align: start;
  margin: 0;
`;

export const ServiceCardBlock = styled.div`
  position: relative;
  min-height: 190px;
  border-radius: 16px;
  padding: 0 0 16px 0;

  .alice-carousel {
    padding-right: 16px;

    @media (${MFMedia.tablet}) {
      padding-right: 24px;
    }
  }
`;

export const Star = styled.button`
  cursor: pointer;
  color: #49994c;
  background: #f4f5f7;
  border-radius: 8px;
  border: none;
  width: 24.3px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    font-size: 11px;
  }
`;

export const VideoBlock = styled.div`
  height: 234px;

  @media (${MFMedia.tablet}) {
    height: 330px;
  }
`;

export const ServiceWindowContainer = styled.div`
  display: flex;
  //height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 56px);
  width: 100%;
  z-index: 300;

  @media (${MFMedia.tablet}) {
    height: 100%;
  }
`;

export const NavWrapper = styled.div`
  display: none;

  @media (${MFMedia.tablet}) {
    width: max-content;
    display: flex;
    align-items: center;
    height: 34px;
    margin: 32px 0;
    cursor: pointer;
  }
`;

export const ContactButton = styled.button`
  font-family: SF Pro normal, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;
`;

export const ContactPhoneBtnPC = styled(ContactButton)`
  width: 100%;
  display: none;

  @media (${MFMedia.tablet}) {
    display: block;
  }
`;

export const ContactPhoneBtnMobile = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #49994c;
  border-radius: 8px;
  border: 1px solid #49994c;
  background: #f8f9fb;
  height: 46px;
  cursor: pointer;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

export const ToCreteBtn = styled(ContactButton)`
  width: 100%;
`;

export const DescriptionTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: end;
  justify-content: start;
  margin: 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #202939;
`;

export const ContentBlock = styled.div`
  padding-right: 16px;

  @media (${MFMedia.tablet}) {
    padding-right: 24px;
  }
`;

export const ContactPhoneBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContactPhone = styled.div`
  top: 50px;
  min-width: 180px;
  width: 49%;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  color: #202939;
  background: #f8f9fb;
  border-radius: 8px;
  border: 1px solid #e3e8ef;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;

  &:before {
    content: url(${phone_black});
    margin-top: 3px;
  }

  span {
    margin-left: 8px;
  }

  @media (${MFMedia.tablet}) {
    display: flex;
  }
`;

export const ContactButtonsBlock = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 24px;
`;

export const EmptyImg = styled.div`
  background-image: url(${plug});
  background-position: 50% 50%;
  background-size: 56px 56px;
  background-repeat: no-repeat;
  background-color: #ffffff;
  height: 210px;
  width: 100%;
  border-radius: 8px;

  @media (${MFMedia.tablet}) {
    margin: 0 12px 0 0;
    width: calc(100% - 24px);
  }
`;

export const ServiceDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #202939;
`;

export const ReturnBtn = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  white-space: nowrap;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

export const AliceCarouselPC = styled.div`
  display: none;

  @media (${MFMedia.tablet}) {
    display: block;
    position: relative;
  }
`;

export const AliceCarouselMobile = styled.div`
  display: block;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

const SlideNavArrBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
`;

export const SlidePrev = styled(SlideNavArrBox)`
  left: 8px;
`;

export const SlideNext = styled(SlideNavArrBox)`
  right: 32px;
`;

const Arrow = styled.button`
  border: solid #f7f8fa;
  opacity: 0.65;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  background-color: inherit;
  cursor: pointer;
  box-shadow: 1px 1px 1px #d9dadc; //в тз не было, но иначе при белой картинке не видно стрелку

  &:focus {
    outline: none;
  }
`;

export const ArrowShortLeft = styled(Arrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

export const ArrowShortRight = styled(Arrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
