import React from 'react';

import { Overlay } from './block-overlay.styled';

export const BlockOverlay = React.memo(() => {
  return (
    <Overlay className={'block-overlay'} data-testid={'overlay'}>
      <span className={'fas fa-spinner fa-spin'} data-testid={'spinner'} />
    </Overlay>
  );
});
