import React from 'react';
import {
  Wrapper,
  Title,
  LinkArea,
  Text,
  StyledBr,
} from './lightblue-tooltip.styled';

interface Props {
  isShown: boolean;
}

export const LightBlueTooltip = ({ isShown }: Props) => {
  if (isShown) {
    return (
      <Wrapper>
        <span className={'fas fa-times'}></span>
        <Title>
          ЖК РФ <StyledBr />
          Статья 155. Внесение платы за жилое помещение и коммунальные услуги.
          П.2.2.
        </Title>
        <LinkArea>
          <a
            href="https://legalacts.ru/kodeks/ZHK-RF/razdel-vii/statja-155/"
            target={'_blank'}
          >
            https://legalacts.ru/kodeks/ZHK-RF/razdel-vii/statja-155/
          </a>
        </LinkArea>
        <Text>
          <p>
            2. Плата за жилое помещение и коммунальные услуги вносится на
            основании:
          </p>
          <p>
            2) информации о размере платы за жилое помещение и коммунальные
            услуги, задолженности по оплате жилых помещений и коммунальных
            услуг, размещенной в системе или в иных информационных системах,
            позволяющих внести плату за жилое помещение и коммунальные услуги.
            Информацией о размере платы за жилое помещение и коммунальные услуги
            и задолженности по оплате жилых помещений и коммунальных услуг
            являются сведения о начислениях в системе, сведения, содержащиеся в
            представленном платежном документе по адресу электронной почты
            потребителя услуг или в полученном посредством информационных
            терминалов платежном документе.
          </p>
        </Text>
      </Wrapper>
    );
  } else {
    return <></>;
  }
};
