import styled from 'styled-components/macro';

export const EmptyText = styled.div`
  color: #707070;
  font-size: 26px;
  font-weight: 600;
  margin-top: 36px;
`;

export const EmptyContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

export const Header = styled.div`
  height: 40px;
  color: #334d6e;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 500;
`;

export const Name = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  
  & > div:first-child {
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    margin-right: 10px;
  }

  & > span {
    margin-right: 20px;
    color: #6f6c99;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const NameRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  overflow: hidden;
  grid-template-rows: 40px max-content 1fr;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  height: 100%;

  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    color: #808080;
  }

  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
    padding: 10px;
  }

  .custom-tooltip {
    background: #263238;
    border-radius: 3px;
    padding: 9px 12px;
    width: max-content;
    min-width: 206px;

    .list-item {
      color: #ffffff;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        display: flex;
        justify-content: space-between;

        & > div:first-child {
          margin-right: 10px;
        }
      }
    }
  }
`;
