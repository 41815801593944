import PlayRegularTTF from 'assets/fonts/Play-Regular.ttf';
import PlayRegularWoff from 'assets/fonts/Play-Regular.ttf';
import RobotoRegular from 'assets/fonts/Roboto-Regular.ttf';
import { createGlobalStyle, css } from 'styled-components/macro';
import { SfFonts } from './sf-fonts';

export const bodyStyles = css``;

/*
 url(${NameOfYourFontWoff2}) format('woff2'),
 url(${NameOfYourFontWoff}) format('woff');
*/

export const GlobalStyle = createGlobalStyle`
  html {
    ${SfFonts};

    @font-face {
      font-family: 'Play Regular';
      src: url(${PlayRegularTTF}) format('ttf'),
        url(${PlayRegularWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
    }

    @font-face {
      font-family: 'Roboto';
      src: url(${RobotoRegular}) format('ttf');
      font-weight: 400;
      font-style: normal;
    }
  }
  
  html,
  body {
     height: 100%;
     width: 100%;
  }

  body {
     font-family: SF Pro normal,serif;
  }

  p,
  label {
     // font-family: Georgia, Times, 'Times New Roman', serif;
     line-height: 15px;// 1.5em;
  }

  input, select {
     font-family: inherit;
     font-size: inherit;
  }
  
  div {
    box-sizing: border-box;
  }
  
  h3 {
    font-size: 20px;
  }
`;
