import styled from 'styled-components/macro';
import calendar from '../../../assets/svg/calendar.svg';

export const Period = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 10px;
  color: #334d6e;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  width: max-content;
  position: relative;

  span {
    padding: 0 18px 0 5px;
  }
`;

export const CalendarIcon = styled.img.attrs({
  src: calendar,
})`
  width: 17px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;

  .react-datepicker-popper {
    right: 0;
    left: unset;
  }

  .react-datepicker__month-text {
    padding: 10px 10px 10px 10px;
  }

  .react-datepicker-wrapper {
    width: 74px;

    .react-datepicker__input-container {
      & > input {
        border: none;
        outline: none;
        width: 100%;
      }
    }
  }
`;

export const RangePickerSection = styled.div`
  position: absolute !important;
  z-index: 10 !important;
  right: -60px !important;
  top: 35px !important;
`;
