const STORAGE_KEY = 'LK_EIS';

interface Storage {
  [key: string]: any;
}

export const runStorageService = (): void => {
  if (!localStorage.getItem(STORAGE_KEY)) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
  }
};

const getStorage = (): Storage =>
  JSON.parse(localStorage.getItem(STORAGE_KEY) as string);

export const getItem = (key: string) => {
  const storage = getStorage();
  return storage[key] || null;
};

export const setItem = (
  key: string,
  value: string,
  notOverride?: boolean
): boolean => {
  const storage = getStorage();
  if (storage[key] && notOverride) {
    console.error('In storage item already exists');
    return false;
  } else {
    storage[key] = value;
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
  return true;
};

export const removeItem = (key: string): boolean => {
  const storage = getStorage();
  if (key in storage) {
    delete storage[key];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
    return true;
  }
  return false;
};

export const clearStorage = (): void => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
};
