import styled from 'styled-components/macro';
import logo from './assets/logo.svg';
import arrow from './assets/arrow.svg';

export const Wrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
  align-items: center;
  color: #62789d;
`;

export const Logo = styled.img.attrs({
  src: logo,
})``;

export const Arrow = styled.img.attrs({
  src: arrow,
})`
  position: absolute;
  left: -15px;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  //border: 2px solid red; //для теста отображения наглядно
`;
