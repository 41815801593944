import { getLoaderState } from 'app/store/root-selectors';
import React from 'react';
import { useSelector } from 'react-redux';

import { Fade, LoaderBlock, Spinner, Wrapper } from './loader-styled';

export const Loader = React.memo(() => {
  const { show } = useSelector(getLoaderState);

  if (!show) {
    return <></>;
  }

  return (
    <>
      <Fade />
      <Wrapper>
        <LoaderBlock>
          <Spinner />
        </LoaderBlock>
      </Wrapper>
    </>
  );
});
