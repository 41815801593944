import { find } from 'lodash';
import moment from 'moment';
import {
  formatISODate,
  getDateRangeList,
  getDeadlineTime,
} from 'app/utils/getters';
import coldWater from './assets/cold-water.svg';
import electric from './assets/electric.svg';
import gas from './assets/gas.svg';
import heat from './assets/heat-meter.svg';
import hotWater from './assets/hot-water.svg';
import day from './assets/day.svg';
import night from './assets/night.svg';
import { meterTypesWithFloatReadings } from './constants';
import {
  IMeter,
  IMeterReportReading,
  IMeterReportCol,
  IMetersInputMethod,
  IMetersValue,
  IRequestReading,
} from './types';

/* Дизейблить ввод показаний счетчика или нет */
export const isMeterDisabled = (meter: IMeter): boolean => !isHeatDistributor(meter.type) && meter.readonly;

/* Проверяет, вышел ли срок поверки счетчика */
export const isVerificationLost = (date: string): boolean => {
  const diff = moment(date).diff(moment(), 'days');
  return diff > 0;
};

export const filterArchiveMeters = (meter: IMeter): boolean => {
  /* Исключает архивные счетчики из выборки */
  if (meter.working_finish_date) {
    return moment().diff(moment(meter.working_finish_date), 'days') <= 0;
  }
  return true;
};

// Todo: УБРАТЬ! костыль пока last_readings не обязательное
export const getMeterZeroValues = (_type: string): number[] => {
  const type = _type.toLowerCase();
  if (type.includes('three')) {
    return [0, 0, 0];
  }
  if (type.includes('two')) {
    return [0, 0];
  }
  return [0];
};

/* Проверяет, можно ли счетчик предзаполнить по среднему расходу
 * Необходимо, что бы в нескольких местах использовать эту проверку
 */
export const hasMeterAverageDeltasDefault = (meter: IMeter) =>
  !meter.current_values?.length &&
  meter.average_deltas.length &&
  meter.previous_values.length &&
  !meter.readonly;

/* Возвращает сокращения "год" и "лет", "г" и "л" */
export const getYearShort = (diffYears: number): string => {
  const g = 'г.';
  const l = 'л.';

  if (diffYears >= 0 && diffYears <= 4) {
    return g;
  } else if (diffYears >= 5 && diffYears <= 20) {
    return l;
  } else if (diffYears >= 20) {
    const diffStr = diffYears.toString();
    const lastNum = +diffStr[diffStr.length - 1];

    if (lastNum >= 1 && lastNum <= 4) {
      return g;
    } else {
      return l;
    }
  } else {
    return '';
  }
};

export const getVerificationParams = (
  date: string
): {
  isComing: boolean;
  verificationStr: string;
} => {
  const nextCheckDateStr = formatISODate(date, 'DD.MM.YYYY');
  let isComing = false;

  const diffMonths = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').diff(
    moment(),
    'months'
  );
  const diffDays = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').diff(
    moment(),
    'days'
  );

  if (diffDays > 0 && diffMonths <= 3) {
    isComing = true;

    return {
      isComing,
      verificationStr: `${nextCheckDateStr} выходит срок поверки`,
    };
  }

  const { years, months, days } = getDeadlineTime(new Date(date));

  if (!years && !months && !days) {
    isComing = true;

    return {
      isComing,
      verificationStr: `${nextCheckDateStr} вышел срок поверки`,
    };
  }

  return {
    isComing,
    verificationStr: `${years ? years + getYearShort(years) : ''} ${
      months ? months + 'м. ' : ''
    } ${days ? days + 'д.' : ''} до следующей поверки`,
  };
};

export const getFormDefaultValues = (
  meters: IMeter[]
): { [key: string]: IMetersValue[] } => {
  const values: { [key: string]: IMetersValue[] } = {};

  meters.filter(filterArchiveMeters).forEach((meter) => {
    values[meter.id] = (
      meter.last_readings || getMeterZeroValues(meter.type)
    ).map((reading) => {
      return ({
        type: meter.type,
        value: isHeatDistributor(meter.type) && !readingPeriodEqual(meter) ? '0' : reading.toString() || '0',
        pristine: false,
        hasError: false,
        crossedZeroUp: false,
        crossedZeroDown: false,
      })
    });
  });

  return values;
};

export const validateMeterValues = (
  meter: IMeter,
  index: number,
  value: string
): boolean => {
  if (!value.length) {
    return true;
  }

  if (value.length && ['.', ','].includes(value[value.length - 1])) {
    return true;
  }

  if (
    value.includes('.') &&
    !meterTypesWithFloatReadings.includes(meter.type)
  ) {
    console.log('Дробные показания для такого счетчика запрещены');
    return true;
  }

  if (meter.readonly) {
    // Для автоматических счетчиков валидация бессмысленна
    return false;
  }

  const newValue = Number.parseFloat(value); // Новое показание
  const prevValue = meter.previous_values[index]; // Предыдущее показание
  console.log(`Введено: ${newValue}, предыдущее показание: ${prevValue}`);

  if (newValue < prevValue) {
    console.log(
      'Сданное показание ',
      newValue,
      ' меньше, чем прошлое ',
      prevValue
    );
    const difference = Math.abs(prevValue - newValue);
    if (difference > meter.digit_capacity / 2) {
      console.log('Счетчик перешел через 0');
      return false;
    } else {
      return true;
    }
    /*
     * в случае, когда сданное показание меньше начального,
     * то берем модуль разницы между сданным и изначальным показанием и сравниваем с максимальным показанием счетчика,
     * деленным на 2
     * Если сданный расход больше, то считаем, что счетчик перешел через 0, в противном случае считаем это ошибкой
     * */
  } else {
    if (newValue - prevValue > meter.digit_capacity / 2) {
      // Нельзя сдать показания больше, чем половина барабана счетчика
      console.log(
        'Нельзя сдать показания больше, чем половина барабана счетчика'
      );
      return true;
    } else {
      return false;
    }
  }
};

export const calculateNextMeterValue = (
  meter: IMeter,
  index: number,
  _value: string,
  method: IMetersInputMethod,
  previousValue: IMetersValue
): {
  valid: boolean;
  value: string;
  crossedZeroUp: boolean;
  crossedZeroDown: boolean;
} => {
  const value = Number.parseFloat(_value);
  const { crossedZeroUp, crossedZeroDown } = previousValue;

  const newValue = {
    valid: false,
    value: _value,
    crossedZeroUp,
    crossedZeroDown,
  };

  if (!_value.length) {
    return {
      ...newValue,
      valid: true,
      value: '',
    };
  }

  // Ограничение до 5 знаков после запятой
  const indexOfDot = _value.indexOf('.');

  if (indexOfDot !== -1) {
    if (_value.length > indexOfDot + 6) {
      console.log('Слишком много знаков после запятой!');
      return {
        ...newValue,
      };
    }
  }

  if (_value.length && ['.', ','].includes(_value[_value.length - 1])) {
    return {
      ...newValue,
      valid: true,
    };
  }

  // Переход через 0 вверх
  if (value > meter.digit_capacity) {
    if (method === 'keyboard') {
      // Заблокирован клавиатурный переход через 0
      return newValue;
    } else {
      return {
        valid: true,
        value: '0',
        crossedZeroUp: true,
        crossedZeroDown: false,
      };
    }
  }

  // const firstValues = [99999.2]; // Todo: убрать!
  // Переход через 0 вниз или уже был совершен переход
  if (value < 0 || crossedZeroDown) {
    // Нельзя давать вводить с клавиатуры со знаком минус
    if (value < 0 && method === 'keyboard') {
      return {
        ...newValue,
        value: Math.abs(value).toString(), // Отрицательных значений в любом случае быть не может
      };
    } else {
      // Значение под нулем
      let negativeValue = value;
      if (value < 0) {
        // Value < 0 только один раз - когда переходят через 0 и вводят -1
        // meter.digit_capacity - длинна барабана счетчика, вроде 99999.999, и если value = -1, то это 99998.999 на барабане
        negativeValue = meter.digit_capacity - Math.abs(value);
      }
      if (negativeValue < meter.first_values[index]) {
        // Если совершен переход через ноль, и совершается попытка ввести значение меньше первоначального - выводим первоначальное значение
        console.log(
          'Состоялся переход через 0, нельзя вводить меньше, чем первоначальное значение'
        );
        return {
          valid: true,
          value: meter.first_values[index].toString(),
          crossedZeroUp: false,
          crossedZeroDown: true,
        };
      } else {
        return {
          valid: true,
          value: Math.round(negativeValue).toString(),
          crossedZeroUp: false,
          crossedZeroDown: true,
        };
      }
    }
  }

  // if (crossedZeroDown && value < meter.first_values[index]) {
  //   return newValue;
  // } // Закомментил, тк походу бесполезная

  return { ...newValue, valid: true };
};

export const checkValuesErrors = (values: {
  [key: string]: IMetersValue[];
}): boolean => {
  let hasErrors = false;

  for (const key in values) {
    if (find(values[key], { hasError: true })) {
      hasErrors = true;
    }
  }

  return hasErrors;
};

/* Фильтрация показаний счетчиков на предмет отсечения показаний, которые нельзя передавать на бэк */
export const filterRequestReadings = (
  readings: IRequestReading[],
  meters: IMeter[]
): IRequestReading[] =>
  readings.filter((reading) => {
    const meter = find(meters, { id: reading.meter_id });
    if (meter) {
      // readonly - нельзя отправлять показания
      return isHeatDistributor(meter.type) || !meter.readonly;
    }
    return false;
  });

/* Подготовка показаний к отправке, в т.ч. фильтрация от лишних счетчиков, которые нельзя сдавать */
export const prepareReadings = (
  values: {
    [key: string]: IMetersValue[];
  },
  meters: IMeter[]
): IRequestReading[] => {
  const readings: IRequestReading[] = [];

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      readings.push({
        meter_id: key,
        values: values[key].map((item) =>
          Number(Number.parseFloat(item.value).toFixed(5))
        ),
      });
    }
  }

  return filterRequestReadings(readings, meters);
};

export const getMeterIcon = (type: string): string => {
  switch (type) {
    case 'ColdWaterHouseMeter':
    case 'ColdWaterAreaMeter':
      return coldWater;
    case 'HotWaterHouseMeter':
    case 'HotWaterAreaMeter':
      return hotWater;
    case 'GasAreaMeter':
    case 'GasHouseMeter':
      return gas;
    case 'ElectricThreeRateHouseMeter':
    case 'ElectricTwoRateHouseMeter':
    case 'ElectricOneRateHouseMeter':
    case 'ElectricThreeRateAreaMeter':
    case 'ElectricTwoRateAreaMeter':
    case 'ElectricOneRateAreaMeter':
      return electric;
    case 'HeatAreaMeter':
    case 'HeatHouseMeter':
    case 'HeatDistributorAreaMeter':
      return heat;
  }

  return '';
};

export const prepareMetersReport = ({
  month_from,
  month_till,
  meters,
}: {
  month_from: string;
  month_till: string;
  meters: IMeter[];
}): IMeterReportCol[] => {
  const monthList = getDateRangeList({
    dateFrom: month_from,
    dateTill: month_till,
    format: 'MM.YYYY',
  });
  return meters.map((meter) => {
    let col: IMeterReportCol = {
      meter,
      readings: [],
    };
    monthList.forEach((month) => {
      let reportReading: IMeterReportReading | null = null;
      // Реверс нужен, тк список месяцев с самого свежего по самый старый, а показания расположены наоборот
      const reversedReadings = Array.from(meter.readings).reverse();
      reversedReadings.forEach((reading) => {
        if (moment(reading.period).format('MM.YYYY') === month) {
          reportReading = {
            month,
            values: reading.values.map((value) =>
              String(formatReadingValue(value, meter.type))),
            deltas: reading.deltas.map((value) =>
              String(formatReadingValue(value, meter.type))),
          };
        }
      });
      if (!reportReading) {
        let emptyValues = meter.first_values.map((_) => '-');
        reportReading = {
          month,
          values: emptyValues,
          deltas: emptyValues,
        };
      }
      col.readings.push(reportReading);
    });
    return col;
  });
};

/* Возвращает иконку показания в зависимости от типа счетчика и индекса показания */
export const getMeterReadingImage = (type: string, index: number): string => {
  if (type.includes('Two') || type.includes('Three')) {
    switch (index) {
      case 0:
        return day;
      case 1:
        return night;
      case 2:
        return electric;
      default:
        return '';
    }
  } else {
    return getMeterIcon(type);
  }
};

export const getMeterReadingNameStr = (type: string, index: number): string => {
  if (type.includes('Three')) {
    switch (index) {
      case 0:
        return 'Пик';
      case 1:
        return 'Ночь';
      case 2:
        return 'Полупик';
      default:
        return '';
    }
  } else if (type.includes('Two')) {
    switch (index) {
      case 0:
        return 'День';
      case 1:
        return 'Ночь';
      default:
        return '';
    }
  } else {
    return '';
  }
};

export const isHeatDistributor = (meterType?: string): boolean => meterType === 'HeatDistributorAreaMeter';

export const readingPeriodEqual = (meter: IMeter): boolean => {
  const nowMonth = moment().month();
  const lastReadingMonth = moment(meter.readings[meter.readings.length - 1]?.period).month();
  return nowMonth === lastReadingMonth;
}

export const isHeatAreaMeter = (type?: string): boolean =>
  type === 'HeatAreaMeter';

export const formatReadingValue = (value: string | number, type?: string, inputValue: boolean = false): string | number => {
  const isHeat = isHeatAreaMeter(type);
  if (inputValue && !isHeat) {
    return String(value);
  }
  if (!value) {
    return String(value)
  }

  let num = Number(value);
  const multiplier =  isHeat ? 1e5 : 1e2;
  num = Math.round(num * multiplier) / multiplier;
  let numStr = String(num);

  if (isHeat) {
    // данный regexp позволяет ввести до 5 цифр после запятой
    return +numStr.replace(/(\.\d{0,5})\d*$/, '$1');
  }

  numStr += numStr.includes('.') ? '' : '.';
  return numStr.padEnd(numStr.indexOf('.') + 3, '0');
}