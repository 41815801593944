import React from 'react';
import { Error, Input, Wrapper } from '../phone-input/phone-input.styled';
import { useAppDispatch } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { getEmailSelector } from '../../selectors';
import { updateEmail } from '../../qr-payment-slice';

export interface Props {
  isError: boolean;
}

export const EmailInput = React.memo(({ isError }: Props) => {
  const dispatch = useAppDispatch();
  const email = useSelector(getEmailSelector);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEmail(e.target.value));
  };
  return (
    <Wrapper>
      <Input
        type={'email'}
        isError={isError}
        defaultValue={email}
        onChange={handleChangeEmail}
      />
      {isError && <Error>E-mail введён неверно</Error>}
    </Wrapper>
  );
});
