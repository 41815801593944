import React, { ButtonHTMLAttributes } from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';

import { StyledButton } from './button.styled';

export type Props = {
  size: 'sm' | 'md' | 'lg';
  variant: 'blue' | 'success' | 'lightblue' | 'cancel' | 'empty';
  br?: boolean;
} & SpaceProps &
  TypographyProps &
  ButtonHTMLAttributes<any>;

export const Button = React.memo(({ children, br = true, ...props }: Props) => (
  <StyledButton br={br} {...props}>
    {children}
  </StyledButton>
));
