import cabinetLogo from 'app/pages/auth/assets/cabinet-logo-new.png';
import styled, { keyframes } from 'styled-components/macro';

export const Logo = styled.img.attrs({
  src: cabinetLogo,
})`
  width: 80px;
`;

const anim1 = keyframes`
  0%, 32% {
    background-color: #007AD9;
  }
  
  33% {
    background-color: #e7e7e7;
  }
`;

const anim2 = keyframes`
  32%, 66% {
    background-color: #e7e7e7;
  }
  33%, 65% {
    background-color: #007AD9;
  }
`;

const anim3 = keyframes`
  65%, 99% {
    background-color: #e7e7e7;
  }
  
  66%, 98% {
    background-color: #007AD9;
  }
`;

export const Dots = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50px;
  margin-top: 20px;

  div {
    width: 8px;
    height: 8px;
    background-color: #e7e7e7;
    border-radius: 50%;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: steps(3, end);

    &:first-child {
      animation-name: ${anim1};
    }

    &:nth-child(2) {
      animation-name: ${anim2};
    }

    &:last-child {
      animation-name: ${anim3};
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: -100px;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100vw;
  height: 100%;
  z-index: 10000;
`;
