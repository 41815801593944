import React from 'react';
import { Message } from './empty-search-result.styled';

interface Props {
  value: string;
  search?: boolean;
}

export const EmptySearchResult = ({ value, search }: Props) => {
  return (
    <Message>
      {search ? 'По запросу' : 'В категории'}
      <br />«{value}»<br />
      ничего не найдено
      <br />
      <br />
      Попробуйте ввести
      <br />
      другой запрос
    </Message>
  );
};
