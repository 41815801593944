import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import {
  GetTemplateRequests,
  PostToBasket,
  GetServerBasket,
  CreateOrder,
  GetRequisites,
} from 'app/api/template-request';
import { showWarning } from 'app/store/root-slice';
import {
  ICategory,
  IServerBasketItem,
  IBasketItem,
  IPhones,
  IServicesOrder,
  IRequisites,
  IBilling,
  IBasket,
  ICategoryList,
} from '../../types';

export interface InitialState {
  list: ICategoryList;
  tempBasket: IBasket;
  serverBasket: IBasket;
  request: IRequisites;
  billing: IBilling;
  // лучше разделить статусы
  statuses: {
    onLoading: boolean; // в процессе загрузки
    loaded: boolean; // загружено
    postFailed: boolean;
  };
  statusesServerBasket: {
    onLoading: boolean;
    loaded: boolean;
    postFailed: boolean;
  };
  statusesTemplateRequestsList: {
    onLoading: boolean;
    loaded: boolean;
    postFailed: boolean;
  };
  statusesOrder: {
    onLoading: boolean;
    loaded: boolean;
    postFailed: boolean;
  };
  statusesRequisites: {
    onLoading: boolean;
    loaded: boolean;
    postFailed: boolean;
  };
  statusesPostToBasket: {
    onLoading: boolean;
    loaded: boolean;
    postFailed: boolean;
  };
}

export const initialState: InitialState = {
  list: {},
  tempBasket: {},
  serverBasket: {},
  request: {
    id: '',
    number: null,
  },
  billing: {
    bank_name: '',
    bank_number: '',
    bic: [],
    correspondent: '',
    inn: '',
    params: {
      account: '',
      amount: '',
      area_name: '',
      attr_0: null,
      attr_1: null,
      attr_2: null,
      attr_3: null,
      attr_4: null,
      email: '',
      service: '',
      url_return: '',
    },
    processing_url: '',
    provider_name: '',
    sector: '',
    bank_url: '',
  },
  statuses: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
  statusesServerBasket: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
  statusesTemplateRequestsList: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
  statusesOrder: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
  statusesRequisites: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
  statusesPostToBasket: {
    onLoading: false,
    loaded: false,
    postFailed: false,
  },
};

export const getTemplateRequests = createAsyncThunk<any>(
  'template_requests/getTemplateRequests',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await GetTemplateRequests();
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getServerBasket = createAsyncThunk<any>(
  'template_requests/getServerBasket',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await GetServerBasket();
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const postToBasket = createAsyncThunk<
  any,
  {
    category: string;
    services: IBasketItem[];
  },
  any
>(
  'template_requests/sendToBasket',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      return await PostToBasket(body);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const createOrder = createAsyncThunk<
  any,
  {
    body: string;
    phones: IPhones[];
    services: IServicesOrder[];
  },
  any
>(
  'template_requests/createOrder',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      return await CreateOrder(body);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getRequisites = createAsyncThunk<
  any,
  {
    request_id: string;
    request_number: number;
    bank_number: string;
    value: number | string;
  },
  any
>(
  'template_requests/getRequisites',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      return await GetRequisites(body);
    } catch (e) {
      // if (e.response.status === 406) {
      // }
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const templateRequestSlice = createSlice({
  name: 'template_requests',
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<any>) => {
      // state.tempBasket - объект, где key категория, а value это массив с сервисами
      if (!state.tempBasket[action.payload.category]) {
        state.tempBasket[action.payload.category] = [action.payload.service];
        return;
      }
      state.tempBasket[action.payload.category] = [
        ...current(state.tempBasket[action.payload.category]).filter(
          (el: IBasketItem) => el.service !== action.payload.service.service
        ),
        action.payload.service,
      ];
    },

    setTempBasket: (state, action: PayloadAction<any>) => {
      state.tempBasket = { ...action.payload };
    },

    setServerBasket: (state, action: PayloadAction<any>) => {
      state.serverBasket = { ...state.serverBasket, ...action.payload };
    },

    clearAndSetServerBasket: (state, action: PayloadAction<any>) => {
      state.serverBasket = { ...action.payload };
    },

    deleteServerBasketEmptyCategory: (state, action: PayloadAction<any>) => {
      delete state.serverBasket[action.payload];
    },

    deleteTempBasketEmptyCategory: (state, action: PayloadAction<any>) => {
      delete state.tempBasket[action.payload];
    },

    clearRequestAndOrderData: (state) => {
      state.request = initialState.request;
      state.billing = initialState.billing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplateRequests.pending, (state) => {
      state.statuses.onLoading = true;
    });

    builder.addCase(postToBasket.pending, (state) => {
      state.statusesPostToBasket.onLoading = true;
    });

    builder.addCase(getServerBasket.pending, (state) => {
      state.statusesServerBasket.onLoading = true;
    });

    builder.addCase(createOrder.pending, (state) => {
      state.statusesOrder.onLoading = true;
    });

    builder.addCase(getRequisites.pending, (state) => {
      state.statusesRequisites.onLoading = true;
    });

    builder.addCase(getTemplateRequests.rejected, (state) => {
      state.statuses.onLoading = false;
    });

    builder.addCase(getServerBasket.rejected, (state) => {
      state.statusesServerBasket.onLoading = false;
    });

    builder.addCase(postToBasket.rejected, (state) => {
      state.statusesPostToBasket.onLoading = false;
      state.statusesPostToBasket.postFailed = true;
    });

    builder.addCase(createOrder.rejected, (state) => {
      state.statusesOrder.onLoading = false;
      state.statusesOrder.postFailed = true;
    });

    builder.addCase(getRequisites.rejected, (state) => {
      state.statusesRequisites.onLoading = false;
      state.statusesRequisites.postFailed = true;
    });

    builder.addCase(postToBasket.fulfilled, (state) => {
      state.statusesPostToBasket.onLoading = false;
      state.statusesPostToBasket.loaded = true;
      state.statusesPostToBasket.postFailed = false;
    });

    builder.addCase(
      getTemplateRequests.fulfilled,
      (state, action: PayloadAction<any>) => {
        action.payload.results.forEach((category: ICategory) =>
          Object.assign(state.list, { [category.id]: category })
        );
        state.statuses.onLoading = false;
        state.statuses.loaded = true;
      }
    );

    builder.addCase(
      getServerBasket.fulfilled,
      (state, action: PayloadAction<any>) => {
        action.payload.cart.forEach((el: IServerBasketItem) =>
          Object.assign(state.serverBasket, { [el.category]: el.attendance })
        );
        state.statusesServerBasket.onLoading = false;
        state.statusesServerBasket.loaded = true;
      }
    );

    builder.addCase(
      getRequisites.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.billing = { ...action.payload };
        state.statusesRequisites.onLoading = false;
        state.statusesRequisites.loaded = true;
      }
    );

    builder.addCase(
      createOrder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.request = { ...action.payload };
        state.statusesOrder.onLoading = false;
        state.statusesOrder.loaded = true;
      }
    );
  },
});

export const {
  addToBasket,
  setTempBasket,
  setServerBasket,
  clearAndSetServerBasket,
  deleteServerBasketEmptyCategory,
  deleteTempBasketEmptyCategory,
  clearRequestAndOrderData,
} = templateRequestSlice.actions;

export default templateRequestSlice.reducer;
