import React from 'react';
import { qrRedirectRoutes } from '../../routesConfig';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  Stage1,
  Stage2,
  Stage3,
  Stage4,
  Stage5,
  Stage6,
  Stage7,
  Stage8,
  Stage9,
  Stage10,
} from './stages';

export const Routes = () => {
  const {
    phone,
    email,
    confirm,
    address,
    sector,
    finish,
    register,
    greetings,
    blocked,
    continuation,
  } = qrRedirectRoutes.qr.payment;

  return (
    <Switch>
      <Route path={phone.link} component={Stage1} />
      <Route path={confirm.link} component={Stage2} />
      <Route path={email.link} component={Stage3} />
      <Route path={address.link} component={Stage4} />
      <Route path={register.link} component={Stage5} />
      <Route path={sector.link} component={Stage6} />
      <Route path={finish.link} component={Stage7} />
      <Route path={greetings.link} component={Stage8} />
      <Route path={blocked.link} component={Stage9} />
      <Route path={continuation.link} component={Stage10} />
    </Switch>
  );
};
