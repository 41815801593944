import React from 'react';
import usePortal from 'react-useportal';
import { Body, ButtonGroup, Fade, Title, Wrapper } from './confirm-list.styled';

export interface IConfirmListSettings {
  body: string;
  list: string[];
  title: string;
  type: 'alert' | 'confirm';
  confirmText: string;
  dismissText: string;
}

interface Props extends IConfirmListSettings {
  onConfirm: () => void;
  onDismiss: () => void;
}

export const ConfirmList = React.memo(
  ({
    title,
    confirmText,
    dismissText,
    body,
    type,
    list,
    onConfirm,
    onDismiss,
  }: Props) => {
    const { Portal } = usePortal();

    return (
      <Portal>
        <Fade />
        <Wrapper data-testid={'confirm-list'}>
          <Title>{title}</Title>
          <Body>
            {body}
            {list.map((item: any) => (
              <div key={item}>{item}</div>
            ))}
          </Body>
          <ButtonGroup $isAlert={type === 'alert'}>
            {type === 'confirm' && (
              <button onClick={onDismiss} type={'button'}>
                {dismissText}
              </button>
            )}
            <button onClick={onConfirm} type={'button'}>
              {confirmText}
            </button>
          </ButtonGroup>
        </Wrapper>
      </Portal>
    );
  }
);
