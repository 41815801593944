import React, { useEffect } from 'react';
import { Button, AddressSelection } from '../components';
import { Wrapper, Header, Explanation, AnotherAddress } from './stages.styled';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { getQrPaymentSelector } from '../selectors';
import {
  selectAddress,
  selectSector,
  getPaymentInfo,
  getRemainingPaymentInfo,
} from '../qr-payment-slice';
import { useAppDispatch } from '../../../hooks';
import { unwrapResult } from '@reduxjs/toolkit';

/** ШАГ ВЫБОРА АДРЕСА **/
/** ../#/qr/payment/address/ **/
export const Stage4 = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    tenant_addresses,
    selectedAddress,
    fromRedirect,
    selectedSector,
    payActionId,
  } = useSelector(getQrPaymentSelector);

  useEffect(() => {
    if (tenant_addresses.length === 1) {
      dispatch(selectAddress(tenant_addresses[0]));
      if (tenant_addresses[0].sectors.length === 1) {
        dispatch(selectSector(tenant_addresses[0].sectors[0]));
      }
    }
  }, [tenant_addresses]);

  const handleNext = () => {
    if (selectedAddress.sectors.length === 1) {
      dispatch(selectSector(selectedAddress.sectors[0]));
      if (fromRedirect) {
        dispatch(
          getRemainingPaymentInfo({
            id: payActionId,
            data: {
              tenant_id: selectedAddress.tenant_id,
              sector: selectedAddress.sectors[0].value,
            },
          })
        )
          .then(unwrapResult)
          .then(
            (response) => {
              history.push(qrRedirectRoutes.qr.payment.finish.link);
            },
            (error) => {
              //ERROR
            }
          );
      } else {
        dispatch(
          getPaymentInfo({
            tenant_id: selectedAddress.tenant_id,
            sector: selectedAddress.sectors[0].value,
            tenant_addresses,
          })
        )
          .then(unwrapResult)
          .then(
            (response) => {
              history.push(qrRedirectRoutes.qr.payment.finish.link);
            },
            (error) => {
              //ERROR
            }
          );
      }
    } else {
      history.push(qrRedirectRoutes.qr.payment.sector.link);
    }
  };

  const handleRegister = () => {
    history.push(qrRedirectRoutes.qr.payment.register.link);
  };

  return (
    <Wrapper>
      <Header>
        {tenant_addresses.length > 1
          ? 'Выберите адрес'
          : 'Проверьте выбранный адрес'}
      </Header>
      <Explanation>
        {tenant_addresses.length > 1
          ? '        Мы нашли несколько адресов. Выберите, по какому адресу Вы хотите\n' +
            '        оплатить услуги ЖКХ.'
          : 'Или нажмите кнопку «Другой адрес».'}
      </Explanation>
      <AddressSelection />
      <AnotherAddress onClick={() => handleRegister()}>
        Другой адрес
      </AnotherAddress>
      <Button
        disabled={!selectedAddress.address_str}
        onClick={() => handleNext()}
      >
        Далее
      </Button>
    </Wrapper>
  );
};
