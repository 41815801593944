import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div<{ selected: boolean }>`
  padding: 14px 12px;
  border: ${(props) =>
    props.selected ? '1px solid #477FFF' : '1px solid #cfdef7'};
  background-color: ${(props) => (props.selected ? '#F4F7FF' : '#FFFFFF')};
  border-radius: 12px;
  margin-bottom: 16px;
  color: #163b79;
  font-size: 16px;
  font-weight: 400;
`;
