import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetNews } from 'app/api/news';
import { showWarning } from 'app/store/root-slice';

import { RootState } from 'app/store/store';
import { INews } from './types';

export interface InitialState {
  list: INews[];
  statuses: {
    onLoading: boolean; // в процессе загрузки
    loaded: boolean; // Загружено
    allLoaded: boolean; // Больше нет новостей
    lastRequestCount: number;
  };
  filters: {
    offset: number;
  };
}

export const initialState: InitialState = {
  list: [],
  statuses: {
    onLoading: false,
    loaded: false,
    allLoaded: false,
    lastRequestCount: 0,
  },
  filters: {
    offset: 0,
  },
};

export const getNews = createAsyncThunk<INews[]>(
  'news/getNews',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { news } = getState() as RootState;
    try {
      const response = await GetNews({
        offset: news.filters.offset,
      });
      return response.results || [];
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNews.pending, (state) => {
      state.statuses.onLoading = true;
    });

    builder.addCase(getNews.rejected, (state) => {
      state.statuses.onLoading = false;
    });

    builder.addCase(
      getNews.fulfilled,
      (state, action: PayloadAction<INews[]>) => {
        state.list = [...state.list, ...action.payload];
        state.filters.offset += 10;
        state.statuses.allLoaded = action.payload.length === 0;
        state.statuses.onLoading = false;
        state.statuses.loaded = true;
        state.statuses.lastRequestCount = action.payload.length;
      }
    );
  },
});

export const {} = newsSlice.actions;

export default newsSlice.reducer;
