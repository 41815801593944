import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { GetMeters, GetMetersReport, SendReadings } from '../../api/meters';
import { IButtonCollapseItem } from '../../components/button-collapse/button-collapse';
import { RootState } from '../../store/store';
import { formatDateToISO } from '../../utils/getters';
import {
  IMeter,
  IMeterReportState,
  IMetersForm,
  IMetersInputMethod,
  IPeriodMetersValues,
  IRequestReading,
} from './types';
import {
  calculateNextMeterValue,
  getFormDefaultValues,
  prepareMetersReport,
  validateMeterValues,
} from './utils';

export interface InitialState {
  form: IMetersForm; // Значения для отправки формы
  list: IMeter[];
  report: IMeterReportState;
}

export const initialState: InitialState = {
  form: {
    values: {},
  },
  list: [],
  report: {
    loaded: false,
    list: [],
    range: {
      // Сохраненнеые периоды, что бы при изменении фильтра отчет не менялся
      month_from: '',
      month_till: '',
    },
    filter: {
      month_from: formatDateToISO(moment().subtract(1, 'year').startOf('M')),
      month_till: formatDateToISO(moment().endOf('M')),
    },
    variables: {
      periodStr: 'lastYear',
    },
  },
};

export const getMeters = createAsyncThunk<{
  current_meters: IMeter[];
  readings_report: IMeter[];
}>('meters/getMeters', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    return await GetMeters();
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getMetersReport = createAsyncThunk<{ readings_report: IMeter[] }>(
  'meters/getMetersReport',
  async (_, { rejectWithValue, getState }) => {
    const { meters } = getState() as RootState;
    try {
      return await GetMetersReport(meters.report.filter);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const sendReadings = createAsyncThunk<any, IRequestReading[], any>(
  'meters/sendReadings',
  async (meters, { rejectWithValue, dispatch }) => {
    try {
      return await SendReadings({
        meters,
      });
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

export const metersSlice = createSlice({
  name: 'meters',
  initialState,
  reducers: {
    restoreMetersValues: (state) => {
      state.form.values = getFormDefaultValues(state.list);
    },
    setMeterValue: (
      state,
      {
        payload: { index, value, meter, method },
      }: PayloadAction<{
        index: number;
        value: string;
        meter: IMeter;
        method: IMetersInputMethod;
      }>
    ) => {
      const previousValue = state.form.values[meter.id][index];
      const newValue = calculateNextMeterValue(
        meter,
        index,
        value,
        method,
        previousValue
      );

      const nextValue = newValue.valid ? newValue.value : previousValue.value;

      state.form.values[meter.id][index] = {
        ...previousValue,
        pristine: true,
        value: nextValue,
        hasError: validateMeterValues(meter, index, nextValue),
        crossedZeroUp: newValue.crossedZeroUp,
        crossedZeroDown: newValue.crossedZeroDown,
      };
    },
    setReportPeriodByButton: (
      state,
      { payload: { value, extra: months } }: PayloadAction<IButtonCollapseItem>
    ) => {
      state.report.variables.periodStr = value as IPeriodMetersValues;
      state.report.filter = months;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMeters.fulfilled,
      (state, { payload: { current_meters, readings_report } }) => {
        state.list = current_meters;
        state.form.values = getFormDefaultValues(current_meters);
      }
    );
    builder.addCase(
      getMetersReport.fulfilled,
      (state, { payload: { readings_report } }) => {
        const { month_from, month_till } = state.report.filter;
        state.report.list = prepareMetersReport({
          month_from,
          month_till,
          meters: readings_report,
        });
        state.report.range = {
          month_from: state.report.filter.month_from,
          month_till: state.report.filter.month_till,
        };
        state.report.loaded = true;
      }
    );
  },
});

export const {
  restoreMetersValues,
  setMeterValue,
  setReportPeriodByButton,
} = metersSlice.actions;

export default metersSlice.reducer;
