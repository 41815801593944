import React from 'react';
import { useIphoneFilePopup } from '../../hooks/use-iphone-file-popup';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  Close,
  Document,
  Fade,
  List,
  Load,
  Title,
  Wrapper,
} from './attachments.styled';

interface Props {
  files: any;
  fileUrl: string; // url без аргумента file=...
  isOpen: boolean;
  onClose: () => void;
}

const downloadFile = (url: string) => {
    console.log(url)
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', '');
  // a.setAttribute('target', '_blank');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const Attachments = React.memo(
  ({ files, fileUrl, isOpen, onClose }: Props) => {
    const [Popup, showPopup] = useIphoneFilePopup();

    const handleDownload = async (id: string) => {
      await showPopup();
      downloadFile(`${fileUrl}?file=${id}`);
    };

    return (
      <>
        <Popup />
        <Fade isOpen={isOpen} />
        <Wrapper isOpen={isOpen}>
          <Close onClick={onClose} />
          <Title>Вложенные документы</Title>
          <List files={files.length || 0}>
              <Scrollbars autoHide>
                  {files.map((file: any, index: number) => (
                      <Document key={index}>
                          <span>{file.name}</span>
                          <button onClick={() => handleDownload(file.file)}>
                              <Load />
                          </button>
                      </Document>
                  ))}
              </Scrollbars>
          </List>
        </Wrapper>
      </>
    );
  }
);
