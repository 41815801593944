import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  ButtonSection,
  ErrorImage,
  StyledButton,
  SuccessImage,
  Text,
  TopSection,
  Wrapper,
} from './result-panel.styled';

export interface IResultPanelSettings {
  text: string;
  state: 'success' | 'error';
  className?: string;
}

export interface Props extends IResultPanelSettings {
  onClick?: () => void;
}

export const ResultPanel = React.memo(
  ({ text, state, onClick, className }: Props) => {
    const history = useHistory();

    const handleBack = () => {
      if (onClick) {
        onClick();
      } else {
        history.goBack();
      }
    };

    return (
      <Wrapper className={className} state={state} data-testid={'result-panel'}>
        <TopSection>
          {state === 'success' && <SuccessImage />}
          {state === 'error' && <ErrorImage />}
          <Text data-testid={'result-panel-text'}>{text}</Text>
        </TopSection>
        <ButtonSection>
          <StyledButton onClick={handleBack} variant={'lightblue'} size={'lg'}>
            Вернуться
          </StyledButton>
        </ButtonSection>
      </Wrapper>
    );
  }
);
