import { API } from './api';
// import { ICurrentOrder } from '../pages/catalog/types';

export const GetCatalogServicesList = (params: any) =>
  API.get('/compendium/all_service/', params);

export const GetCatalogCategoriesList = (params: any) =>
  API.get('/models/compendium/position/', params);

// export const GetCatalogServicesBySearch = (params: any) =>
//   API.get(`/compendium/all_service/`, params)

// params: ICurrentOrder не подойдут, т.к. будут удалены пустые поля
export const PostCatalogOrder = (params: any) =>
  API.post('/compendium/order_service/send_mail/', params);

export const AddToFavorite = (id: string) =>
  API.post(`/compendium/all_service/${id}/add_to_favorite/`);

export const DeleteFromFavorite = (id: string) =>
  API.delete(`/compendium/all_service/${id}/del_from_favorite/`, '');

export const PostOrderLink = (positionId: string, params: any) =>
  API.post(`/compendium/order_service/${positionId}/follow_link/`, params);

export const PostShowPhoneForStatistic = (positionId: string, params: any) =>
  API.post(`/compendium/order_service/${positionId}/show_phone/`, params);

export const GetPreviewService = (key: string) =>
    API.get(`/compendium/preview/${key}/get_data/`);

export const GetPreviewFile = (key: string, itemName: string) =>
    API.get(`/compendium/preview/${key}/get_image/?image_name=${itemName}`);
// http://localhost:9000/api/v4/compendium/preview/ru3qbv1ssy/get_data/
// http://localhost:9000/api/v4/compendium/preview/3iugnqh5kv/get_image/?image_name=media__1