import styled from 'styled-components/macro';

export const ServiceOldPrice = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4B5565;
  margin-left: 8px;
  text-decoration: line-through;
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ActualPrice = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #202939;
`;

export const ServiceCost = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #5c7695;
`;
