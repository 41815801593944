import styled from 'styled-components/macro';
import { MFMedia } from '../../../../../../styles/media-breakpoints';

export const ServiceOrderingField = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e6eaef;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0b2646;
  margin-bottom: 8px;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #9aa4b2;
  }

  &:focus {
    border: 1px solid #8a9097;
    outline: none;
  }
`;

export const ServiceOrderingComment = styled.textarea`
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e6eaef;
  border-radius: 8px;
  height: 70px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0b2646;
  width: 100%;
  font-family: SF Pro normal, serif;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #9aa4b2;
  }

  &:focus {
    border: 1px solid #8a9097;
    outline: none;
  }
`;

export const PostButton = styled.button<{ disabled: boolean }>`
  height: 44px;
  background: ${(props) => (props.disabled ? '#A4CCA5' : '#2e9937')};
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const ConfidentialityBlock = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #4682c7;
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }
  }
`;

export const ErrorMsg = styled.div`
  height: 18px;
  font-size: 12px;
  color: #f54b18;
  padding: 0 10px;
  //text-align: center;
  margin-top: -8px;
`;

export const Required = styled.div`
  width: 100%;
  position: relative;
`;

export const RedAsterisk = styled.div`
  position: absolute;
  top: 8px;
  left: 3px;
  color: #f46b4d;

  &:before {
    content: '*';
    color: #f46b4d;
  }
`;

export const HalfFieldsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HalfFields = styled.div<{ half: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.half ? '49%' : '100%')};
`;

export const FieldsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 24px 0;
`;

export const CreateRequestBlock= styled.div<{preview?: boolean}>`
  display: flex;
  flex-direction: column;
  margin: 24px 16px 0 0;

  // TODO проверить, зачем это
  @media (${MFMedia.tablet}) {
    margin: ${(props) => props.preview ? '24px 16px 0 0' : '24px 24px 0 0'}
  }
`;
