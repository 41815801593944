/* Для стилизации выбранного элемента используйте класс button-tabs__selected
 * По умолчанию он зеленый
 *  */

import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Item, Nav } from './button-tabs.styled';

type Props = {
  items: any[];
  onSelect: (item: any) => void;
  selectedItem?: any;
  className?: string;
  variant?: 'category' | 'filter';
  dontSelect?: boolean;
  selectedFromProps?: any;
};

export const ButtonTabs = React.memo(
  ({
    items,
    onSelect,
    dontSelect,
    selectedItem,
    variant = 'category',
    className,
    selectedFromProps,
  }: Props) => {
    const [selected, setSelected] = useState(
      dontSelect ? '' : selectedItem ? selectedItem.id : items[0]?.id
    );

    useEffect(() => {
      if (selectedFromProps) {
        setSelected(selectedFromProps);
      }
    }, [selectedFromProps]);

    return (
      <ScrollContainer>
        <Nav className={className}>
          {items.map((item) => (
            <Item
              variant={variant}
              type={'button'}
              onClick={() => {
                setSelected(item.id);
                onSelect(item);
              }}
              key={item.id}
              className={selected === item.id ? 'button-tabs__selected' : ''}
              selected={selected === item.id}
            >
              {item.title}
            </Item>
          ))}
        </Nav>
      </ScrollContainer>
    );
  }
);
