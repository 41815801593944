import styled from 'styled-components/macro';
import { MFMedia } from '../../../../../../styles/media-breakpoints';

export const CategoryBtnBoxMobile = styled.div`
  display: block;
  padding:  16px 0 10px 0;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

export const CategoryMenuBtnBoxMobile = styled.div`
  display: block;
  padding: 5px 0;
  background: #ffffff;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

export const CategoryBtnBoxPC = styled.div`
  display: none;

  @media (${MFMedia.tablet}) {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    background-color: #ffffff;
    border-radius: 15px;
  }

  @media (${MFMedia.smallPC}) {
    min-height: 30px;
    padding: 0 32px 16px 32px;
    border-radius: initial;
  }
`;

export const AllCategoryTitleBlock = styled.div`
  display: flex;
  position: relative;
  color: #202939;
  margin-bottom: 32px;
`;

export const AllCategoryTitle = styled.div`
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const ReturnBtn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  white-space: nowrap;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;