import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useAppDispatch } from 'app/hooks';
import { checkValidEmail } from 'app/utils/getters';
import {
  clearCurrentOrder,
  clearCurrentService,
  postCatalogOrder,
  postOrderLink,
  setCurrentOrderField,
  showServiceItemFullCard,
} from '../../../../catalog-slice';
import { InlineCheckbox } from '../../components';
import { replaceEmojis } from 'app/utils/getters';
import {
  ServiceOrderingField,
  ConfidentialityBlock,
  CreateRequestBlock,
  ErrorMsg,
  FieldsBlock,
  HalfFields,
  HalfFieldsBlock,
  PostButton,
  RedAsterisk,
  Required,
  ServiceOrderingComment,
} from './send-order-block.styled';
import { BlockTitle } from '../../list.styled';
import { IValidOrderFields } from '../../../../types';

interface Props {
  currentOrder: any;
  currentService: any;
  visibleServiceFullCard: any;
  preview?: boolean;
}

const getSpacelessLength = (str: string) => {
  return str.replace(/ /g, '').length;
};

export const SendOrderBlock = React.forwardRef(
  (
    { currentOrder, currentService, visibleServiceFullCard, preview }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const dispatch = useAppDispatch();
    const [agreement, setAgreement] = useState<boolean>(false);
    const [validFields, setValidFields] = useState<IValidOrderFields>({
      validName: false,
      validAddress: false,
      validPhone: false,
      validMail: false,
      validComment: false,
      validAgreement: false,
    }); // валидность полей оформления заявки
    const [blockCreate, setBlockCreate] = useState<boolean>(true);
    const { mail, name, phone, comment, address } =
      currentService.contact_details;

    useEffect(() => {
      if (Object.values(validFields).every((field) => field)) {
        setBlockCreate(false);
      } else {
        setBlockCreate(true);
      }
    }, [agreement, validFields]);

    useEffect(() => {
      if (!visibleServiceFullCard) {
        setAgreement(false);
      }
    }, [visibleServiceFullCard]);

    // валидация полей
    useEffect(() => {
      setValidFields({
        validName:
          (name !== 'necessarily' && !currentOrder.name.length) ||
          (getSpacelessLength(currentOrder.name) >= 3 &&
            currentOrder.name.length <= 255),
        validAddress:
          (address !== 'necessarily' && !currentOrder.address.length) ||
          (getSpacelessLength(currentOrder.address) >= 10 &&
            currentOrder.address.length <= 256),
        validPhone:
          (phone !== 'necessarily' && !currentOrder.phone.length) ||
          currentOrder.phone.length === 12,
        validMail:
          (mail !== 'necessarily' && !currentOrder.mail.length) ||
          checkValidEmail(currentOrder.mail),
        validComment:
          (comment !== 'necessarily' && !currentOrder.comment.length) ||
          (getSpacelessLength(currentOrder.comment) >= 5 &&
            currentOrder.comment.length <= 1000),
        validAgreement:
          (agreement && !!currentService.contact_executor.email) ||
          !!currentService.contact_executor.link,
      });
    }, [currentService, currentOrder, agreement]);

    const handleCreateOrder = () => {
      if (!!currentService.contact_executor.link) {
        const a = document.createElement('a');
        a.setAttribute('href', currentService.contact_executor.link);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        a.remove();
        if (!preview) {
          dispatch(postOrderLink());
          dispatch(clearCurrentService());
          dispatch(showServiceItemFullCard(false));
        }
      }
      if (!!currentService.contact_executor.email && !preview) {
        dispatch(postCatalogOrder()).then(() => {
          dispatch(showServiceItemFullCard(false));
          dispatch(clearCurrentOrder());
        });
      }
    };

    return (
      <CreateRequestBlock preview={preview}>
        {!!currentService.contact_executor.email && (
          <>
            <BlockTitle>Оставить заявку</BlockTitle>
            <FieldsBlock>
              {['show', 'necessarily'].includes(
                currentService.contact_details.name
              ) && (
                <>
                  <Required>
                    {name === 'necessarily' && !currentOrder.name && (
                      <RedAsterisk />
                    )}
                    <ServiceOrderingField
                      value={currentOrder.name}
                      onChange={(e) =>
                        dispatch(
                          setCurrentOrderField({
                            name: e.target.value.replace(
                              /[^а-яА-Яa-zA-ZёЁ ]/gi,
                              ''
                            ),
                          })
                        )
                      }
                      placeholder={'ФИО'}
                    />
                  </Required>
                  {!validFields.validName && (
                    <ErrorMsg>
                      {getSpacelessLength(currentOrder.name) > 0 &&
                        getSpacelessLength(currentOrder.name) < 3 &&
                        'Не менее 3 символов'}
                      {currentOrder.name.length > 255 &&
                        'Не более 255 символов'}
                    </ErrorMsg>
                  )}
                </>
              )}
              {['show', 'necessarily'].includes(
                currentService.contact_details.address
              ) && (
                <>
                  <Required>
                    {address === 'necessarily' && !currentOrder.address && (
                      <RedAsterisk />
                    )}
                    <ServiceOrderingField
                      value={currentOrder.address}
                      onChange={(e) =>
                        dispatch(
                          setCurrentOrderField({
                            address: replaceEmojis(e.target.value),
                          })
                        )
                      }
                      placeholder={'Адрес'}
                    />
                  </Required>
                  {!validFields.validAddress && (
                    <ErrorMsg>
                      {getSpacelessLength(currentOrder.address) > 0 &&
                        getSpacelessLength(currentOrder.address) < 10 &&
                        'Не менее 10 символов'}
                      {currentOrder.address.length > 256 &&
                        'Не более 256 символов'}
                    </ErrorMsg>
                  )}
                </>
              )}
              <HalfFieldsBlock>
                {['show', 'necessarily'].includes(
                  currentService.contact_details.phone
                ) && (
                  <>
                    <HalfFields
                      half={['show', 'necessarily'].includes(
                        currentService.contact_details.mail
                      )}
                    >
                      <Required>
                        {phone === 'necessarily' && !currentOrder.phone && (
                          <RedAsterisk />
                        )}
                        <ReactInputMask
                          placeholder={'Телефон'}
                          mask={'+7 999 999 99 99'}
                          onChange={(e) =>
                            dispatch(
                              setCurrentOrderField({
                                phone: e.target.value
                                  .replace(/[\s_]/g, '')
                                  .toString(),
                              })
                            )
                          }
                        >
                          {(inputProps: any) => (
                            <ServiceOrderingField {...inputProps} />
                          )}
                        </ReactInputMask>
                      </Required>
                      {!validFields.validPhone &&
                        currentOrder.phone.length !== 0 && (
                          <ErrorMsg>Введите 10 цифр</ErrorMsg>
                        )}
                    </HalfFields>
                  </>
                )}
                {['show', 'necessarily'].includes(
                  currentService.contact_details.mail
                ) && (
                  <>
                    <HalfFields
                      half={['show', 'necessarily'].includes(
                        currentService.contact_details.phone
                      )}
                    >
                      <Required>
                        {mail === 'necessarily' && !currentOrder.mail && (
                          <RedAsterisk />
                        )}
                        <ServiceOrderingField
                          value={currentOrder.mail}
                          onChange={(e) =>
                            dispatch(
                              setCurrentOrderField({
                                mail: replaceEmojis(e.target.value),
                              })
                            )
                          }
                          placeholder={'Почта'}
                        />
                      </Required>
                      {!validFields.validMail &&
                        currentOrder.mail.length > 0 && (
                          <ErrorMsg>Введите корректную почту</ErrorMsg>
                        )}
                    </HalfFields>
                  </>
                )}
              </HalfFieldsBlock>
              {['show', 'necessarily'].includes(
                currentService.contact_details.comment
              ) && (
                <>
                  <Required>
                    {comment === 'necessarily' && !currentOrder.comment && (
                      <RedAsterisk />
                    )}
                    <ServiceOrderingComment
                      onChange={(e) =>
                        dispatch(
                          setCurrentOrderField({
                            comment: e.target.value,
                          })
                        )
                      }
                      placeholder={'Укажите дополнительные пожелания'}
                    />
                  </Required>
                  {!validFields.validComment && (
                    <ErrorMsg>
                      {getSpacelessLength(currentOrder.comment) > 0 &&
                        getSpacelessLength(currentOrder.comment) < 5 &&
                        'Не менее 5 символов'}
                      {currentOrder.comment.length > 1000 &&
                        'Не более 1000 символов'}
                    </ErrorMsg>
                  )}
                </>
              )}
              <InlineCheckbox
                title={
                  <ConfidentialityBlock>
                    <div>Согласен с </div>
                    <a
                      href={'https://eis24.me/privacy-policy/'}
                      target="_blank"
                    >
                      &nbsp;политикой приватности
                    </a>
                  </ConfidentialityBlock>
                }
                disabled={false}
                checked={agreement}
                field={'Согласен с политикой приватности'}
                agreement={agreement}
                setAgreement={setAgreement}
              />
            </FieldsBlock>
          </>
        )}
        <PostButton
          onClick={handleCreateOrder}
          disabled={blockCreate}
          ref={ref}
        >
          {currentService.contact_executor.link
            ? 'Перейти по ссылке'
            : 'Отправить заявку'}
        </PostButton>
      </CreateRequestBlock>
    );
  }
);
