import { useState } from 'react';
import { getItem, removeItem, setItem } from '../utils/local-storage';

export const useLocalStorage = (key: string) => {
  const [localItem, setLocalItem] = useState(getItem(key));

  const handleSetItem = (key: string, value: any, notOverride?: boolean) => {
    // Возвращает true, если айтем записан в localStorage, зависит от notOverride
    if (setItem(key, value, notOverride)) {
      setLocalItem(value);
    }
  };

  const handleRemoveItem = (key: string) => {
    if (removeItem(key)) {
      setLocalItem(null);
    }
  };

  return [localItem, handleSetItem, handleRemoveItem];
};
