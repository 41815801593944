import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { space, SpaceProps } from 'styled-system';
import { BtnSize, BtnVariant } from './types';

type Props = {
  variant: BtnVariant;
  size: BtnSize;
} & SpaceProps;

const Variant = {
  cancel: css`
    background-color: #ffffff;
    color: #d92828;
  `,
  blue: css`
    background-color: #238fd8;
    color: #ffffff;
  `,
  login: css`
    background-color: #e87f4a;;
    color: #ffffff;
  `,
};

const Size = {
  lg: css`
    // max-width: 280px;
    //height: 47px;
  `,
};

export const StyledButton = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => Variant[props.variant]};
  ${(props) => Size[props.size]};
  ${space}
`;

export const StyledLink = styled(Link)<Props>`
  font-size: 13px;
  text-decoration: none;
  ${(props) => Variant[props.variant]};
`;
