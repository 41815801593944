import React from 'react';

import { Wrapper } from './star-rate.styled';

const stars = [1, 2, 3, 4, 5];

interface Props {
  rate: number;
  canRate: boolean;
  onRate: (newRate: number) => void;
  className?: string;
}

export const StarRate = React.memo(
  ({ rate, canRate, onRate, className }: Props) => (
    <Wrapper className={className} data-testid="star-rate">
      {stars.map((star, index) => (
        <span
          key={index}
          title={star.toString()}
          onClick={() => canRate && onRate(index + 1)}
          className={(index + 1 <= rate ? 'fas ' : 'far ') + 'fa-star'}
        />
      ))}
    </Wrapper>
  )
);
