import React from 'react';
import { SpaceProps } from 'styled-system';
import { Arrow, Calendar, CalendarIcon, Nav, Title } from './small-nav.styled';

type Props = {
  className?: string;
  title?: string;
  children: React.ReactNode;
  onBack: () => void;
  calendar?: boolean;
  handleCalendar?: () => void;
} & SpaceProps;

export const SmallNav = React.memo(
  ({
    children,
    onBack,
    title,
    className,
    calendar,
    handleCalendar,
    ...props
  }: Props) => {
    return (
      <Nav {...props} className={className} data-testid={'small-nav'}>
        <Arrow type={'button'} onClick={onBack} data-testid={'small-nav-arrow'}>
          &#10229;
        </Arrow>
        <Title title={title}>{children}</Title>
        {calendar && (
          <Calendar
            className={'small-nav__calendar'}
            onClick={handleCalendar}
            data-testid={'calendar'}
          >
            <CalendarIcon />
          </Calendar>
        )}
      </Nav>
    );
  }
);
