import React from 'react';
import { Wrapper, Explanation, RedHeader } from './stages.styled';

/** ШАГ ОБРЫВА, КОГДА ОПЛАТИТЬ НЕВОЗМОЖНО **/
/** ../#/qr/payment/blocked/ **/
export const Stage9 = () => {
  return (
    <Wrapper>
      <RedHeader>Оплата невозможна</RedHeader>
      <Explanation>
        К сожалению, платёж по Вашему лицевому счету невозможен в связи с
        ограничением работы личного кабинета.
      </Explanation>
    </Wrapper>
  );
};
