import React from 'react';
import { Wrapper, Icon, Status, Text } from './payer-status.styled';
import { useSelector } from 'react-redux';
import { getSelectedSector } from '../../selectors';
import good from '../../assets/good.svg';
import debtor from '../../assets/debtor.svg';

interface Props {
  balance: string;
}

export const PayerStatus = React.memo(({ balance }: Props) => {
  const selectedSector = useSelector(getSelectedSector);
  return (
    <Wrapper>
      <Icon src={Number(balance) === 0 ? good : debtor} />
      <Status debtor={Number(balance) !== 0}>
        {Number(balance) === 0 ? 'Долгов нет' : `Долг ${balance} руб.`}
      </Status>
      <Text>{selectedSector.text}</Text>
    </Wrapper>
  );
});
