import { PayloadAction } from '@reduxjs/toolkit';
import { showServerError } from '../root-slice';

export const errorMiddleware = (store: any) => (next: any) => (
  action: PayloadAction<any>
) => {
  if (action?.payload?.errorMessage?.length) {
    store.dispatch(showServerError(action.payload.errorMessage));
  }
  next(action);
};
