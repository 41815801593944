import styled from 'styled-components/macro';

export const Input = styled.input`
  display: block;
  width: inherit;
  height: inherit;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 20px 11px 8px 11px;
  font-size: 13px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance:textfield;
  }

  &:not(:placeholder-shown) + div {
    font-size: 10px;
    top: 4px;
  }

  & + div {
    position: absolute;
    top: 14px;
    left: 11px;
    color: #212121;
    opacity: 0.5;
    font-size: 12px;
    transition: top 0.3s ease;
    pointer-events: none;
  }

  &:focus {
    outline: none;

    & + div {
      font-size: 10px;
      top: 4px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
`;
