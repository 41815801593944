import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper,
  Logo,
  Arrow,
  Header,
  Content,
} from './qr-redirect-layout.styled';
import { useSelector } from 'react-redux';
import { getQrPaymentSelector } from '../../pages/qr-payment/selectors';

interface Props {
  children: React.ReactChild;
}

export const QrRedirectLayout = ({ children }: Props) => {
  const { fromRedirect } = useSelector(getQrPaymentSelector);
  const history = useHistory();
  const { pathname } = useLocation();

  /* помогает избежать непредвиденных сценариев и ветвит схему только на выбор адресов и направлений */
  const initialPages = [
    '/qr/payment/phone',
    '/qr/payment/phone/',
    '/qr/payment/greetings/',
    '/qr/payment/continuation/',
    '/qr/payment/address/',
    '/qr/payment/email/',
    // '/qr/payment/blocked/',
  ];

  //!(fromRedirect && pathname === '/qr/payment/address/')

  return (
    <Wrapper>
      <Header>
        {!initialPages.includes(pathname) && (
          <Arrow onClick={() => history.goBack()} />
        )}
        <Logo />
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};
