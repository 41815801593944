import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedAddressSelector, getSelectedSector } from '../../selectors';
import { useAppDispatch } from '../../../../hooks';
import { Wrapper, Section } from './sector-selection.styled';
import { selectSector } from '../../qr-payment-slice';

interface ISector {
  text: string;
  value: string;
}

export const SectorSelection = React.memo(() => {
  const dispatch = useAppDispatch();
  const sectors = useSelector(getSelectedAddressSelector).sectors;
  const selectedSector = useSelector(getSelectedSector);

  const handleSelect = (index: number) => {
    dispatch(selectSector(sectors[index]));
  };

  return (
    <Wrapper>
      {sectors.map((item: ISector, index: number) => (
        <Section
          key={index}
          selected={item.value === selectedSector.value}
          onClick={() => handleSelect(index)}
        >
          {item.text}
        </Section>
      ))}
    </Wrapper>
  );
});
