import styled from 'styled-components/macro';
import { MFMedia } from '../../../../../../../styles/media-breakpoints';

export const CategoryBtnBox = styled.div`
  padding-right: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    color: #202939;
    position: relative;
    padding: 10px 0;
    margin-left: 12px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e3e8ef;
    border-radius: 0;

    &:hover {
      background: initial;
      color: #4b5565;
    }
  }
`;

export const CategoryBtn = styled.div<{ category?: string | null }>`
  padding: 10px 12px;
  background: ${(props) =>
    props.category === 'all' || props.category === null
      ? '#49994c'
      : '#ffffff'};
  border: 1px solid #e3e8ef;
  border-radius: 16px;
  width: max-content;
  cursor: pointer;
  color: ${(props) =>
    props.category === 'all' || props.category === null
      ? '#FFFFFF'
      : '#4b5565'};

  @media (${MFMedia.tablet}) {
    pointer-events: ${(props) =>
      props.category === 'all' || props.category === null ? 'none' : 'initial'};
    touch-action: ${(props) =>
      props.category === 'all' || props.category === null ? 'none' : 'initial'};
  }
`;

export const CategoryCarouselBtnBox = styled.div`
  padding-right: 10px;
  width: max-content;
`;

export const ArrowRight = styled.i`
  border: solid #4b5565;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
