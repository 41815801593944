import React, { useEffect, useState } from 'react';
import { Explanation, CallAgain, Header, Wrapper } from './stages.styled';
import { Button, CodeInput } from '../components';
import { getQrPaymentSelector } from '../selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
  getAddresses,
  updateTimer,
  clearTimer,
  sendCode,
  initCall,
} from '../qr-payment-slice';
import { useHistory } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { unwrapResult } from '@reduxjs/toolkit';
import { preparePhone } from '../utils';

/** ШАГ ВВОДА КОДА ПОДТВЕРЖДЕНИЯ **/
/** ../#/qr/payment/confirm/ **/
export const Stage2 = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { phone, code, timer, callTaskId } = useSelector(getQrPaymentSelector);
  const [error, setError] = useState(false);

  const [isTimer, setIsTimer] = useState(true);
  let interval: any = null;

  useEffect(() => {
    if (code.length < 4) {
      setError(false);
    }
  }, [code]);

  useEffect(() => {
    if (timer !== 30) {
      dispatch(clearTimer());
      setIsTimer(true);
    }
  }, [phone]);

  useEffect(() => {
    if (isTimer) {
      interval = setInterval(() => {
        dispatch(updateTimer());
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimer]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(interval);
      interval = null;
      setIsTimer(false);
    }
    if (timer === 30 && !isTimer) {
      setIsTimer(true);
    }
  }, [timer]);

  const handleInitCall = () => {
    dispatch(
      initCall({
        guest_number: preparePhone(phone),
      })
    )
      .then(unwrapResult)
      .then(
        (response) => {
          setIsTimer(true);
        },
        (error) => {
          //...
        }
      );
  };

  const handleNext = () => {
    dispatch(
      sendCode({
        id: callTaskId,
        data: {
          verification_num: code,
        },
      })
    )
      .then(unwrapResult)
      .then(
        (response) => {
          if (response.status === 'success') {
            setError(false);
            clearInterval(interval);
            interval = null;
            setIsTimer(false);
            dispatch(getAddresses({ phone: preparePhone(phone) }))
              .then(unwrapResult)
              .then(
                (response) => {
                  if (response.tenant_addresses.length) {
                    history.push(qrRedirectRoutes.qr.payment.address.link);
                  } else {
                    if (response.status === 'denied') {
                      history.push(qrRedirectRoutes.qr.payment.blocked.link);
                    } else {
                      history.push(qrRedirectRoutes.qr.payment.email.link);
                    }
                  }
                },
                (error) => {
                  //ERROR
                }
              );
          } else {
            setError(true);
            setIsTimer(false);
          }
        },
        (error) => {
          //...
        }
      );
  };

  return (
    <Wrapper>
      <Header>Подтвердите номер телефона</Header>
      <Explanation>
        На номер {phone} совершён звонок. Введите последние 4 цифры позвонившего
        Вам номера.
      </Explanation>
      <CodeInput isError={error} />
      <Button disabled={code.length !== 4} onClick={() => handleNext()}>
        {error ? 'Попробовать ещё раз' : 'Подтвердить'}
      </Button>
      <CallAgain
        isTimer={isTimer}
        onClick={isTimer ? () => {} : () => handleInitCall()}
      >
        {isTimer && !error
          ? `Позвонить ещё раз через ${timer} секунд`
          : 'Позвонить ещё раз'}
      </CallAgain>
    </Wrapper>
  );
};
