import styled from 'styled-components/macro';
import { MFMedia } from '../../styles/media-breakpoints';

import { ResetButtonMixin } from '../../styles/mixins';

export const RemoveButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  ${ResetButtonMixin};
`;

export const Row = styled.div`
  color: #4c4c4c;
  font-size: 13px;
  margin-bottom: 4px;
`;

export const Title = styled.div`
  color: #ff4141;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 8px 0;
`;

export const Panel = styled.div`
  position: fixed;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 290px;
  min-height: 144px;
  background: #ffffff;
  box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.15), 0px 0px 12.5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 18px;
  z-index: 400;

  @media (${MFMedia.smallPC}) {
    max-width: 434px;
    min-height: 229px;
    padding: 28px;
    top: 200px;

    & > img {
      width: 62px;
    }

    ${Title} {
      font-size: 22px;
      margin: 16px 0 10px 0;
    }

    ${Row} {
      font-size: 16px;
    }

    ${RemoveButton} {
      top: 22px;
      right: 22px;
      & > img {
        width: 15px;
      }
    }
  }
`;
