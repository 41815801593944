import chip from 'app/components/bank-card/assets/chip.svg';
import { MFMedia } from 'app/styles/media-breakpoints';
import styled from 'styled-components/macro';

const GradientText = styled.div`
  font-family: Roboto, SF Pro normal, Arial, Helvetica, sans-serif;
  font-style: normal;
  //display: table;
  color: #ffffff;
  //background: radial-gradient(
  //        59.78% 20.73% at 50.14% 51.22%,
  //        rgba(255, 255, 255, 0.8) 51.73%,
  //        #ffffff 100%
  //);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
`;

export const CardText = styled(GradientText)`
  position: absolute;
  bottom: 10px;
  left: 36px;
  font-size: 12px;
`;

export const Number = styled(GradientText)`
  font-size: 17px;
  margin-top: 16px;
  margin-left: 36px;
`;

export const Chip = styled.img.attrs({ src: chip, alt: 'chip' })`
  margin-top: 31px;
  margin-left: 36px;
  width: 37px;
`;

export const Types = styled.div`
  margin-top: 14px;
  margin-right: 14px;
  text-align: right;

  img {
    width: 32px;
    margin-left: 3px;
  }
`;

export const Card = styled.div<{ selected: boolean }>`
  position: relative;
  border: none;
  outline: none;
  width: 300px;
  height: 190px;
  background-color: #232c7a;
  //box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      21.63deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.33) 39%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.6;
  }

  @media (${MFMedia.smallPC}) {
    border: ${(props) =>
      props.selected ? '2px solid #56c9fa' : '2px solid transparent'};
  }
`;
