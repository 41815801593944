import React, { memo, useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IMeter } from 'app/pages/meters/types';
import { IConstants } from '../../../../types';
import { getStrokeColor, prepareChartData } from '../../utils';
import { TooltipBlack } from '../tooltip-black/tooltip-black';

interface Props {
  meters: IMeter[];
  constants: IConstants;
}

export const MetersAreaChart = memo(({ meters, constants }: Props) => {
  const data = prepareChartData(meters, constants);
  const toRender: JSX.Element[] = useMemo(() => prepareChartLines(meters), [
    meters,
  ]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={100}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: -26,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis strokeWidth={1} dataKey="name" />
        <YAxis strokeWidth={1} />
        <Tooltip
          content={<TooltipBlack constants={constants} meters={meters} />}
        />
        {toRender}
      </AreaChart>
    </ResponsiveContainer>
  );
});

const prepareChartLines = (meters: IMeter[]) => {
  const toRender: JSX.Element[] = [];

  meters.forEach((meter) => {
    if (meter.readings.length) {
      meter.readings[0].deltas.forEach((delta, index) => {
        const color = getStrokeColor(meter.type, index) || '#000000';
        toRender.push(
          <Area
            dot={false}
            key={meter.id + index}
            type={'monotone'}
            dataKey={meter.id + index}
            strokeWidth={2}
            fillOpacity={0.4}
            fill={color}
            stroke={color}
          />
        );
      });
    }
  });

  return toRender;
};
