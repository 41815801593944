import React, { InputHTMLAttributes } from 'react';

import { Dot, Input, Round, Slider, Switch } from './checkbox.styled';

interface IBase {
  variant?: 'green' | 'blue';
  disabled?: boolean;
}

type IBaseProps = InputHTMLAttributes<HTMLInputElement> & IBase;

interface IToggleProps extends IBaseProps {
  reference?: React.Ref<HTMLInputElement>;
}

const Toggle = React.memo(
  ({
    reference,
    variant = 'green',
    disabled = false,
    type = 'checkbox',
    checked = false,
    className,
    ...rest
  }: IToggleProps) => {
    return (
      <Switch
        variant={variant}
        className={className}
        disabled={disabled}
        data-testid={'checkbox-toggle'}
      >
        <Input
          defaultChecked={checked}
          type={type}
          ref={reference}
          disabled={disabled}
          {...rest}
        />
        <Slider data-testid={'checkbox-toggle-slider'} />
      </Switch>
    );
  }
);

/* Контролируемый снаружи чекбокс */
const ToggleControlled = React.memo(
  ({
    type = 'checkbox',
    variant = 'green',
    disabled = false,
    className,
    ...rest
  }: IBaseProps) => {
    return (
      <Switch
        variant={variant}
        className={className}
        disabled={disabled}
        data-testid={'checkbox-toggle-controlled'}
      >
        <Input type={type} disabled={disabled} {...rest} />
        <Slider data-testid={'checkbox-toggle-controlled-slider'} />
      </Switch>
    );
  }
);

const Circle = React.memo(
  ({
    reference,
    variant = 'green',
    type = 'checkbox',
    checked = false,
    disabled = false,
    className,
    ...rest
  }: IToggleProps) => {
    return (
      <Round
        variant={variant}
        className={className}
        data-testid={'circle-checkbox'}
      >
        <Input
          type={type}
          defaultChecked={checked}
          ref={reference}
          disabled={disabled}
          {...rest}
        />
        <Dot data-testid={'circle-checkbox-dot'} />
      </Round>
    );
  }
);

const CircleControlled = React.memo(
  ({
    variant = 'green',
    type = 'checkbox',
    checked = false,
    disabled = false,
    className,
    ...rest
  }: IToggleProps) => {
    return (
      <Round
        variant={variant}
        className={className}
        data-testid={'circle-checkbox'}
      >
        <Input type={type} disabled={disabled} checked={checked} {...rest} />
        <Dot data-testid={'circle-checkbox-dot'} />
      </Round>
    );
  }
);

export const CheckBox = {
  Toggle,
  ToggleControlled,
  Circle,
  CircleControlled,
};
