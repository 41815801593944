import React from 'react';
import { ServiceCompanyWorkTime } from './company-work-time.styled';

interface Props {
  monday: {
    work_finish_at: string;
    work_start_at: string;
  } | null;
  sunday: {
    work_finish_at: string;
    work_start_at: string;
  } | null;
  isEqual: boolean;
}

export const CompanyWorkTime = ({ monday, sunday, isEqual }: Props) => {
  return (
    <ServiceCompanyWorkTime>
      {(monday?.work_start_at && monday?.work_finish_at) && (
        <div>
          {isEqual ? 'ПН-ВС' : 'ПН-ПТ'}: {monday.work_start_at} -{' '}
          {monday.work_finish_at}
        </div>
      )}
      {(sunday?.work_start_at && sunday?.work_finish_at) && !isEqual && (
        <div>
          СБ-ВС: {sunday.work_start_at} - {sunday.work_finish_at}
        </div>
      )}
    </ServiceCompanyWorkTime>
  );
};
