import { Button } from 'app/components/index';
import { useAppDispatch } from 'app/hooks';
import { getTimeWarningState } from 'app/store/root-selectors';
import { hideTimeWarning } from 'app/store/root-slice';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Image,
  SmallText,
  Title,
  TopSection,
  Wrapper,
} from './timeWarning.styled';

export const TimeWarning = React.memo(() => {
  const dispatch = useAppDispatch();
  const { show } = useSelector(getTimeWarningState);

  if (!show) {
    return <></>;
  }

  const handleBack = () => {
    dispatch(hideTimeWarning());
  };

  return (
    <Wrapper>
      <TopSection>
        <Image />
        <Title>Повторите попытку позже</Title>
        <SmallText>
          В целях безопасности оплата чаще, чем 1 раз в 10 секунд, заблокирована.
          <br />
          Пожалуйста, повторите попытку чуть позже.
        </SmallText>
      </TopSection>
      <Button onClick={handleBack} variant={'lightblue'} size={'lg'}>
        Вернуться
      </Button>
    </Wrapper>
  );
});
