import React from 'react';
import { Wrapper, StyledInput, InputLabel } from './inline-checkbox.styled';

interface Props {
  title: JSX.Element;
  field: string;
  disabled: boolean;
  checked: boolean;
  agreement: boolean;
  setAgreement: Function;
}

export const InlineCheckbox = ({
  title,
  field,
  disabled,
  checked,
  setAgreement,
  agreement,
}: Props) => {
  return (
    <Wrapper>
      <StyledInput
        id={field}
        value={field}
        disabled={disabled}
        onChange={() => setAgreement(!agreement)}
        checked={checked}
      />
      <InputLabel htmlFor={field}>{title}</InputLabel>
    </Wrapper>
  );
};
