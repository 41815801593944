/* Слайс, хранящий и авторизацию, и регистрацию, и инфу о залогиненом текущем юзере */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ActivateAccount,
  GetConstants,
  GetUserInfo,
  LogIn,
  LogOut,
  ResetPassword,
  SignUp,
  SwitchAccount,
} from 'app/api/auth';
import {
  hideAuthLoader,
  showAuthLoader,
  showWarning,
} from 'app/store/root-slice';
import { IConstants } from 'app/types';
import { getBgImage, getFaviconImage } from 'app/utils/random-images';
import { ICredentials, IRegisterDetails, IUserInfo } from './types';

export interface InitialState {
  user: IUserInfo;
  constants: IConstants;
  onLoad: boolean; // Процесс загрузки инфы о юзере(влияет на то, авторизован он или нет, в том числе)
  isAuth: boolean;
  isPerson: boolean;
  bgImage: any;
  favImage: any;
  login: {
    isError: boolean;
  };
  register: {
    isError: boolean | string;
  };
  resetPassword: {
    isError: boolean;
  };
  activate: {
    isError: boolean | string;
  };
  visit_counter: number;
}

export const initialState: InitialState = {
  user: {} as IUserInfo,
  constants: {} as IConstants,
  onLoad: false,
  isAuth: false,
  isPerson: false,
  bgImage: '',
  favImage: '',
  login: {
    isError: false,
  },
  register: {
    isError: false,
  },
  resetPassword: {
    isError: false,
  },
  activate: {
    isError: false,
  },
  visit_counter: 0,
};

export const getDynamicImages = createAsyncThunk(
  'auth/getDynamicImages',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const bgImage = await getBgImage();
      const favImage = await getFaviconImage();
      return {
        bgImage,
        favImage,
      };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showAuthLoader());
      await dispatch(getDynamicImages());
      const response = await GetUserInfo();
      dispatch(hideAuthLoader());
      return response;
    } catch (e) {
      dispatch(hideAuthLoader());
      return rejectWithValue(e.message);
    }
  }
);

/* Запрос информации о пользователе без посторонних эффектов */
export const getUserInfoSoft = createAsyncThunk(
  'auth/getUserInfoSoft',
  async (_, { rejectWithValue }) => {
    try {
      return await GetUserInfo();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getConstants = createAsyncThunk(
  'auth/getConstants',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetConstants();
      return response.results;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const logIn = createAsyncThunk<any, ICredentials, {}>(
  'auth/logIn',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      const data = await LogIn(credentials);
      dispatch(getUserInfo());
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const logOut = createAsyncThunk(
  'auth/logOut',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const data = await LogOut();
      window.location.reload();
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const register = createAsyncThunk<
  any, // Возвращаемое значение
  IRegisterDetails, // credentials
  any // dispatch и тп
>('auth/register', async (details, { rejectWithValue, dispatch }) => {
  try {
    return await SignUp(details);
  } catch (e) {
    const response = await e.response.json();
    return rejectWithValue(response);
  }
});

export const switchAccount = createAsyncThunk<any, string, any>(
  'auth/switchAccount',
  async (accountId, { dispatch, rejectWithValue }) => {
    try {
      const result = await SwitchAccount(accountId);
      if (result.status === 'OK') {
        window.location.reload();
      } else {
        dispatch(showWarning());
      }
    } catch (e) {
      window.location.reload();
      return rejectWithValue(e.message);
    }
  }
);

export const resetPassword = createAsyncThunk<
  any, // Возвращаемое значение
  string, // credentials
  any // dispatch и тп
>('auth/resetPassword', async (username, { rejectWithValue, dispatch }) => {
  try {
    return await ResetPassword(username);
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

export const activateAccount = createAsyncThunk<
  any, // Возвращаемое значение{
  {
    areaNumber: string;
    accountId: string;
  }, // credentials
  any // dispatch и тп
>('auth/activateAccount', async (data, { rejectWithValue, dispatch }) => {
  try {
    return await ActivateAccount(data);
  } catch (e) {
    if (e.response.status === 500) {
      dispatch(showWarning());
      return rejectWithValue(e.message);
    } else {
      const response = await e.response.json();
      return rejectWithValue(response);
    }
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.register.isError = false;
      state.login.isError = false;
      state.resetPassword.isError = false;
      state.activate.isError = false;
    },
    clearLoginError: (state) => {
      state.register.isError = false;
    },
    visitIncrement: (state, action: PayloadAction<number>) => {
      state.visit_counter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Получение информации о пользователе
    builder.addCase(getUserInfo.pending, (state, action) => {
      state.onLoad = true;
    });
    builder.addCase(getDynamicImages.fulfilled, (state, action) => {
      state.bgImage = action.payload.bgImage;
      state.favImage = action.payload.favImage;
    });
    builder.addCase(
      getUserInfo.fulfilled,
      (state, action: PayloadAction<IUserInfo>) => {
        state.user = action.payload;
        state.onLoad = false;
        state.isAuth = true;
        state.isPerson =
          !action.payload.connected.length && !action.payload.owner.area._id;
      }
    );
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.onLoad = false;
      state.isAuth = false;
    });
    builder.addCase(
      getUserInfoSoft.fulfilled,
      (state, action: PayloadAction<IUserInfo>) => {
        state.user = action.payload;
      }
    );

    // Получение всех основных констант ЛКЖ
    builder.addCase(getConstants.fulfilled, (state, action) => {
      state.constants = action.payload;
    });

    // Авторизация
    builder.addCase(logIn.pending, (state, action) => {
      state.login.isError = false;
    });
    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.login.isError = false;
      state.visit_counter = payload.visit_counter;
    });
    builder.addCase(logIn.rejected, (state, action) => {
      state.isAuth = false;
      state.login.isError = true;
    });

    // Регистрация
    builder.addCase(register.pending, (state, action) => {
      state.register.isError = false;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.register.isError = false;
    });
    builder.addCase(register.rejected, (state, action) => {
      let error: any = action.payload;
      state.register.isError = error.message as string;
    });

    // Активация
    builder.addCase(activateAccount.pending, (state, action) => {
      state.activate.isError = false;
    });
    builder.addCase(activateAccount.fulfilled, (state, action) => {
      state.activate.isError = false;
    });
    builder.addCase(activateAccount.rejected, (state, action) => {
      let error: any = action.payload;
      if (error) {
        state.activate.isError = error as string;
      } else {
        state.activate.isError = true;
      }
    });

    // Сброс пароля
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resetPassword.isError = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPassword.isError = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetPassword.isError = true;
    });
  },
});

export const { visitIncrement } = authSlice.actions;
export default authSlice.reducer;
