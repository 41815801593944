import React, { useState } from 'react';
import { ResultPanel, ResultPanelProps } from '../components';

export const useOldResultPanel = ({
  text,
  state,
  className,
  onClose,
}: ResultPanelProps & { onClose: () => void }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(false);
    onClose && onClose();
  };
  return {
    Panel: () => (
      <>
        {open && (
          <ResultPanel
            text={text}
            state={state}
            onClick={handleClick}
            className={className}
          />
        )}
      </>
    ),
    showPanel: () => setOpen(true),
  };
};
