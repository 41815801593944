import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { authorizedRoutes, unauthorizedRoutes } from 'app/routesConfig';
import { ServicesList, MobilePreview, PCPreview } from './tabs';

export const Routes = () => {
  const { servisesList } = authorizedRoutes.catalog;
  const { previewMobile, previewPC } = unauthorizedRoutes.catalog;

  return (
    <Switch>
      <Route path={servisesList.link} component={ServicesList} />
      <Route path={previewMobile.link} component={MobilePreview} />
      <Route path={previewPC.link} component={PCPreview} />
    </Switch>
  );
};
