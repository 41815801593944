import React, { useEffect, useState } from 'react';
import {
  Description,
  ServiceCardBlock,
  FloatImg,
  ServiceCardTitleBlock,
  ServiceCardTitle,
  ServiceCardDescriptionBlock,
  // ServicePrice,
  // ServiceOldPrice,
  // Star,
  ServiceCardContent,
  ServiceCoverContainer,
} from './service-card.styled';
import { plug } from '../../../../assets';
import { useAppDispatch } from 'app/hooks';
import {
  setCurrentService,
  // setFavorite,
  showServiceItemFullCard,
} from '../../../../catalog-slice';
import { IService } from '../../../../types';
import { ServicePrice } from '../service-price';

interface Props {
  service: IService;
}

export const ServiceCard = ({ service }: Props) => {
  const dispatch = useAppDispatch();
  const [currentWallpaperFile, setCurrentWallpaperFile] = useState(plug);

  useEffect(() => {
    if (service.image_cover?.file) {
      setCurrentWallpaperFile(
        `/api/v4/compendium/file/${service.id}/?image_cover=${service.image_cover.file}`
      );
    } else {
      setCurrentWallpaperFile(plug);
    }
  }, [service]);

  const handleShowService = (service: any) => {
    dispatch(showServiceItemFullCard(true));
    dispatch(setCurrentService(service));
  };

  // const handleRate = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();
  //   dispatch(
  //     setFavorite({
  //       id: position.id,
  //       method: position.favorite ? 'delete' : 'add',
  //     })
  //   );
  // };

  return (
    <ServiceCardBlock onClick={() => handleShowService(service)}>
      <ServiceCoverContainer>
        <FloatImg src={currentWallpaperFile} alt="" />
      </ServiceCoverContainer>
      <ServiceCardContent>
        <ServiceCardTitleBlock>
          <ServiceCardTitle>{service.title}</ServiceCardTitle>
          {/*<Star*/}
          {/*  onClick={(e) => handleRate(e)}*/}
          {/*  className={service.favorite ? 'fas fa-star' : 'far fa-star'}*/}
          {/*/>*/}
        </ServiceCardTitleBlock>
        <ServicePrice service={service} />
        <ServiceCardDescriptionBlock>
          <Description
            dangerouslySetInnerHTML={{ __html: service.description }}
          ></Description>
        </ServiceCardDescriptionBlock>
      </ServiceCardContent>
    </ServiceCardBlock>
  );
};
