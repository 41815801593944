import React from 'react';

import { Input, Wrapper } from './smooth-input.styled';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
}

export const SmoothInput = React.forwardRef(
  (
    { className, placeholder = ' ', label, ...props }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <Wrapper className={className}>
        <Input ref={ref} placeholder={placeholder} {...props} />
        <div>{label}</div>
      </Wrapper>
    );
  }
);
