import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OldConfirm } from '../components';

import {
  getBillingInfo,
  setEmptyBillingValue,
} from '../pages/accruals/accruals-slice';
import { ACCRUAL_ALERT_TEXT } from '../pages/accruals/constants';
import { authorizedRoutes } from '../routesConfig';
import { useAppDispatch } from './redux-hooks';

export const usePrepayment = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  const handlePrepayment = (sector: string, isPrepayment: boolean) =>
    dispatch(
      getBillingInfo({
        sector_code: sector,
      })
    )
      .then(unwrapResult)
      .then(
        (response) => {
          if (!isEmpty(response)) {
            isPrepayment && dispatch(setEmptyBillingValue()); //isPrepayment ? обнуляет сумму на страничке оплаты : выводит всю задолженность
            history.push(authorizedRoutes.accruals.payment.link);
          } else {
            setShowConfirm(true);
          }
        },
        () => {}
      );

  const handleCloseConfirm = () => setShowConfirm(false);

  return {
    createPrepayment: handlePrepayment,
    AlertPrepayment: () => (
      <>
        {showConfirm && (
          <OldConfirm
            title={'Внимание'}
            body={ACCRUAL_ALERT_TEXT}
            type={'alert'}
            confirmText={'Понятно'}
            onConfirm={handleCloseConfirm}
          />
        )}
      </>
    ),
  };
};
