export class ResponseError extends Error {
  public response: Response;
  public errorMessage: string[] = [];

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    (response.url.includes('acquiring/fast_transaction') &&
      response.status === 200)
  ) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @param {string} url   A current request url
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: Response, url: string) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401 && url !== '/api/v4/auth/current/') {
    window.location.reload();
  }

  const error = new ResponseError(response);
  error.response = response;

  if (response.status === 406 ||
      response.status === 409
  ) {
    const message = await response.json();
    error.errorMessage = message.errors;
  }

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit
): Promise<any | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  const response = await checkStatus(fetchResponse, url);
  if (response.headers.get('content-type')?.includes('text/html')) {
    return response.text();
  }
  return parseJSON(response);
}
