import React from 'react';
import { isEqual } from 'lodash';
import { CompanyWorkTime } from '../../components';
import {
  CompanyRow,
  ServiceCompanyBlock,
  ServiceCompanyAddress,
  ServiceCompanyName,
  ServiceExecutorExtraBlock,
} from './executor.styled';
import { ServiceCompanyExtraInfo, BlockTitle } from '../../list.styled';

interface Props {
  executor: any;
  preview?: boolean;
}

export const Executor = ({ executor, preview }: Props) => {
  return (
    <ServiceCompanyBlock>
      <BlockTitle>О компании</BlockTitle>
      {!!executor ? (
        <>
          <ServiceCompanyName>
            {executor.str_name}
          </ServiceCompanyName>
          <CompanyRow style={{ alignItems: 'start' }} preview={preview}>
            {/* Время работы компании пока не является обязательным при создании услуги */}
            {(executor.work_schedule?.monday?.work_start_at ||
              executor.work_schedule?.sunday?.work_start_at) && (
                <CompanyWorkTime
                  monday={executor.work_schedule.monday}
                  sunday={executor.work_schedule.sunday}
                  isEqual={isEqual(
                    executor.work_schedule.monday,
                    executor.work_schedule.sunday
                  )}
                />
              )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ServiceExecutorExtraBlock>
                {executor.works_under_contract && (
                  <ServiceCompanyExtraInfo>
                    Работает по договору
                  </ServiceCompanyExtraInfo>
                )}
                {executor.requisites_confirmed && (
                  <ServiceCompanyExtraInfo>
                    Реквизиты проверены
                  </ServiceCompanyExtraInfo>
                )}
              </ServiceExecutorExtraBlock>
              <CompanyRow style={{ marginTop: '2px' }}>
                <ServiceCompanyAddress>
                  {executor.address}
                </ServiceCompanyAddress>
              </CompanyRow>
            </div>
          </CompanyRow>
        </>
      ) : (
        <ServiceCompanyName>
          Информация о компании уточняется
        </ServiceCompanyName>
      )}
    </ServiceCompanyBlock>
  );
};
