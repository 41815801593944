import styled from 'styled-components/macro';
import { ResetButtonMixin } from 'app/styles/mixins';
import phone from './assets/phone.svg';
import swipe from './assets/swipe.gif';

export const Phone = styled.img.attrs({ src: phone })`
  position: relative;
  z-index: 10;
`;

export const Swipe = styled.img.attrs({
  src: swipe,
  alt: 'Смахните вправо, что бы закрыть документ',
})`
  width: 184px;
  position: absolute;
  left: 41px;
  top: 68px;
  z-index: 1;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Title = styled.div`
  color: #262626;
  font-size: 28px;
  font-weight: 700;
  margin-top: 50px;
`;

export const Note = styled.div`
  color: #a6a6a6;
  font-size: 18px;
  margin-top: 12px;
  width: 280px;
  text-align: center;
`;

export const Button = styled.button`
  ${ResetButtonMixin};
  width: 184px;
  height: 51px;
  background: #49b3f8;
  border-radius: 12px;
  color: #ffffff;
  font-size: 21px;
  font-weight: 700;
  margin-top: 25px;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 999;

  // IPhone SE и ниже
  @media (max-height: 568px) {
    ${Phone} {
      width: 224px;
    }

    ${Swipe} {
      width: 140px;
    }

    ${Title} {
      margin-top: 10px;
    }
  }
`;
