import styled from 'styled-components/macro';
import { Button } from '../button';
import elexnetLogo from './assets/elexnet_new.png';
import gazpromLogo from './assets/gazprom_logo.png';
import { MFMedia } from '../../styles/media-breakpoints';

export const Panel = styled.div<{ infoWindow: boolean }>`
  padding: 30px;
  position: fixed;
  z-index: 400;
  //bottom: 0;
  top: 83px;
  left: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  max-width: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //padding-bottom: 15px;
  //left: 50%;
  //transform: translateX(-50%);
  //top: 20%;
  margin-bottom: 30px;
  overflow-y: scroll;
  min-height: 300px;
  max-height: 650px;
  box-shadow: 1px 1px 1px 2px #f6f6f6;
  
  @media (max-height: 580px) {
    max-height: 500px;
  }
  
  @media(max-height: 667px) {
    max-height: 570px;
  }

  @media (max-width: 768px) {
    margin: auto;
    left: 0;
    right: 0;
  }

  @media (${MFMedia.tablet}) {
    margin: auto;
    left: 0;
    right: 0;
  }

  @media (${MFMedia.smallPC}) {
    right: unset;
    bottom: unset;
    left: 35%;
    top: 20%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4b506d;

  @media (max-width: 450px) {
    min-height: 164px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;

  @media (${MFMedia.smallPC}) {
    font-size: 24px;
  }
`;

export const ElexnetLogo = styled.img.attrs({
  src: elexnetLogo,
})`
  padding: 10px 0;
`;

export const GazpromLogo = styled.img.attrs({
  src: gazpromLogo,
})`
  width: 108px;
  height: 100px;
  padding: 10px 0;
`;

export const LogoHeader = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 30px 0;
  text-align: center;

  @media (max-height: 580px) {
    padding: 15px;
  }
`;

export const Block = styled.div`
  padding: 25px 20px;
  color: #4b506d;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 10px;

  /* настройка для размеров 6-ого айфона*/
  @media (max-width: 380px) and (max-height: 710px) and (min-width: 360px) and (min-height: 540px) {
    min-height: 775px;
  }
  
  @media (max-height: 580px) {
    padding: 15px;
  }

  @media (max-width: 500px) {
    margin-top: 45px;
  }
`;

export const BlockHeader = styled.div`
  font-weight: 500;
  padding-bottom: 5px;
`;

export const BlockBody = styled.div`
  font-weight: 300;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContentBlock = styled.div`
  //max-width: 280px;
  //min-width: 200px;
  width: 280px;
  position: relative;
  padding: 20px 50px;
    
  @media (max-height: 580px) {
    //padding: 10px 0 10px 40px; 
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: 20px;
  left: 0;

  @media (max-height: 580px) {
    top: 3px;
  }
`;

export const ElexnetConditions = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
  padding: 20px 0;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 380px) and (max-height: 710px) and (min-width: 360px) and (min-height: 540px) {
    min-height: 195px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
  font-size: 18px;
  //width: 100%;
  width: 290px;
  font-weight: 500;
  align-self: center;
  height: 47px;
`;

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.2;
  background-color: #000000;
  z-index: 400;
`;
