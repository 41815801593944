import React from 'react';
import { Wrapper } from './card.styled';

export type Br = 'sm' | 'md' | 'lg' | 'xl';

interface Props {
  children: React.ReactNode;
  br?: Br;
  className?: string;
  onClick?: () => void;
}

export const Card = React.memo(
  ({ children, className, onClick, br = 'lg' }: Props) => (
    <Wrapper onClick={onClick} className={className} br={br} data-testid="card">
      {children}
    </Wrapper>
  )
);
