import SFProBlackWoff from 'assets/fonts/SFProDisplay-Black.woff';
import SFProBlackWoff2 from 'assets/fonts/SFProDisplay-Black.woff2';
import SFProBoldWoff from 'assets/fonts/SFProDisplay-Bold.woff';
import SFProBoldWoff2 from 'assets/fonts/SFProDisplay-Bold.woff2';
import SFProLightWoff from 'assets/fonts/SFProDisplay-Light.woff';
import SFProLightWoff2 from 'assets/fonts/SFProDisplay-Light.woff2';
import SFProMediumWoff from 'assets/fonts/SFProDisplay-Medium.woff';
import SFProMediumWoff2 from 'assets/fonts/SFProDisplay-Medium.woff2';
import SFProNormalWoff from 'assets/fonts/SFProDisplay-Regular.woff';

import SFProNormalWoff2 from 'assets/fonts/SFProDisplay-Regular.woff2';
import SFProSemiBoldWoff from 'assets/fonts/SFProDisplay-Semibold.woff';
import SFProSemiBoldWoff2 from 'assets/fonts/SFProDisplay-Semibold.woff2';
import SFProThinWoff from 'assets/fonts/SFProDisplay-Thin.woff';
import SFProThinWoff2 from 'assets/fonts/SFProDisplay-Thin.woff2';
import SFProUltraLightWoff from 'assets/fonts/SFProDisplay-Ultralight.woff';
import SFProUltraLightWoff2 from 'assets/fonts/SFProDisplay-Ultralight.woff2';
import { css } from 'styled-components/macro';

export const SfFonts = css`
  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProNormalWoff2}) format('woff2'),
      url(${SFProNormalWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  } // Todo: РАЗОБРАТЬСЯ С ДЕРГАНЬЕМ!(В ПРОДЕ НЕ ДЕРГАЕТ, ВЕРОЯТНО, ВИНОВАТ ВЕБПАК)

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProBoldWoff2}) format('woff2'),
      url(${SFProBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProMediumWoff2}) format('woff2'),
      url(${SFProMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProLightWoff2}) format('woff2'),
      url(${SFProLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProUltraLightWoff2}) format('woff2'),
      url(${SFProUltraLightWoff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProThinWoff2}) format('woff2'),
      url(${SFProThinWoff}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProSemiBoldWoff2}) format('woff2'),
      url(${SFProSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro normal';
    src: url(${SFProBlackWoff2}) format('woff2'),
      url(${SFProBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
  }
`;
