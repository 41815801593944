import { API, APIGateway } from './api';

export const GetAccrualsInfo = (params: any) =>
  API.get('/cabinet/accruals/', params);

export const GetNotices = (params: any) => API.get(`/cabinet/notices/`, params);

export const GetBillingInfo = (params: any) =>
  API.get(`/acquiring/tenant_accrual_info/`, params);

export const GetPaySettings = () => API.get(`/acquiring/pay_settings/`);

export interface ITransaction {
  accrual?: string;
  sector: string;
  auto_pay: boolean;
  save_card: boolean;
  value: string;
}

export interface IGPBTransaction {
  amount: number;
  user_id: string;
  save_card?: boolean;
  provider_bank_number: string;
  period: string | null;
  card_id?: string;
  description: string;
  autopay: boolean;
  sector: string;
}

export interface IGPBRequestTransaction {
  amount: number;
  user_id: string;
  save_card?: boolean;
  provider_bank_number: string;
  request_number: number | null;
  card_id?: string;
  description: string;
}

export interface INotConfirmedTransactions {
  user_id: string;
  date_from: string;
  date_till: string;
}

export const SendTransaction = (data: ITransaction) =>
  API.post('/acquiring/transaction/', data);

export interface IFastTransaction {
  sector: string;
  card: string;
  amount: number;
  month: string;
}
export const SendFastTransaction = (data: IFastTransaction) =>
  API.post('/acquiring/fast_transaction/', data);

export const RemoveCard = (cardId: string) =>
  API.delete(`/acquiring/pay_settings/`, cardId);

export const GetPaymentInfoByNumber = (params: {
  number: string;
  month?: string;
  auto_pay?: boolean;
}) => API.get('/public/pay_by_number/', params);

export const GetReceipt = ({
  accrual,
  sector_code,
}: {
  accrual: string;
  sector_code: string;
}) => API.post(`/cabinet/receipt/`, { accrual, sector_code });

export const CheckReceiptStatus = ({
  task_id,
  sector_code,
}: {
  task_id: string;
  sector_code: string;
}) =>
  API.get('/cabinet/receipt/', {
    task_id,
    sector_code,
  });

//TODO: ВРЕМЕННО! НУЖНО ЧТОБЫ СЕРВЕР САМ С ЭТИМ ВСЕМ РАЗБИРАЛСЯ
const GPBAcquiring = '/gpb/acquiring/';

export const UpdateAutoPay = (
  actor_id: string,
  user_id: string,
  card_id: string,
  data: any
) =>
  APIGateway.patch(
    GPBAcquiring + `users/${user_id}/cards/${card_id}`,
    actor_id,
    data
  );

export const SendNewTransaction = (actor_id: string, data: IGPBTransaction) =>
  APIGateway.post(GPBAcquiring + 'orders/pay', actor_id, data);

export const SendNewRequestTransaction = (
  actor_id: string,
  data: IGPBRequestTransaction
) => APIGateway.post(GPBAcquiring + 'orders/pay_request', actor_id, data);

export const RegisterCard = (actor_id: string, data: any) =>
  APIGateway.post(GPBAcquiring + 'orders/register_card', actor_id, data);

export const GetGPBFee = (actor_id: string, data: { amount: string }) =>
  APIGateway.get(GPBAcquiring + 'fees/calculate', actor_id, data);

export const RemoveGPBCard = (
  actor_id: string,
  user_id: string,
  card_id: string
) =>
  APIGateway.delete(
    GPBAcquiring + `users/${user_id}/cards/${card_id}`,
    actor_id
  );

export const GetNotConfirmedTransactions = (
  actor_id: string,
  data: INotConfirmedTransactions
) =>
  APIGateway.get(GPBAcquiring + 'transactions/get_not_confirm', actor_id, data);

//TODO: отправка логов
export const SendPaymentRequestLog = (actor_id: string, data: any) =>
  APIGateway.post(GPBAcquiring + 'add_log', actor_id, data);
