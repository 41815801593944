import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CheckReceiptStatus,
  GetAccrualsInfo,
  GetBillingInfo,
  GetGPBFee,
  GetNotConfirmedTransactions,
  GetPaySettings,
  GetReceipt,
  IFastTransaction,
  IGPBTransaction,
  ITransaction,
  RemoveCard,
  SendFastTransaction,
  SendNewTransaction,
  SendTransaction,
  UpdateAutoPay,
  SendPaymentRequestLog,
  RemoveGPBCard,
  RegisterCard,
  IGPBRequestTransaction,
  SendNewRequestTransaction,
} from 'app/api/accruals';
import { getUserInfo } from 'app/pages/auth/auth-slice';
import { showWarning } from 'app/store/root-slice';
import { RootState } from 'app/store/store';

import { formatDateToISO } from 'app/utils/getters';
import moment from 'moment';
import { IBalance } from '../profile/types';
import {
  IAccrual,
  IAccrualsInfo,
  IBillingInfo,
  ICard,
  INotConfirmedTransaction,
} from './types';

export interface InitialState {
  onLoad: boolean;
  hasDebts: boolean;
  showOnlyDebts: boolean; // Переключатель "только долги"
  accrualsInfo: IAccrualsInfo;
  notConfirmedTransactions: INotConfirmedTransaction[];
  accrualToPay: {
    billing: IBillingInfo;
    accrual: IAccrual;
  };
  lastPayMoment: moment.Moment;
  paySettings: ICard[];
  sectors: string[];
  filters: {
    sectors: string[];
    month_from: Date;
    month_till: Date;
  };
}

export const initialState: InitialState = {
  onLoad: false,
  hasDebts: false,
  showOnlyDebts: false,
  accrualsInfo: {
    accruals: [],
    debts: [],
    balance: {} as IBalance,
  },
  notConfirmedTransactions: [],
  accrualToPay: {
    billing: {} as IBillingInfo,
    accrual: {} as IAccrual,
  },
  lastPayMoment: {} as moment.Moment,
  paySettings: [],
  sectors: [],
  filters: {
    sectors: [],
    // month_from: formatDateToISO(moment().startOf('M')),
    // month_till: formatDateToISO(moment().endOf('M')),
    month_from: moment().subtract(1, 'year').startOf('M').toDate(),
    month_till: moment().endOf('M').toDate(),
  },
};

export const getAccrualsInfo = createAsyncThunk(
  'accruals/getAccrualsInfo',
  async (_, { rejectWithValue, getState }) => {
    const {
      accruals: { filters },
    } = getState() as RootState;
    try {
      return await GetAccrualsInfo({
        ...filters,
        month_from: formatDateToISO(filters.month_from),
        month_till: formatDateToISO(moment(filters.month_till).endOf('M')),
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getBillingInfo = createAsyncThunk<
  IBillingInfo,
  { accrual?: string; sector_code: string },
  any
>('accruals/getBillingInfo', async (params, { rejectWithValue }) => {
  try {
    return await GetBillingInfo(params);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getPaySettings = createAsyncThunk<ICard[]>(
  'accruals/getPaySettings',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await GetPaySettings();
      return response.results || [];
    } catch (e: any) {
      if (e.response?.status === 400) {
        try {
          const fallbackResponse = await GetPaySettings();
          return fallbackResponse.results || [];
        } catch (fallbackError) {
          dispatch(showWarning());
          return rejectWithValue(e);
        }
      }
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getGPBFee = createAsyncThunk(
  'accruals/getGPBFee',
  async (amount: string, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    try {
      return await GetGPBFee(auth.user._id, {
        amount: amount,
      });
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const registerCard = createAsyncThunk<any, string, any>(
  'accruals/registerCard',
  async (sector, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    try {
      return await RegisterCard(auth.user._id, {
        user_id: auth.user.owner._id,
        sector: sector,
      });
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue;
    }
  }
);

export const sendTransaction = createAsyncThunk<
  any,
  ITransaction | IGPBTransaction | IGPBRequestTransaction,
  any
>(
  'accruals/sendTransaction',
  async (data, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    try {
      if ('request_number' in data) {
        return await SendNewRequestTransaction(auth.user._id, data);
      } else if ('user_id' in data) {
        return await SendNewTransaction(auth.user._id, data);
      } else {
        return await SendTransaction(data);
      }
    } catch (e: any) {
      if (e.response.status === 425) {
        return {
          status: 'time_error',
        };
      }

      if (e.response.status === 423) {
        let data = await e.response.json();
        return {
          status: 'pay_error',
          msg: data.detail,
        };
      }

      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const sendPaymentRequestLog = createAsyncThunk<any, any, any>(
  'accruals/sendPaymentRequestLog',
  // TODO Временно закомментировано по просьбе Дмитрия Олеговича
  // async (data, { rejectWithValue, dispatch, getState }) => {
  //   const { auth } = getState() as RootState;
  //   try {
  //     return await SendPaymentRequestLog(auth.user._id, data);
  //   } catch (e) {
  //     dispatch(showWarning());
  //     return rejectWithValue(e);
  //   }
  // }
  () => {
    return;
  }
);

export const removeCard = createAsyncThunk<any, string, any>(
  'accruals/removeCard',
  async (cardId, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await RemoveCard(cardId);

      dispatch(getPaySettings());

      return response;
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const removeGPBCard = createAsyncThunk<any, string, any>(
  'accruals/removeGPBCard',
  async (cardId, { rejectWithValue, dispatch, getState }) => {
    try {
      const { auth } = getState() as RootState;
      const response = await RemoveGPBCard(
        auth.user._id,
        auth.user.owner._id,
        cardId
      );

      dispatch(getPaySettings());

      return response;
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const sendFastTransaction = createAsyncThunk<any, IFastTransaction, any>(
  'accruals/sendTransaction',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await SendFastTransaction(data);
    } catch (e: any) {
      if (e.response.status === 500) {
        dispatch(showWarning());
      }

      return rejectWithValue(e);
    }
  }
);

export const updateAutoPay = createAsyncThunk<
  any,
  {
    cardId: string;
    value: boolean;
    sector?: string;
    method: 'add' | 'change' | 'delete';
  },
  any
>(
  'accruals/updateAutoPay',
  async (data, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    try {
      return await UpdateAutoPay(
        auth.user._id,
        auth.user.owner._id,
        data.cardId,
        {
          activate_autopay_sector:
            (['add', 'change'].includes(data.method) && data.sector) || null,
          deactivate_autopay_sector:
            (['change', 'delete'].includes(data.method) && data.sector) || null,
        }
      );
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getNotConfirmedTransactions = createAsyncThunk(
  'accruals/getNotConfirmedTransactions',
  async (data, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    const {
      payments: { filters },
    } = <RootState>getState();
    try {
      return await GetNotConfirmedTransactions(auth.user._id, {
        user_id: auth.user.owner._id,
        date_from: moment(filters.date_from).toISOString(true),
        date_till: moment(filters.date_till).endOf('M').toISOString(true),
      });
    } catch (e: any) {
      if (e.response.status === 500) {
        dispatch(showWarning());
      }

      return rejectWithValue(e);
    }
  }
);

export const printAccrualReceipt = createAsyncThunk<any, any, any>(
  'accruals/printAccrualReceipt',
  async (params, { rejectWithValue }) => {
    try {
      const { sector_code, task_id } = await GetReceipt(params);
      const [url, error]: [string, any] = await new Promise(
        (resolve, reject) => {
          async function checkStatus() {
            const { status, url } = await CheckReceiptStatus({
              task_id,
              sector_code,
            });
            // Todo: все же вынести наверно в контроллер циклические запросы, что бы комфортней блокировать кнопку
            switch (status) {
              case 'wip':
                setTimeout(function () {
                  checkStatus();
                }, 3000);
                break;
              case 'failed':
                reject([null, new Error(params.accrual)]);
                break;
              case 'success':
                resolve([url, null]);
                return;
              default:
                break;
            }
          }

          checkStatus();
        }
      );
      if (error) {
        return rejectWithValue(params.accrual);
      } else {
        return {
          accrual: params.accrual,
          url,
        };
      }
    } catch (e) {
      return rejectWithValue(params.accrual);
    }
  }
);

export const accrualsSlice = createSlice({
  name: 'accruals',
  initialState,
  reducers: {
    setDateRange: (
      state,
      action: PayloadAction<{ month_from: Date; month_till: Date }>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setAccrualToPay: (state, action: PayloadAction<IAccrual>) => {
      state.accrualToPay.accrual = action.payload;
    },
    setLastPayMoment: (state, action: PayloadAction<moment.Moment>) => {
      state.lastPayMoment = action.payload;
    },
    setEmptyBillingValue: (state) => {
      state.accrualToPay.billing.params.amount = '';
    },
    setOnlyDebts: (state, action: PayloadAction<boolean>) => {
      state.showOnlyDebts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.sectors = action.payload.sectors;
      state.filters.sectors = action.payload.sectors;
    });

    builder.addCase(getAccrualsInfo.pending, (state) => {
      state.onLoad = true;
    });

    builder.addCase(getAccrualsInfo.rejected, (state) => {
      state.onLoad = false;
    });

    builder.addCase(
      getAccrualsInfo.fulfilled,
      (state, action: PayloadAction<IAccrualsInfo>) => {
        state.accrualsInfo = action.payload;
        state.accrualsInfo.accruals.forEach((accrual) => {
          if (['debt', 'partially'].includes(accrual.status)) {
            state.hasDebts = true;
          }
        });
        state.onLoad = false;
      }
    );

    builder.addCase(getBillingInfo.fulfilled, (state, action) => {
      state.accrualToPay.billing = action.payload;
    });

    builder.addCase(
      getPaySettings.fulfilled,
      (state, action: PayloadAction<ICard[]>) => {
        state.paySettings = action.payload;
      }
    );

    builder.addCase(getNotConfirmedTransactions.pending, (state) => {
      state.onLoad = false;
    });

    builder.addCase(getNotConfirmedTransactions.rejected, (state) => {
      state.onLoad = false;
    });

    builder.addCase(
      getNotConfirmedTransactions.fulfilled,
      (state, action: PayloadAction<INotConfirmedTransaction[]>) => {
        state.notConfirmedTransactions = action.payload;
      }
    );
  },
});

export const {
  setAccrualToPay,
  setLastPayMoment,
  setDateRange,
  setEmptyBillingValue,
  setOnlyDebts,
} = accrualsSlice.actions;

export default accrualsSlice.reducer;
