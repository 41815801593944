import React from 'react';
import { Routes } from './routes';
import { Helmet } from 'react-helmet';
import { Section } from './catalog.styled';

export const Catalog = () => {
  return (
    <>
      <Helmet>
        <title>Каталог</title>
      </Helmet>
      <Section>
        <Routes />
      </Section>
    </>
  );
};
