import React, { ChangeEventHandler, memo, useEffect, useRef, useState } from 'react';
import arrow from './assets/arrow.svg';
import check from './assets/check.svg';
import { StyledField } from './styles.styled';
import { IStepInputField } from './types';


interface IFieldProps {
  field: IStepInputField;
}

export const Field = memo(({ field }: IFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { name, type, placeHolder, isFilled, error, value } = field;
  const [complete, setComplete] = useState(false);
  const [types, setType] = useState<'text' | 'password'>('password');
  const eyeClass = `fa ${types === 'password' ? 'fa-eye' : 'fa-eye-slash'}`;
  const handleSwitchType = () =>
    setType(types === 'text' ? 'password' : 'text');

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (complete) {
      setComplete(false);
    }
  };

  useEffect(() => {
    if (isFilled && !error && !complete) {
      setComplete(true);
    }
  }, [isFilled, error]);

  return (
    <StyledField.Wrapper>
      <StyledField.Button disabled={complete}>
        {<img src={complete ? check : arrow} alt={'ok'} />}
      </StyledField.Button>
      {name === 'password' ? <StyledField.Eye onClick={handleSwitchType} type={'button'}>
        <span className={eyeClass} />
      </StyledField.Eye> : null}
      <StyledField.Input
        type={name === 'username' ? type : types}
        placeholder={placeHolder}
        defaultValue={value}
        onChange={handleChange}
        key={name}
        name={name}
        ref={inputRef}
      />
      <StyledField.Error>{field.error}</StyledField.Error>
    </StyledField.Wrapper>
  );
});
