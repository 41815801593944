/** Обработчики событий **/

/* взаимодействие с курсором при вводе номера телефона в ReactInputMask */
export const cursorFinder = (e: any) => {
  if (e.target.value === '+7 ___ ___ __ __') {
    e.target.selectionStart = 3;
    e.target.selectionEnd = 3;
  } else if (
    e.target.value.includes('_') &&
    (e.target.value.indexOf('_') < e.target.selectionStart ||
      e.target.selectionStart < 3)
  ) {
    let pos = e.target.value.indexOf('_');
    e.target.selectionStart = pos;
    e.target.selectionEnd = pos;
  } else if (!e.target.value.includes('_')) {
    e.target.selectionStart = 16;
    e.target.selectionEnd = 16;
  }
};
