import { Button } from 'app/components/index';
import { useAppDispatch } from 'app/hooks';
import { getBenefitState } from 'app/store/root-selectors';
import { hideBenefit } from 'app/store/root-slice';
import React from 'react';
import { useSelector } from 'react-redux';
import { Image, SmallText, Title, TopSection, Wrapper } from './benefit.styled';

export const Benefit = React.memo(() => {
  const dispatch = useAppDispatch();
  const { show } = useSelector(getBenefitState);

  const federalLawUrl =
    'https://eis24.me/wp-content/uploads/2024/06/%E2%84%96602-fz-ot-19.12.2023.pdf';

  if (!show) {
    return <></>;
  }

  const handleBack = () => {
    dispatch(hideBenefit());
  };

  return (
    <Wrapper>
      <TopSection>
        <Image />
        <Title>Оплата недоступна</Title>
        <SmallText>
          К сожалению, мы не можем в режиме онлайн произвести проверку
          необходимых подтверждающих документов,
          <br /> дающих право на льготу по уплате комиссии при перечислении
          платы за жилищно-коммунальные услуги.
          <br />
          Для получения льготы, предусмотренной{' '}
          <a href={federalLawUrl}>федеральным законом от 19.12.2023 №602-ФЗ</a>,
          <br />
          пожалуйста, обратитесь в отделения банков. Приносим извинения за
          доставленные неудобства.
        </SmallText>
      </TopSection>
      <Button onClick={handleBack} variant={'lightblue'} size={'lg'}>
        Вернуться
      </Button>
    </Wrapper>
  );
});
