/* Для стилизации выбранного элемента используйте класс button-tabs__selected
 * По умолчанию он зеленый
 *  */

import React, { memo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Item, Nav } from './controlled-button-tabs.styled';

type Props = {
  items: any[];
  selectedItem: any;
  onChange: (item: any) => void;
  className?: string;
  variant?: 'category' | 'filter';
};

export const ControlledButtonTabs = memo(
  ({
    items,
    selectedItem,
    onChange,
    variant = 'category',
    className,
  }: Props) => (
    <ScrollContainer>
      <Nav className={className}>
        {items.map((item) => (
          <Item
            variant={variant}
            type={'button'}
            onClick={() => onChange(item)}
            key={item.id}
            className={
              selectedItem.id === item.id ? 'button-tabs__selected' : ''
            }
            selected={selectedItem.id === item.id}
          >
            {item.title}
          </Item>
        ))}
      </Nav>
    </ScrollContainer>
  )
);
