import styled from 'styled-components/macro';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9de2ff;
  font-size: 28px;
  z-index: 10;

  span {
    color: #ffffff;
    z-index: 11;
  }
`;
