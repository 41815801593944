import { toFractionRubles } from '../../../utils/getters';
import { IPreviewCompany } from '../types';

export const getPriceWithSale = (
  value: number,
  sale: number,
  sale_type: string
) => {
  if (sale_type === 'percent') {
    return value - (value * sale) / 100;
  } else if (sale_type === 'fixed') {
    return value - sale;
  } else {
    return value;
  }
};

export const getCurrentPrice = (priceObj: {
  sale: number;
  sale_type: string;
  value: number;
  price_type: string;
}) => {
  const { sale, sale_type, value, price_type } = priceObj;
  const withSale = getPriceWithSale(value, sale, sale_type);
  if (price_type === 'price_start_from') {
    return `от ${toFractionRubles(withSale)}`;
  } else {
    return toFractionRubles(withSale);
  }
};

export const hideCompany = (company: IPreviewCompany) => {
  return (
    !company.str_name?.length &&
    !company.address?.length &&
    !company.phone_number?.length &&
    !company.work_schedule?.monday?.work_start_at &&
    !company.work_schedule?.monday?.work_finish_at &&
    !company.work_schedule?.sunday?.work_start_at &&
    !company.work_schedule?.sunday?.work_finish_at
  );
};
