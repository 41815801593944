import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import {
  showServiceItemFullCard,
  setCurrentService,
  postShowPhoneForStatistic,
} from '../../../../catalog-slice';
import { replaceTextAreaNewLine } from 'app/utils/getters';
import {
  ServicePrice,
  // ServiceVideo,
} from '../../components';
import {
  SliderImg,
  ServiceTitle,
  ServiceCardBlock,
  ServiceDescription,
  // VideoBlock,
  EmptyImg,
  StyledAliceCarousel,
  ContactPhoneBlock,
  ContactPhoneBtn,
  ToCreateBtn,
  ContactButtonsBlock,
  ServiceTitleBlock,
  ServiceCompanyExtraInfoBlock,
} from './service-card-mobile.styled';
import { ServiceCompanyExtraInfo } from '../../list.styled';
import { IService } from '../../../../types';

interface Props {
  service: IService;
}

const setPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length === 11 && phoneNumber[0] === '7') {
    return `8${phoneNumber.slice(1, 11)}`;
  }
  return phoneNumber;
};

export const ServiceCardMobile = ({ service }: Props) => {
  const dispatch = useAppDispatch();
  const [changeSlide, setChangeSlide] = useState<boolean>(false);
  const [serviceMediaWithCover, setServiceMediaWithCover] = useState<IService[]>([]);
  const [serviceExecutorPhone, setServiceExecutorPhone] = useState<string>('');

  const handleShowService = (service: IService) => {
    dispatch(showServiceItemFullCard(true));
    dispatch(setCurrentService(service));
  };

  useEffect(() => {
    setServiceExecutorPhone(setPhoneNumber(service.executor?.phone_number || ''));
  }, []);

  useEffect(() => {
    /* Добавление image_cover (при наличии) к media первым слайдом
     * а также явная пометка image_cover для определения ссылки в src
     */
    if (service.image_cover?.content_type.includes('image')) {
      const imageCover = { ...service.image_cover, isCover: true };
      // возможность добавления видео через интерфейс в lk временно убрана,
      // в старых услугах могут присутствовать видео в media
      const onlyImg = service.media.filter((file: any) => file.content_type.includes('image'));
      setServiceMediaWithCover([imageCover, ...onlyImg]);
    } else {
      setServiceMediaWithCover(service.media);
    }
  }, []);

  return (
    <ServiceCardBlock onClick={() => handleShowService(service)}>
      {!!serviceMediaWithCover.length ? (
        <StyledAliceCarousel
          disableButtonsControls
          disableDotsControls
          mouseTracking
          onSlideChanged={() => {
            setChangeSlide((changeSlide: boolean) => !changeSlide);
          }}
          autoPlayStrategy={'default'}
          controlsStrategy={'responsive'}
          // 1.05 для небольшого предпросмотра следующего элемента карусели в текущем stage.
          // Необходимо учесть, что пагинация через DotsControls и ButtonsControls (если она нужна) будет нарушена
          responsive={{ 0: { items: 1.05 } }}
        >
          {
            serviceMediaWithCover.map((file: any, index: number) => {
              // if (file.content_type.includes('image')) {
                return (
                  <SliderImg
                    key={index}
                    img={`/api/v4/compendium/file/${service.id}/?${
                      file.isCover ? 'image_cover' : 'media'
                    }=${file.file}`}
                  />
                );
              // }
              // if (file.content_type.includes('video')) {
              //   return (
              //     <VideoBlock key={index}>
              //       <ServiceVideo
              //         service={service}
              //         file={file}
              //         changeSlide={changeSlide}
              //       />
              //     </VideoBlock>
              //   );
              // }
            })
            // ]
          }
        </StyledAliceCarousel>
      ) : (
        <EmptyImg />
      )}
      <ServiceTitleBlock>
        <ServiceTitle>{service.title}</ServiceTitle>
        {/*<Star*/}
        {/*  onClick={handleRate}*/}
        {/*  className={*/}
        {/*    currentService.favorite ? 'fas fa-star' : 'far fa-star'*/}
        {/*  }*/}
        {/*/>*/}
      </ServiceTitleBlock>
      <ServicePrice service={service} />
      {!!service.executor && (
        <ServiceCompanyExtraInfoBlock>
          {service.executor.works_under_contract && (
            <ServiceCompanyExtraInfo>
              Работает по договору
            </ServiceCompanyExtraInfo>
          )}
          {service.executor.requisites_confirmed && (
            <ServiceCompanyExtraInfo>
              Реквизиты проверены
            </ServiceCompanyExtraInfo>
          )}
        </ServiceCompanyExtraInfoBlock>
      )}

      <ServiceDescription
        dangerouslySetInnerHTML={{
          __html: replaceTextAreaNewLine(service.description),
        }}
      />
      <ContactButtonsBlock>
        {!!service.executor?.phone_number && (
          <ContactPhoneBlock>
            <ContactPhoneBtn
              onClick={(e: any) => {
                e.stopPropagation();
                dispatch(postShowPhoneForStatistic({ serviceId: service.id }));
              }}
              href={'tel:' + serviceExecutorPhone}
            >
              Позвонить
            </ContactPhoneBtn>
          </ContactPhoneBlock>
        )}
        <ToCreateBtn>Подробнее</ToCreateBtn>
      </ContactButtonsBlock>
    </ServiceCardBlock>
  );
};
