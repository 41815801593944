import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const StyledLink = styled(Link)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.lightBlueLink};
  float: right;
  margin-top: 2px;
  text-decoration: none;
`;

export const Field = styled.div`
  position: relative;
`;

export const Note = styled.span`
  float: right;
  font-size: 12px;
  margin-top: 2px;
  color: #939393;
`;

export const Input = styled.input<{ error: boolean }>`
  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.orange : 'transparent')};
  outline: none;
  padding: 11px 12px;
  font-size: 14px;
  border-radius: 9px;
  background-color: ${(props) =>
    props.error ? props.theme.colors.white : '#eaf3f9'};
  height: 36px;
  width: 100%;
  margin-top: 2px;

  &:active,
  &:focus {
    outline: none;
  }
`;

export const Area = styled.textarea<{ error: boolean }>`
  font-family: SF Pro normal, serif;
  border: 1px solid
    ${(props) => (props.error ? props.theme.colors.orange : 'transparent')};
  outline: none;
  padding: 11px 12px;
  font-size: 11px;
  border-radius: 9px;
  background-color: ${(props) =>
    props.error ? props.theme.colors.white : '#eaf3f9'};
  width: 100%;
  margin-top: 2px;
  height: 110px;
  resize: none;

  &:active,
  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.div<{ mt?: number }>`
  margin-top: ${(props) => props.mt}px;
  height: 18px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.orange};
`;

export const Label = styled.label`
  font-size: 13px;
`;

export const MaxLengthIndicator = styled.span`
  position: absolute;
  bottom: 0;
  right: 10px;
  color: #cfd1d2;
`;
