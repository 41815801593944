import React, { memo, useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart, ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IMeter } from 'app/pages/meters/types';
import { IConstants } from '../../../../types';
import { getStrokeColor, prepareChartData } from '../../utils';
import { TooltipBlack } from '../tooltip-black/tooltip-black';
import { EmptyText, EmptyContainer } from '../../meters-chart.styled';

interface Props {
  meters: IMeter[];
  constants: IConstants;
}

export const MetersLineChart = memo(({ meters, constants }: Props) => {
  const data = prepareChartData(meters, constants);
  const toRender: JSX.Element[] = useMemo(() => prepareChartLines(meters), [
    meters,
  ]);
  if (!data.length) {
    return (
        <EmptyContainer>
          <EmptyText>Здесь будет отражен Ваш расход по счетчикам</EmptyText>
        </EmptyContainer>);
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={100}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: -26,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis strokeWidth={1} dataKey="name" />
        <YAxis strokeWidth={1} />
        <Tooltip
          content={<TooltipBlack constants={constants} meters={meters} />}
        />
        {/*<Legend content={renderLegend} />*/}
        {/*<Line*/}
        {/*  type="monotone"*/}
        {/*  dataKey="pv"*/}
        {/*  stroke="#8884d8"*/}
        {/*  activeDot={{ r: 8 }}*/}
        {/*/>*/}
        {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
        {/*<Line type="monotone" dataKey={'name'} stroke="#82ca9d" />*/}
        {toRender}
      </LineChart>
    </ResponsiveContainer>
  );
});

const prepareChartLines = (meters: IMeter[]) => {
  const toRender: JSX.Element[] = [];

  meters.forEach((meter) => {
    if (meter.readings.length) {
      meter.readings[0].deltas.forEach((delta, index) => {
        toRender.push(
          <Line
            dot={false}
            key={meter.id + index}
            type={'monotone'}
            dataKey={meter.id + index}
            strokeWidth={2}
            stroke={getStrokeColor(meter.type, index) || '#000000'}
          />
        );
      });
    }
  });

  return toRender;
};
