import { ICard } from 'app/pages/accruals/types';
import React from 'react';
import mastercard from './assets/mastercard.svg';
import mir from './assets/mir.svg';
import visa from './assets/visa.svg';

import { Card, CardText, Chip, Number, Types } from './bank-card.styled';

interface Props {
  card: ICard;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
}

export const BankCard = React.memo(
  ({ card, selected = false, onClick, className }: Props) => (
    <Card
      onClick={onClick}
      selected={selected}
      className={className}
      data-testid={'bank-card'}
    >
      <Types>
        <img src={visa} alt={'visa'} />
        <img src={mastercard} alt={'mastercard'} />
        <img src={mir} alt={'mir'} />
      </Types>
      <Chip />
      <Number>{card.card}</Number>
      <CardText>БАНКОВСКАЯ КАРТА</CardText>
    </Card>
  )
);
