import React from 'react';
import usePortal from 'react-useportal';

import { Body, ButtonGroup, Fade, Title, Wrapper } from './old-confirm.styled';

interface Props {
  title?: string;
  body: string;
  type?: 'confirm' | 'alert';
  onConfirm: () => void;
  onDismiss?: () => void;
  confirmText?: string;
  dismissText?: string;
  className?: string;
}

export const OldConfirm = React.memo(
  ({
    title = 'Подтверждение',
    body,
    type = 'confirm',
    confirmText = 'Да',
    dismissText = 'Нет',
    onConfirm,
    onDismiss,
    className,
  }: Props) => {
    const { Portal } = usePortal();

    return (
      <Portal>
        <Fade />
        <Wrapper className={className}>
          <Title>{title}</Title>
          <Body>{body}</Body>
          <ButtonGroup $isAlert={type === 'alert'}>
            {type === 'confirm' && (
              <button onClick={onDismiss} type={'button'}>
                {dismissText}
              </button>
            )}
            <button onClick={onConfirm} type={'button'}>
              {confirmText}
            </button>
          </ButtonGroup>
        </Wrapper>
      </Portal>
    );
  }
);
