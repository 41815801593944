import {
  Man,
  JkhMan,
  Monitor,
  Note,
  Puddle,
  RequestBrick,
  TicketsBrick,
  WorkBrick,
  Title,
} from 'app/components/appeals-widgets/appeals-widgets.styled';
import { useRights } from 'app/hooks';
import { authorizedRoutes } from 'app/routesConfig';
import React from 'react';

interface Props {
  className?: string;
}

export const RequestsWidget = ({ className }: Props) => {
  const { onClickHandler, Protect, read } = useRights(
    authorizedRoutes.requests.slug,
    'В настоящее время Ваша управляющая организация не принимает заявки в электронном виде'
  );
  const templateRequestSlug = useRights(
    authorizedRoutes.requests.templateRequest.slug
  );
  return (
    <Protect>
      <RequestBrick
        $available={read}
        onClick={onClickHandler}
        className={className}
        to={templateRequestSlug.read ? authorizedRoutes.requests.templateRequest.link : authorizedRoutes.requests.create.link}
      >
        <Title>Подать заявку</Title>
        <Note>Просьба выполнить какой-либо ремонт</Note>
        <Monitor />
      </RequestBrick>
    </Protect>
  );
};

export const TicketsWidget = ({ className }: Props) => {
  const { onClickHandler, Protect, read } = useRights(
    authorizedRoutes.tickets.slug,
    'В настоящее время Ваша управляющая организация не принимает обращения в электронном виде'
  );

  return (
    <Protect>
      <TicketsBrick
        $available={read}
        onClick={onClickHandler}
        className={className}
        to={authorizedRoutes.tickets.create.link}
      >
        <Title>Подать обращение</Title>
        <Note>Способ коммуникации с УК</Note>
        <Puddle />
        <Man />
      </TicketsBrick>
    </Protect>
  );
};

export const WorkWidget = ({ className }: Props) => {
  return (
    <WorkBrick
      href={'https://roscluster.ru/rabotahouse/?externalLink=true'}
      target={'_blank'}
    >
      <Title>Работа в ЖКХ</Title>
      <Note>Поиск работы в сфере жилищно-коммунального хозяйства</Note>
      <JkhMan />
    </WorkBrick>
  );
};
