import Scrollbars from 'react-custom-scrollbars';
import styled, { css } from 'styled-components/macro';
import { Media, MFMedia } from '../../styles/media-breakpoints';

export const StyledScrollbars = styled(Scrollbars)`
  & > div:nth-child(2) {
    // Скрывает горизонтальный скроллбар
    display: none;
  }
  @media (${MFMedia.smallPC}) {
    & > div:nth-child(2) {
      // Скрывает горизонтальный скроллбар
      display: unset;
    }
  }
`;

export const Item = styled.button<{
  selected: boolean;
  variant: 'category' | 'filter';
}>`
  position: relative;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? props.theme.colors.darkBlue : '#7d7d7d'};
  padding: 0 15px;

  &:before {
    transition: background-color 0.2s ease;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 4px;
    background-color: ${(props) =>
      props.selected ? props.theme.colors.darkBlue : '#e7e7e7'};
    border-radius: 3px;
  }

  &:first-child {
    padding-left: 0;

    &:before {
      border-bottom-left-radius: unset;
      border-top-left-radius: unset;
    }
  }

  &:last-child {
    padding-right: 0;

    &:before {
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
    }
  }

  @media (${MFMedia.smallPC}) {
    padding: 7px 15px;
  }

  ${(props) =>
    props.variant === 'filter' &&
    css`
      @media (${MFMedia.smallPC}) {
        background-color: ${props.selected ? '#9DD95C' : '#ffffff'};
        color: ${props.selected ? '#ffffff' : '#334d6e'};
        margin-right: 15px;
        border-radius: 5px;
        box-shadow: 5px 7px 12px rgba(112, 112, 112, 0.15);

        &,
        &:last-child,
        &:first-child {
          padding: 10px 24px;
        }

        &:before {
          display: none;
        }
      }
    `};

  @media (${Media.tablet}) {
    border: 1px solid #c4c4c4;
    border-radius: 37px;
    padding: 6px 10px;
    margin: 0 5px;
    color: ${(props) =>
      props.selected ? props.theme.colors.white : '#7d7d7d'};
    background-color: ${(props) =>
      props.selected ? '#3EAEDE' : 'transparent'};

    &:before {
      display: none;
    }

    &:first-child {
      padding-left: 10px;
      margin-left: 0;
    }

    &:last-child {
      padding-right: 10px;
      margin-right: 0;
    }
  }

  @media (${Media.mobile}) {
    //#9DD95C
    background-color: ${(props) => (props.selected ? '#9DD95C' : '#f9f9f9')};
    border: ${(props) =>
      props.selected ? 'transparent' : '1px solid #c4c4c4'};
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-flow: row nowrap;
`;
