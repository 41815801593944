import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTicket,
  GetTicketsList,
  UpdateTicketFiles,
} from 'app/api/tickets';
import { showWarning } from 'app/store/root-slice';

import { RootState } from 'app/store/store';
import { IBlankTicket, ITicket } from './types';
import { IPhone } from 'app/types';
import { getTenantPhones } from '../profile/profile-slice';

export interface InitialState {
  statuses: {
    loaded: boolean;
    onLoad: boolean;
  };
  tickets: {
    selected: ITicket;
    list: ITicket[];
    count: number;
  };
  filters: {
    limit: number;
    offset: number;
  };
  blankTicket: IBlankTicket;
  commonPhones: IPhone[];
}

export const initialState: InitialState = {
  statuses: {
    loaded: false,
    onLoad: false,
  },
  tickets: {
    selected: {} as ITicket,
    list: [],
    count: 0,
  },
  filters: {
    limit: 10,
    offset: 0,
  },
  blankTicket: {
    subject: '',
    body: '',
    phones: [],
    photos: [],
  },
  commonPhones: [],
};

export const getTicketsList = createAsyncThunk<any>(
  'tickets/getTicketsList',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      const data = await GetTicketsList(state.tickets.filters);
      return {
        list: data.results || [],
        count: data.count,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createTicket = createAsyncThunk<any, IBlankTicket, any>(
  'tickets/createTicket',
  async (ticket, { rejectWithValue, dispatch, getState }) => {
    try {
      const result = await CreateTicket(ticket);
      return result;
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const addTicketFiles = createAsyncThunk<any, any, any>(
  'tickets/addTicketFiles',
  async (params, { rejectWithValue }) => {
    try {
      return await UpdateTicketFiles(params.id, params.files);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    clearErrors: (state) => {},
    selectTicket: (state, action: PayloadAction<ITicket>) => {
      state.tickets.selected = action.payload;
    },
    resetStore: (state) => ({
      ...initialState,
      blankTicket: {
        ...initialState.blankTicket,
        phones: state.commonPhones,
        photos: state.blankTicket.photos,
      },
      commonPhones: state.commonPhones,
    }),
    addPhone: (state, action) => {
      state.blankTicket.phones.push(action.payload);
    },
    removePhone: (state, action) => {
      state.blankTicket.phones.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    // Получение списка тикетов
    builder.addCase(getTicketsList.pending, (state, action) => {
      state.statuses.loaded = true;
      state.statuses.onLoad = true;
    });

    builder.addCase(
      getTicketsList.fulfilled,
      (
        state,
        action: PayloadAction<{
          list: ITicket[];
          count: number;
        }>
      ) => {
        const { list, count } = action.payload;
        state.tickets.list = [...state.tickets.list, ...list];
        state.tickets.count = count;
        state.filters.offset += 10;
        state.statuses.onLoad = false;
      }
    );

    builder.addCase(getTenantPhones.fulfilled, (state, { payload }) => {
      state.blankTicket.phones = payload;
      state.commonPhones = payload;
    });
  },
});

export const {
  selectTicket,
  resetStore,
  addPhone,
  removePhone,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
