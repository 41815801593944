import React from 'react';
import { LinkProps } from 'react-router-dom';
import { StyledButton, StyledLink } from './linked-button.styled';
import { BtnSize, BtnVariant } from './types';

type Props = {
  variant: BtnVariant;
  size: BtnSize;
  className?: string;
} & LinkProps;

export const LinkedButton = React.memo(
  ({ variant, size, className, ...props }: Props) => (
    <StyledButton variant={variant} size={size} className={className}>
      <StyledLink variant={variant} size={size} {...props}>
        {props.children}
      </StyledLink>
    </StyledButton>
  )
);
