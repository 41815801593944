import React from 'react';
import { Button } from '../components';
import { Wrapper, Header, Explanation } from './stages.styled';
import { useHistory } from 'react-router-dom';
import { unauthorizedRoutes } from '../../../routesConfig';
import { useSelector } from 'react-redux';
import { getQrPaymentSelector } from '../selectors';

/** ШАГ РЕДИРЕКТА НА РЕГИСТРАЦИЮ ИЛИ АВТОРИЗАЦИЮ В ЛКЖ **/
/** ../#/qr/payment/register/ **/
export const Stage5 = () => {
  const history = useHistory();
  const { tenant_addresses } = useSelector(getQrPaymentSelector);

  const handleRegister = () => {
    if (tenant_addresses.length) {
      history.push(unauthorizedRoutes.auth.login.link);
    } else {
      history.push(unauthorizedRoutes.auth.registration.link);
    }
    window.location.reload();
  };

  return (
    <Wrapper>
      <Header>
        {tenant_addresses.length
          ? 'Авторизуйтесь в личном кабинете'
          : 'Зарегистрируйтесь'}
      </Header>
      <Explanation>
        {tenant_addresses.length
          ? 'Для оплаты услуг ЖКХ по другому адресу Вам необходимо авторизоваться в личном кабинете и добавить необходимый Вам адрес.'
          : 'Для оплаты услуг ЖКХ Вам необходимо зарегистрироваться в личном кабинете'}
      </Explanation>
      <Button onClick={() => handleRegister()}>
        {tenant_addresses.length ? 'Авторизоваться' : 'Зарегистрироваться'}
      </Button>
    </Wrapper>
  );
};
