import { FC, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { IPhoneFileNotify } from '../components';
import { IPHONE_NOTIFY_POPUP } from '../constants/local-storage';
import { useAsyncPopup } from './use-async-popup';
import { useLocalStorage } from './use-local-storage';

export const useIphoneFilePopup = (): [FC, () => void] => {
  const [Popup, showPopup] = useAsyncPopup(IPhoneFileNotify);
  const [popup, setPopup] = useLocalStorage(IPHONE_NOTIFY_POPUP);

  useEffect(() => {
    if (!popup) {
      setPopup(IPHONE_NOTIFY_POPUP, 1);
    }
  }, []);

  const handleShowPopup = async () => {
    if (isMobile && isIOS && popup <= 3) {
      setPopup(IPHONE_NOTIFY_POPUP, popup + 1);
      return await showPopup();
    }
  };

  return [Popup, handleShowPopup];
};
