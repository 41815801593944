import styled from 'styled-components/macro';
import { Props } from './code-input';
import { number } from 'yup';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
`;

export const Box = styled.input.attrs({
  type: 'text',
  maxLength: 1,
  inputMode: 'tel',
})<Props>`
  font-size: 16px;
  padding: 17px;
  border-radius: 12px;
  color: #163b79;
  outline: none;
  max-width: 65px;
  text-align: center;
  background-color: ${(props) => (props.isError ? '#FFF3F3' : '#F5F6FA')};
  border: ${(props) => (props.isError ? '1px solid #EE1E1E' : '0')};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ErrorRow = styled.div`
  padding-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  color: #ee1e1e;
  font-weight: 400;
`;
