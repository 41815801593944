import React from 'react';
import { useSelector } from 'react-redux';
import { BlockOverlay } from '../components';
import { getStartRequestsState } from '../store/root-selectors';

export const useBlockOverlay = () => {
  const allLoaded = useSelector(getStartRequestsState);

  return {
    Overlay: () => <>{!allLoaded && <BlockOverlay />}</>,
  };
};
