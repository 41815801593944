import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useAppDispatch } from 'app/hooks';
import {
  clearCatalogServicesBySearch,
  setCurrentCategory,
} from '../../../../../catalog-slice';
import {
  CategoryBtnBox,
  CategoryBtn,
  CategoryCarouselBtnBox,
  ArrowRight,
} from './category-buttons-list.styled';

interface Props {
  categories: any;
  inCarousel?: boolean;
  forPC?: boolean;
}

export const CategoryButtonsList = ({
  categories,
  inCarousel,
  forPC,
}: Props) => {
  return (
    <>
      {!inCarousel ? (
        forPC ? (
          categories.map((category: any) => (
            <CategoryCarouselBtnBox key={category.value}>
              <CategoryBtnItem category={category} forPC />
            </CategoryCarouselBtnBox>
          ))
        ) : (
          categories.map((category: any) => (
            <CategoryBtnBox key={category.value}>
              <CategoryBtnItem category={category} />
              <ArrowRight />
            </CategoryBtnBox>
          ))
        )
      ) : (
        <AliceCarousel
          autoWidth
          disableButtonsControls
          disableDotsControls
          mouseTracking
          autoPlayStrategy={'default'}
          controlsStrategy={'responsive'}
        >
          {categories.map((category: any) => (
            <CategoryCarouselBtnBox key={category.value}>
              <CategoryBtnItem category={category} />
            </CategoryCarouselBtnBox>
          ))}
        </AliceCarousel>
      )}
    </>
  );
};

const CategoryBtnItem = ({
  category,
  forPC,
}: {
  category: any;
  forPC?: boolean;
}) => {
  const dispatch = useAppDispatch();
  return (
    <CategoryBtn
      onClick={() => {
        dispatch(clearCatalogServicesBySearch());
        dispatch(setCurrentCategory(category));
      }}
      category={forPC && category?.value}
    >
      {category.text}
    </CategoryBtn>
  );
};
