import moment from 'moment';
import { IButtonCollapseItem } from '../../components/button-collapse/button-collapse';
import { formatDateToISO } from '../../utils/getters';

export const meterTypesWithFloatReadings = ['HeatAreaMeter', 'HeatHouseMeter'];

const monthTill = formatDateToISO(moment().endOf('M'));

/* Элементы для кнопки выбора периода в истории показаний */
export const buttonItems: IButtonCollapseItem[] = [
  {
    text: 'Последний год',
    value: 'lastYear',
    extra: {
      month_from: formatDateToISO(moment().subtract(1, 'year').startOf('M')),
      month_till: monthTill,
    },
  },
  {
    text: 'Последние полгода',
    value: 'lastHalfYear',
    extra: {
      month_from: formatDateToISO(moment().subtract(6, 'months').startOf('M')),
      month_till: monthTill,
    },
  },
  {
    text: 'Последние два года',
    value: 'lastTwoYears',
    extra: {
      month_from: formatDateToISO(moment().subtract(2, 'years').startOf('M')),
      month_till: monthTill,
    },
  },
  {
    text: 'Последний месяц',
    value: 'lastMonth',
    extra: {
      month_from: formatDateToISO(moment().subtract(1, 'month').startOf('M')),
      month_till: monthTill,
    },
  },
];
