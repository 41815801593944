import styled from 'styled-components/macro';

export const Input = styled.input`
  position: absolute;
  z-index: -100;
  opacity: 0;
`;

export const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Switch = styled.label<{
  variant: 'green' | 'blue';
  disabled: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 21px;
  outline: none;

  ${Input}:checked + ${Slider} {
    background-color: ${(props) =>
      props.variant === 'green' ? '#27AE60' : '#2196f3'};
    opacity: ${(props) => (props.disabled ? '0.33' : '1')};
  }

  ${Input}:checked + ${Slider}:before {
    transform: translateX(15px);
  }
`;

export const Dot = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid #dcdcdc;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Round = styled.label<{ variant: 'green' | 'blue' }>`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  outline: none;

  ${Input}:checked + ${Dot} {
    background-color: ${(props) =>
      props.variant === 'green' ? '#27AE60' : '#2196f3'};
    border: 1px solid
      ${(props) => (props.variant === 'green' ? '#27AE60' : '#2196f3')};
  }
`;
