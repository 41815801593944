import { MFMedia } from 'app/styles/media-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    pointer-events: none;
    background: rgb(249, 249, 249);
    background: linear-gradient(
      90deg,
      rgba(249, 249, 249, 1) 0%,
      rgba(249, 249, 249, 0) 20%,
      rgba(249, 249, 249, 0) 80%,
      rgba(249, 249, 249, 1) 100%
    );
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    position: absolute;
    top: -7px;
    font-size: 22px;
    font-weight: 1000;
    width: 48px;
    z-index: 100;
    display: none;

    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
      color: #545454;

      &:hover {
        color: #49b3f8;
      }
    }

    @media (${MFMedia.smallPC}) {
      display: block;
    }
  }

  .alice-carousel__prev-btn {
    left: 0;
  }

  .alice-carousel__next-btn {
    right: 0;
  }

  .alice-carousel__stage-item {
    cursor: pointer;
    text-align: center;
    padding: 10px;
  }

  ul
    .alice-carousel__stage-item.__target
    + .alice-carousel__stage-item.__active {
    color: #49b3f8;
  }
`;
