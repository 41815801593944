import '@fortawesome/fontawesome-free/css/all.css';
import { App } from 'app';
import ru from 'date-fns/locale/ru';
import 'moment-timezone';
import 'moment/locale/ru';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'sanitize.css';
import store from './app/store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';

registerLocale('ru', ru);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
