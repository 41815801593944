import man from 'app/pages/main/assets/man.png';
import monitor from 'app/pages/main/assets/monitor.png';
import puddle from 'app/pages/main/assets/puddle.png';
import jkhman from 'app/pages/main/assets/jkh_man.png';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const BrickImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const Man = styled(BrickImage).attrs({
  src: man,
})``;

export const Puddle = styled(BrickImage).attrs({
  src: puddle,
})``;

export const Monitor = styled(BrickImage).attrs({
  src: monitor,
})``;

export const JkhMan = styled(BrickImage).attrs({
  src: jkhman,
})``;

const LinkedBrick = styled(Link)<{ $available: boolean }>`
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  margin: 0 15px;

  ${(props) =>
    !props.$available &&
    css`
      opacity: 0.3;
    `};
`;

export const Title = styled.div`
  color: #334d6e;
  font-weight: 600;
  font-size: 16px;
`;

export const Note = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #334d6e;
  opacity: 0.5;
  margin-top: 5px;
  max-width: 67%;
`;

export const RequestBrick = styled(LinkedBrick)`
  border: 1px solid #9dd95c;
  padding: 13px 17px;
`;

export const TicketsBrick = styled(LinkedBrick)`
  border: 1px solid #56c9fa;
  padding: 13px 17px;
`;

export const WorkBrick = styled.a`
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  margin: 0 15px;
  border: 1px solid #56c9fa;
  padding: 13px 17px;
`;
