import styled from 'styled-components/macro';

export const Item = styled.div<{ selected: boolean }>`
  text-decoration: none;
  color: #000000;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 12px;
  border-radius: 50px;
  background-color: ${(props) => (props.selected ? '#ffffff' : 'transparent')};
`;

export const Wrapper = styled.nav`
  display: flex;
  border-radius: 50px;
  padding: 3px;
  background-color: #deddea;
`;
