import React, { useState } from 'react';
import { ConfirmList, IConfirmListSettings } from '../components';

let promise: {
    resolve: (value: string) => void;
    reject: (reason?: string) => void;
} | null = null;

const defaultConfirmListSettings: IConfirmListSettings = {
    title: 'Подтверждение',
    body: '',
    list: [],
    type: 'confirm',
    confirmText: 'Да',
    dismissText: 'Нет',
};

interface ConfirmListProps {
    body: string;
    title?: string;
    list?: string[];
    type?: 'alert' | 'confirm';
    confirmText?: string;
    dismissText?: string;
}

export const useConfirmList = () => {
    const [show, setShow] = useState(false);
    const [settings, setSettings] = useState<IConfirmListSettings>({
        ...defaultConfirmListSettings,
    });

    const handleShow = (confirmListSettings: ConfirmListProps) => {
        setSettings({
            ...defaultConfirmListSettings,
            ...confirmListSettings,
        });
        setShow(true);

        return new Promise((resolve, reject) => {
            promise = {
                resolve,
                reject,
            };
        });
    };

    const handleConfirm = () => {
        promise?.resolve('');
        setShow(false);
    };

    const handleDismiss = () => {
        promise?.reject();
        setShow(false);
    };

    const Component = () => (
        <>
            {show && (
                <ConfirmList
                    {...settings}
                    onConfirm={handleConfirm}
                    onDismiss={handleDismiss}
                />
            )}
        </>
    );

    return [Component, handleShow] as [() => JSX.Element, typeof handleShow];
};