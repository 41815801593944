import React, { ChangeEvent, useRef } from 'react';
import { FilesRow, StyledLoader, BtnText, BtnTextSmall} from './file-loader.styled';


interface Props {
    files: File[];
    onChange: (files: File[]) => void;
    onRemove: (index: number) => void;
}

export const FileLoader = React.memo(({ files, onChange, onRemove }: Props) => {
    const filesRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(Array.from(e.target.files || []));
        e.target.value = '';
    };

    return (
        <div>
            <StyledLoader htmlFor={'files-input'}>
                <input
                    id={'files-input'}
                    multiple
                    onChange={(e) => handleChange(e)}
                    ref={filesRef}
                    type={'file'}
                />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <BtnText>

                        <span>Выберите файлы</span>
                        {/*или перетащите в эту область*/}
                    </BtnText>
                    <BtnTextSmall>Допустимые форматы: jpg, png, svg, bmp, gif. Макс. размер файла: 10 Мб</BtnTextSmall>
                </div>
            </StyledLoader>
            <FilesRow>
                {files.map((file, index) => (
                    <span key={index}>
            {file.name}{' '}
                        <button key={index} type={'button'} onClick={() => onRemove(index)}>
              x
            </button>
          </span>
                ))}
            </FilesRow>
        </div>
    );
});