import {
  Card,
  CardList,
  Number,
  Deleter,
  Wrapper,
} from 'app/components/select-card-dropdown/select-card-dropdown.styled';
import { ICard } from 'app/pages/accruals/types';
import React, { useState } from 'react';
import checkTrue from './assets/check-true.svg';
import checkFalse from './assets/check-false.svg';
import masterCard from './assets/master-card.svg';
import rubbishOpened from './assets/rubbish-opened.svg';
import rubbishClosed from './assets/rubbish-closed.svg';

interface Props {
  cards: ICard[];
  isOpen: boolean;
  selectedCard: ICard;
  onSelect: (card: ICard) => void;
  onRemove?: (card: ICard) => void;
}

export const SelectCardDropDown = React.memo(
  ({ cards, isOpen, selectedCard, onSelect, onRemove }: Props) => {
    const [rubbish, setRubbish] = useState<string>(rubbishClosed);
    const [hoveredCard, setHoveredCard] = useState<string>('0');

    const removeCard = (card: ICard) => {
      if (onRemove) {
        onRemove(card);
        onSelect(cards[0]);
      }
    };

    return (
      <Wrapper
        isOpen={isOpen}
        onMouseLeave={() => setHoveredCard('0')}
        data-testid={'SelectCardDropDown'}
      >
        <CardList>
          {cards.map((card) => (
            <Card
              data-testid={'card__' + card._id}
              key={card._id}
              selected={selectedCard._id === card._id}
              onMouseOver={() => setHoveredCard(card._id)}
            >
              <img src={masterCard} />
              <Number
                style={{ marginLeft: '10px' }}
                onClick={() => onSelect(card)}
              >
                * * * * {card.card.substr(card.card.length - 4)}
              </Number>
              <img
                onClick={() => onSelect(card)}
                src={selectedCard._id === card._id ? checkTrue : checkFalse}
                alt={'Выбрано'}
              />
              {onRemove && hoveredCard === card._id && (
                <Deleter
                  onClick={() => removeCard(card)}
                  onMouseOver={() => setRubbish(rubbishOpened)}
                  onMouseLeave={() => setRubbish(rubbishClosed)}
                  src={rubbish}
                />
              )}
            </Card>
          ))}
        </CardList>
      </Wrapper>
    );
  }
);
