import React, { useState } from 'react';
import { OldConfirm } from '../components';

export const useOldConfirm = ({
  onConfirm,
  onDismiss,
  ...props
}: {
  title?: string;
  body: string;
  type?: 'confirm' | 'alert';
  confirmText?: string;
  dismissText?: string;
  onConfirm: (anyData?: any) => void;
  onDismiss?: (anyData?: any) => void;
}) => {
  /* userData нужна для получения в колбэке сохраенных пользовательских данных, пока не придуман асинхронный конфирм, возвращающий промайс */
  const [userData, setUserData] = useState<any>(null);
  const [isOpen, setOpen] = useState(false);
  const handleConfirm = () => {
    setOpen(false);
    onConfirm(userData);
  };
  const handleDismiss = () => {
    setOpen(false);
    onDismiss && onDismiss(userData);
  };
  return {
    Confirm: ({ className }: { className?: string }) => (
      <>
        {isOpen && (
          <OldConfirm
            onConfirm={handleConfirm}
            onDismiss={handleDismiss}
            {...props}
            className={className}
          />
        )}
      </>
    ),
    openConfirm: (anyData?: any) => {
      setUserData(anyData);
      setOpen(true);
    },
  };
};
