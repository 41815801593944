import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Wrapper, Input, Error } from './phone-input.styled';
import { useAppDispatch } from '../../../../hooks';
import { updatePhone } from '../../qr-payment-slice';
import { useSelector } from 'react-redux';
import { getPhoneSelector } from '../../selectors';
import { cursorFinder } from 'app/utils/handlers';

export interface Props {
  isError: boolean;
}

export const PhoneInput = React.memo(({ isError }: Props) => {
  const dispatch = useAppDispatch();
  const phone = useSelector(getPhoneSelector);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePhone(e.target.value));
  };

  return (
    <Wrapper>
      <ReactInputMask
        mask={'+7 999 999 99 99'}
        placeholder={'+7 999 999 99 99'}
        onChange={handleChangePhone}
        onFocus={(e) => cursorFinder(e)}
        autoFocus
        defaultValue={phone}
      >
        {(inputProps: any) => (
          <Input
            type={'tel'}
            isError={isError}
            onClick={(e) => cursorFinder(e)}
            autoFocus
            {...inputProps}
          />
        )}
      </ReactInputMask>
      {isError && <Error>Телефон введён неверно</Error>}
    </Wrapper>
  );
});
