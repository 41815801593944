import { flattenDeep, uniq } from 'lodash';
import moment from 'moment';
import { IMeter } from '../../pages/meters/types';
import { formatISODate } from '../../utils/getters';

export const prepareChartData = (meters: IMeter[], constants: any) => {
  // 1. Собрать все периоды показаний
  const allPeriods = meters.map((meter) => {
    return meter.readings.map((reading) => reading.period);
  });
  let periods = flattenDeep(allPeriods);
  periods = uniq(periods);
  periods = periods.sort(
    (a, b) => new Date(a).valueOf() - new Date(b).valueOf()
  );
  // 2. Строим скелет данных
  const data: any[] = periods.map((period) => ({
    name: moment(period).format('MM.YYYY'),
  }));
  // 3. Заполняем каждый период
  data.forEach((point) => {
    meters.forEach((meter) => {
      meter.readings.forEach((reading) => {
        if (formatISODate(reading.period, 'MM.YYYY') === point.name) {
          reading.deltas.forEach((value, index) => {
            point[meter.id + index] = value;
          });
        }
      });
    });
  });
  return data;
};

export const getStrokeColor = (_type: string, index: number) => {
  const type = _type.toLowerCase();
  if (type.includes('cold')) {
    return '#61C1F8';
  }
  if (type.includes('gas')) {
    return '#E78512';
  }
  if (type.includes('hot')) {
    return '#EE6D26';
  }
  if (type.includes('electric')) {
    if (index === 0) {
      return '#8BCA6D';
    }
    if (index === 1) {
      return '#FFBA69';
    }
    if (index === 3) {
      return '#FB49C0';
    }
  }
  return '#FFBA69';
};

// Что-то забытое
// const renderLegend = (props: any) => {
//   const { payload } = props;
//   let meterNames: string[] = [];
//   payload.forEach((data: any) => {
//     const meter = find(meters, {
//       id: data.dataKey.substring(0, data.dataKey.length - 1),
//     }); // Отрезается index расхода, и отдается только id
//     if (meter) {
//       meterNames.push(
//         `${getConstantValue(constants.MeterTypeNamesShort, meter.type)} ${
//           meter.serial_number
//         }`
//       );
//     } else {
//       meterNames.push('');
//     }
//   });
//   return (
//     <ul>
//       {payload.map((entry: any, index: number) => (
//         <li key={`item-${index}`}>{meterNames[index]}</li>
//       ))}
//     </ul>
//   );
// };
