import { find, map } from 'lodash';
import React, { ChangeEvent, memo } from 'react';
import { Field } from './field';
import { IStepInputField, IStepInputValues } from './types';

interface Props {
  fields: IStepInputField[];
  disabled?: boolean;
  onChange: (newFields: IStepInputField[]) => void;
  onSubmit: (values: IStepInputValues) => void;
}

/* Отображаем либо первый инпут, пока ничего не заполнено, либо те, у которых предыдущие являются когда-то заполненным */
const filterFields = (
  field: IStepInputField,
  index: number,
  fields: IStepInputField[]
) => !index || fields[index - 1]?.isFilled;

const getUpdatedFields = (
  fields: IStepInputField[],
  nextValues: IStepInputValues[]
) => {
  let hasErrors = false;
  let isComplete = false;

  const updatedFields = fields.map((field, index) => {
    const newFieldValues = find(nextValues, { name: field.name });

    if (!newFieldValues) return field;

    const errorText = field.validator(newFieldValues.value);
    // Рисуем ошибку только если прошлое поле уже заполнено.
    // Что бы не рисовать ошибку полям, которых ещё не было на экране
    const error =
      errorText && (index === 0 || fields[index - 1]?.isFilled)
        ? errorText
        : '';

    if (error) {
      hasErrors = true;
    } else {
      if (index === fields.length - 1) {
        // Обновлен последний экземпляр
        isComplete = true;
      }
    }

    // Считаем поле заполненным, если в нем нет ошибки и оно может вообще быть isFilled
    const isFilled = field.neverFilled
      ? false
      : !field.isFilled
      ? !error
      : field.isFilled;

    return {
      ...field,
      value: newFieldValues.value,
      error,
      isFilled,
    };
  });

  return {
    updatedFields,
    hasErrors,
    isComplete,
  };
};

export const InputSteps = memo(
  ({ fields, disabled, onChange, onSubmit }: Props) => {
    const fieldNames = map(fields, 'name');

    const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      // В евент попадают значения target только те, которые видны на экране, то бишь значения скрытых элементов сюда не попадают
      let nextValues: IStepInputValues[] = [];

      fieldNames.forEach((name) => {
        const value = e.target[name]?.value;
        if (typeof value === 'string')
          nextValues.push({
            name,
            value,
          });
      });

      const { updatedFields, hasErrors, isComplete } = getUpdatedFields(
        fields,
        nextValues
      );
      onChange(updatedFields);

      if (isComplete && !hasErrors) {
        let values: IStepInputValues = {};

        updatedFields.forEach((field) => {
          values[field.name] = field.value;
        });

        onSubmit(values);
      }
    };

    const handleKeySubmit = (e: any) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        // В евент попадают значения target только те, которые видны на экране, то бишь значения скрытых элементов сюда не попадают
        let nextValues: IStepInputValues[] = [];

        fieldNames.forEach((name) => {
          if (e.target.name === name) {
            const value = e.target?.value;

            if (typeof value === 'string') {
              nextValues.push({
                name: e.target.name,
                value,
              });
            }
          }
        });

        const { updatedFields, hasErrors, isComplete } = getUpdatedFields(
          fields,
          nextValues
        );
        onChange(updatedFields);

        if (isComplete && !hasErrors) {
          let values: IStepInputValues = {};

          updatedFields.forEach((field) => {
            values[field.name] = field.value;
          });

          onSubmit(values);
        }
      }
    };

    return (
      <form onSubmit={handleSubmit} onKeyPress={handleKeySubmit}>
        {fields.filter(filterFields).map((field, index) => (
          <Field key={field.name} field={field} />
        ))}
      </form>
    );
  }
);
