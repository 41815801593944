import { IPhone } from '../types';
import { API } from './api';

export const GetProfileInfo = () => API.get('/cabinet/tenant_profile/');

export const GetTenantPhones = () => API.get('/cabinet/tenant_profile/phones/');

export const GetReceiptsInfo = () => API.get(`/cabinet/notifications/`);

export const UpdateReceiptsInfo = (receiptsInfo: { [key: string]: any }) =>
  API.patch('/cabinet/notifications/update/', undefined, receiptsInfo);

export const AddTenantPhone = (phone: IPhone) =>
  API.put('/cabinet/tenant_profile/phones/', phone);

export const RemoveTenantPhone = (phoneId: string) =>
  API.get(`/cabinet/tenant_profile/phones/${phoneId}/not_actual/`);

export const ChangeTenantEmail = (email: string) =>
  API.post('/cabinet/tenant_profile/change_email/', { email });

export const ReChangeTenantEmail = () =>
  API.get('/cabinet/tenant_profile/send_activation_mail_again/');

/*
 * Изменение пароля от личного кабинета.
 *
 * Если у пользователя несколько лицевых счетов, которые прикреплены к личному кабинету, пароль изменяется для всех.
 */
export const ChangeTenantPassword = (password: string) =>
  API.post('/cabinet/tenant_profile/user_change_password/', {
    password,
  });

export const CheckTenantPassword = (password: string) =>
  API.post('/auth/check_current_password/', { password });

export const DeactivateAccount = () =>
  API.get('/cabinet/tenant_profile/deactivate_account/');

export const getInsuranceList = () =>
  API.get(`/cabinet/insurance/`);
