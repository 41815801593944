import styled from 'styled-components/macro';
import { ResetButtonMixin } from '../../styles/mixins';

export const Number = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  flex: 1;
`;

export const Remove = styled.button`
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;

  ${ResetButtonMixin};
`;

export const Deleter = styled.img`
  position: absolute;
  right: -35px;
`

export const Card = styled.div<{ selected: boolean }>`
  width: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  margin-bottom: 16px;
  background-color: ${(props) => (props.selected ? '#23A6F0' : '#ffffff')};
  border-radius: 16px;
  cursor: pointer;

  ${Number} {
    color: ${(props) => (props.selected ? '#ffffff' : '#3E3E3E')};
  }

  ${Remove} {
    color: ${(props) => (props.selected ? '#ffffff' : '#FF794F')};
  }
`;

export const CardList = styled.div`
  padding-bottom: 10px;
`;

export const Wrapper = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
