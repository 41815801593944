import styled from 'styled-components/macro';

export const Choice = styled.button<{ selected: boolean, color?: string, background?: string }>`
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? '#ffffff' : props.color ? props.color : '#56c9fa')};
  outline: none;
  width: 110px;
  height: 47px;
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => (props.selected ? '#ffffff' : props.color ? props.color : '#56c9fa')};
  background-color: ${(props) => (props.selected ? props.background ?  props.background : '#9DD95C' : '#ffffff')};
  border-radius: 10px;
  transition: background-color 0.5s ease;
  margin: 0 10px;
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
`;
