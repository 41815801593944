import { getUserInfoSelector } from 'app/pages/auth/selectors';
import { getRights } from 'app/utils/getters';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOldConfirm } from './use-old-confirm';

export const useRights = (slug: string, warnText?: string) => {
  const userInfo = useSelector(getUserInfoSelector);
  const rights = useMemo(() => getRights(userInfo.slugs, slug), [userInfo, slug]);
  const { Confirm, openConfirm } = useOldConfirm({
    body: warnText || 'Недостаточно прав',
    type: 'alert',
    confirmText: 'Понятно',
    onConfirm: () => {},
  });

  const onClickHandler = (e: any) => {
    if (!rights.read) {
      e.preventDefault();
      e.stopPropagation();
      warnText && openConfirm();
    }
  };

  return {
    ...rights,
    onClickHandler,
    Protect: ({
      children,
    }: {
      children: React.ReactElement | React.ReactElement[];
    }) => (
      <>
        <Confirm />
        {children}
      </>
    ),
  };
};
