import { unwrapResult } from '@reduxjs/toolkit';
import { FC, useState } from 'react';
import { printAccrualReceipt } from '../pages/accruals/accruals-slice';
import { IAccrual } from '../pages/accruals/types';
import { useAppDispatch } from './redux-hooks';
import { useIphoneFilePopup } from './use-iphone-file-popup';

const downloadReceipt = (url: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', '');
  // a.setAttribute('target', '_blank');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/*
 * Хуй для печати квитанций
 * Возвращает onPrintReceipt - состояние печати
 *            printReceipt   - функция запуска печати
 *            IPhoneConfirm  - компонент подтверждения запуска скачивания на IPhone. Без его размещения на странице на IPhone хук не сработает
 * */
export const usePrintReceipt = (): {
  onPrintReceipt: boolean;
  printReceipt: (accrual: IAccrual) => void;
  IPhoneConfirm: FC;
} => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [Popup, showPopup] = useIphoneFilePopup();

  const printReceipt = (accrual: IAccrual) => {
    setDisabled(true);

    dispatch(
      printAccrualReceipt({
        accrual: accrual.accrual_id,
        sector_code: accrual.sector_code,
      })
    )
      .then(unwrapResult)
      .then(async ({ url }) => {
        if (url) {
          // fetch(response.url).then(response => response.blob()).then(response => {
          //   // console.log(response, typeof response);
          //   const downloadFile = (blob: Blob, fileName: string) => {
          //     const link = document.createElement('a');
          //     // create a blobURI pointing to our Blob
          //     link.href = URL.createObjectURL(blob);
          //     link.download = fileName;
          //     // some browser needs the anchor to be in the doc
          //     document.body.append(link);
          //     link.click();
          //     link.remove();
          //     // in case the Blob uses a lot of memory
          //     setTimeout(() => URL.revokeObjectURL(link.href), 7000);
          //   };
          //
          //   downloadFile(response, "");
          // })
          // saveAs(response.url);
          // // window.open(response.url);
          // const a = document.createElement('a');
          // a.setAttribute('href', response.url);
          // // a.setAttribute('download', '');
          // a.setAttribute('download', '');
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          await showPopup();
          downloadReceipt(url);
        }
      })
      .finally(() => setDisabled(false));
  };

  return {
    onPrintReceipt: disabled,
    printReceipt,
    IPhoneConfirm: Popup,
  };
};
