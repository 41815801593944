import React, { useState, useEffect } from 'react';
import { ConfirmHtml, IConfirmHtmlSettings } from '../components';

let promise: {
  resolve: (value: string) => void;
  reject: (reason?: string) => void;
} | null = null;

const defaultConfirmSettings: IConfirmHtmlSettings = {
  title: 'Подтверждение',
  children: <div>Тест</div>,
  type: 'confirm',
  confirmText: 'Да',
  dismissText: 'Нет',
};

interface ConfirmProps {
  children: React.ReactNode | React.ReactChild;
  title?: string;
  type?: 'alert' | 'confirm';
  confirmText?: string;
  dismissText?: string;
}

export const useConfirmHtml = () => {
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState<IConfirmHtmlSettings>({
    ...defaultConfirmSettings,
  });

  const handleShow = (confirmSettings: ConfirmProps) => {
    setSettings({
      ...defaultConfirmSettings,
      ...confirmSettings,
    });
    setShow(true);

    return new Promise((resolve, reject) => {
      promise = {
        resolve,
        reject,
      };
    });
  };

  const handleConfirm = () => {
    promise?.resolve('');
    setShow(false);
  };

  const handleDismiss = () => {
    promise?.reject();
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const Component = () => (
    <>
      {show && (
        <ConfirmHtml
          {...settings}
          onConfirm={handleConfirm}
          onDismiss={handleDismiss}
        />
      )}
    </>
  );

  return [Component, handleShow, handleClose] as [
    () => JSX.Element,
    typeof handleShow,
    () => void
  ];
};
