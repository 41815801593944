import React from 'react';
import usePortal from 'react-useportal';

import { Body, ButtonGroup, Fade, Title, Wrapper } from './confirm-html.styled';

export interface IConfirmHtmlSettings {
  title: string;
  children: React.ReactNode | React.ReactChild;
  type: 'alert' | 'confirm';
  confirmText: string;
  dismissText: string;
}

interface Props extends IConfirmHtmlSettings {
  onConfirm: () => void;
  onDismiss: () => void;
}

export const ConfirmHtml = React.memo(
  ({
    title,
    confirmText,
    dismissText,
    type,
    children,
    onConfirm,
    onDismiss,
  }: Props) => {
    const { Portal } = usePortal();

    return (
      <Portal>
        <Fade />
        <Wrapper>
          <Title>{title}</Title>
          <Body>{children}</Body>
          <ButtonGroup $isAlert={type === 'alert'}>
            {type === 'confirm' && (
              <button
                onClick={onDismiss}
                type={'button'}
                style={{ color: '#d93030' }}
              >
                {dismissText}
              </button>
            )}
            <button onClick={onConfirm} type={'button'}>
              {confirmText}
            </button>
          </ButtonGroup>
        </Wrapper>
      </Portal>
    );
  }
);
