import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateRequest,
  GetRequest,
  GetRequestList,
  UpdateRequest,
  UpdateRequestFiles,
  GetKindsTree,
  GetKindsDescriptions,
} from 'app/api/requests';
import { showWarning } from 'app/store/root-slice';
import { RootState } from 'app/store/store';
import { formatDateToISO } from 'app/utils/getters';
import moment from 'moment';
import { IBlankRequest, IRequest } from './types';
import { IPhone } from 'app/types';
import { getTenantPhones } from '../profile/profile-slice';

export type ListType = 'all' | 'area' | 'house';

export interface RequestFilter {
  limit: number;
  offset: number;
  house_requests: boolean;
  tenant_requests: boolean;
}

export interface InitialState {
  statuses: {
    loaded: {
      all: boolean;
      area: boolean;
      house: boolean;
    };
    onLoad: {
      all: boolean;
      area: boolean;
      house: boolean;
    };
    show: ListType;
  };
  requests: {
    selected: IRequest;
    all: IRequest[];
    area: IRequest[];
    house: IRequest[];
    counts: {
      all: number;
      area: number;
      house: number;
    };
  };
  filters: {
    all: RequestFilter;
    area: RequestFilter;
    house: RequestFilter;
  };
  blankRequest: IBlankRequest;
  commonPhones: IPhone[];
  // TODO проверить что может быть в ответе и типизировать
  kindsTree: { [propName: string]: any };
  kindsDescriptions: { [propName: string]: any };
}

export const initialState: InitialState = {
  statuses: {
    loaded: {
      all: false,
      area: false,
      house: false,
    },
    onLoad: {
      all: false,
      area: false,
      house: false,
    },
    show: 'house',
  },
  requests: {
    selected: {} as IRequest,
    all: [],
    area: [],
    house: [],
    counts: {
      all: 0,
      area: 0,
      house: 0,
    },
  },
  filters: {
    all: {
      // В данный момент это "Мои"
      limit: 10,
      offset: 0,
      tenant_requests: true,
      house_requests: false,
    },
    area: {
      limit: 10,
      offset: 0, // Пока не используется
      tenant_requests: true,
      house_requests: false,
    },
    house: {
      // В данный момент это "Все"
      limit: 10,
      offset: 0,
      tenant_requests: true,
      house_requests: true,
    },
  },
  blankRequest: {
    body: '',
    phones: [],
    photos: [],
    common_house: null,
    dt_desired_start: '',
    dt_desired_end: '',
    kinds: [],
  },
  commonPhones: [],
  kindsTree: {},
  kindsDescriptions: {},
};

export const getRequestsList = createAsyncThunk<any>(
  'requests/getRequestsList',
  async (listType, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      const data = await GetRequestList(
        state.requests.filters[state.requests.statuses.show]
      );
      return {
        list: data.results || [],
        type: state.requests.statuses.show,
        count: data.count,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getRequest = createAsyncThunk<any, string, any>(
  'requests/getRequest',
  async (requestId, { rejectWithValue, dispatch, getState }) => {
    try {
      return await GetRequest(requestId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateRequest = createAsyncThunk<
  any,
  { requestId: string; payload: any },
  any
>(
  'requests/updateRequest',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      return await UpdateRequest(data.requestId, data.payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createRequest = createAsyncThunk<any, IBlankRequest, any>(
  'requests/createRequest',
  async (request, { rejectWithValue, dispatch, getState }) => {
    try {
      return await CreateRequest({
        ...request,
        dt_desired_start: formatDateToISO(moment(request.dt_desired_start)),
        dt_desired_end: formatDateToISO(moment(request.dt_desired_start)), // Устанавливаю одинаковую специально
      });
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const addRequestFiles = createAsyncThunk<any, any, any>(
  'requests/addRequestFiles',
  async (params, { rejectWithValue }) => {
    try {
      return await UpdateRequestFiles(params.id, params.files);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getKindsTree = createAsyncThunk<any>(
    'requests/getKindsTree',
    async (_, { rejectWithValue, getState }) => {
      try {
        return await GetKindsTree();
      } catch (e) {
        return rejectWithValue(e);
      }
    }
);

export const getKindsDescriptions = createAsyncThunk<any>(
    'requests/getKindsDescriptions',
    async (_, { rejectWithValue }) => {
      try {
        return await GetKindsDescriptions();
      } catch (e) {
        return rejectWithValue(e);
      }
    }
);

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    clearErrors: (state) => {},
    switchListType: (state, action) => {
      state.statuses.show = action.payload;
    },
    selectRequest: (state, action: PayloadAction<IRequest>) => {
      state.requests.selected = action.payload;
    },
    clearCurrentRequest: (state) => {
      state.requests.selected = initialState.requests.selected;
    },
    resetStore: (state) => ({
      ...initialState,
      statuses: {
        ...initialState.statuses,
        show: state.statuses.show, // Это нужно, что бы неуправляемый buttonsTabs оставался актуальным
      },
      blankRequest: {
        ...initialState.blankRequest,
        phones: state.commonPhones,
        photos: state.blankRequest.photos,
      },
      commonPhones: state.commonPhones,
    }),
    addPhone: (state, action) => {
      state.blankRequest.phones.push(action.payload);
    },
    removePhone: (state, action) => {
      state.blankRequest.phones.splice(action.payload, 1);
    },
    addDate: (state, action) => {
      state.blankRequest.dt_desired_start = action.payload;
    },
    typeChoice: (state, action) => {
      state.blankRequest.common_house = !action.payload;
    },
    addComment: (state, action) => {
      state.blankRequest.body = action.payload;
    },
    setKinds: (state, action) => {
      state.blankRequest.kinds = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Получение списка тикетов
    builder.addCase(getRequestsList.pending, (state, action) => {
      state.statuses.loaded[state.statuses.show] = true;
      state.statuses.onLoad[state.statuses.show] = true;
    });

    // builder.addCase(getRequestsList.rejected, (state, action) => {
    //   state.statuses.onLoad[state.statuses.show] = false;
    // });

    builder.addCase(
      getRequestsList.fulfilled,
      (
        state,
        action: PayloadAction<{
          list: IRequest[];
          type: ListType;
          count: number;
        }>
      ) => {
        const { type, list, count } = action.payload;
        state.requests[type] = [...state.requests[type], ...list];
        state.requests.counts[type] = count;
        state.filters[type].offset += 10;
        state.statuses.onLoad[type] = false;
      }
    );

    builder.addCase(getRequest.fulfilled, (state, action) => {
      const types: ListType[] = ['all', 'area', 'house'];

      types.forEach((type) => {
        state.requests[type] = state.requests[type].map((request) => {
          if (request._id !== action.payload._id) {
            return request;
          } else {
            return action.payload;
          }
        });
      });
    });

    builder.addCase(getTenantPhones.fulfilled, (state, { payload }) => {
      state.blankRequest.phones = payload;
      state.commonPhones = payload;
    });

    builder.addCase(getKindsTree.fulfilled, (state, { payload }) => {
      state.kindsTree = payload.results;
    });

    builder.addCase(getKindsDescriptions.fulfilled, (state, { payload }) => {
      state.kindsDescriptions = payload;
    });
  },
});

export const {
  switchListType,
  selectRequest,
  resetStore,
  addPhone,
  removePhone,
  addDate,
  typeChoice,
  clearCurrentRequest,
  addComment,
  setKinds
} = requestsSlice.actions;

export default requestsSlice.reducer;
