import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes } from './routes';
import { QrRedirectLayout } from '../../layouts/qr-redirect-layout';

export const QrPayment = () => {
  return (
    <>
      <Helmet>
        <title>Оплата QR</title>
      </Helmet>
      <QrRedirectLayout>
        <Routes />
      </QrRedirectLayout>
    </>
  );
};
