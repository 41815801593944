import { MFMedia } from 'app/styles/media-breakpoints';
import styled from 'styled-components/macro';
import { Button } from '../button/button';
import errorImage from './assets/error-image.svg';

import successImage from './assets/success-image.svg';

export const Text = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
`;

export const SuccessImage = styled.img.attrs({
  src: successImage,
  alt: 'success',
})``;

export const ErrorImage = styled.img.attrs({
  src: errorImage,
  alt: 'error',
})``;

export const TopSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)``;

export const Wrapper = styled.div<{ state: 'success' | 'error' }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;

  ${Text} {
    color: ${(props) => (props.state === 'success' ? '#4dac20' : '#E56464')};
  }

  @media (${MFMedia.smallPC}) {
    position: absolute;
  }
`;
