import React, { useState, useEffect } from 'react';

import { Wrapper, Item } from './button-tabs-x-switch-nav.styled';

type Props = {
  items: any[];
  onSelect: (item: any) => void;
  selectedItem?: any;
  className?: string;
  dontSelect?: boolean;
  selectedFromProps?: any;
};

//TODO: Новый компонент - коллаб компонентов button-tabs x switch-nav
// (работает как button-tabs, а выглядит как switch-nav)

export const ButtonTabsXSwitchNav = React.memo(
  ({
    items,
    onSelect,
    dontSelect,
    selectedItem,
    className,
    selectedFromProps,
  }: Props) => {
    const [selected, setSelected] = useState(
      dontSelect ? '' : selectedItem ? selectedItem.id : items[0]?.id
    );

    useEffect(() => {
      if (selectedFromProps) {
        setSelected(selectedFromProps);
      }
    }, [selectedFromProps]);

    return (
      <Wrapper className={className}>
        {items.map((item) => (
          <Item
            selected={selected === item.id}
            key={item.id}
            onClick={() => {
              setSelected(item.id);
              onSelect(item);
            }}
          >
            {item.title}
          </Item>
        ))}
      </Wrapper>
    );
  }
);
