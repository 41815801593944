import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../../hooks';
import { Wrapper, Row, Box, ErrorRow } from './code-input.styled';
import { updateCode } from '../../qr-payment-slice';

export interface Props {
  isError: boolean;
}

const numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]; //Допустимые символы для ввода

export const CodeInput = React.memo(({ isError }: Props) => {
  const dispatch = useAppDispatch();
  //TODO: откровенно реализация так себе, если будет время, нужно продумать лучше, как это должно быть

  const [letter1, setLetter1] = useState('');
  const [letter2, setLetter2] = useState('');
  const [letter3, setLetter3] = useState('');
  const [letter4, setLetter4] = useState('');

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let code = [letter1, letter2, letter3, letter4].join('');
    dispatch(updateCode(code));
  }, [letter1, letter2, letter3, letter4]);

  useEffect(() => {
    inputRef1.current?.focus();
  }, []);

  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLetter1(e.target.value);

    if (e.target.value.length) {
      inputRef2.current?.focus();
    }
  };

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLetter2(e.target.value);

    if (e.target.value.length) {
      inputRef3.current?.focus();
    } else {
      inputRef1.current?.focus();
    }
  };

  const handleChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLetter3(e.target.value);

    if (e.target.value.length) {
      inputRef4.current?.focus();
    } else {
      inputRef2.current?.focus();
    }
  };

  const handleChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLetter4(e.target.value);

    if (!e.target.value.length) {
      inputRef3.current?.focus();
    }
  };

  /* В <input type="number/tel/text" /> НЕ вводятся спецсимволы */
  const handleKeyListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (!numbers.includes(Number(e.key)) && e.key !== 'Backspace') ||
      e.key === ' '
    ) {
      e.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Row>
        <Box
          isError={isError}
          value={letter1}
          onKeyDown={(e) => handleKeyListener(e)}
          onChange={(e) => handleChange1(e)}
          ref={inputRef1}
        />
        <Box
          isError={isError}
          value={letter2}
          onKeyDown={(e) => handleKeyListener(e)}
          onChange={(e) => handleChange2(e)}
          ref={inputRef2}
        />
        <Box
          isError={isError}
          value={letter3}
          onKeyDown={(e) => handleKeyListener(e)}
          onChange={(e) => handleChange3(e)}
          ref={inputRef3}
        />
        <Box
          isError={isError}
          value={letter4}
          onKeyDown={(e) => handleKeyListener(e)}
          onChange={(e) => handleChange4(e)}
          ref={inputRef4}
        />
      </Row>
      {isError && <ErrorRow>Код введён неверно</ErrorRow>}
    </Wrapper>
  );
});
