import styled from 'styled-components/macro';
import { MFMedia } from '../../../../styles/media-breakpoints';

export const Container = styled.div<{
  focusSearchPanel: boolean;
  fixedBlockHeight?: number;
}>`
  position: relative;
  padding: 0 16px;
  height: calc(
    100% -
      ${(props) => (props.fixedBlockHeight ? props.fixedBlockHeight + 'px' : 0)}
  );
  background-color: ${(props) =>
    props.focusSearchPanel ? '#FFFFFF' : 'initial'};

  @media (${MFMedia.tablet}) {
    padding: 0 16px;
    margin-top: 5px;
    background-color: initial;
  }

  @media (${MFMedia.smallPC}) {
    margin-top: 0;
    padding: 0;
  }
`;

export const ServiceItemsGridPC = styled.div`
  display: none;

  @media (${MFMedia.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    padding-bottom: 24px;
    margin-top: 24px;
  }

  @media (${MFMedia.smallPC}) {
    padding: 0 32px 24px 32px;
  }
`;

export const ServiceItemsGridMobile = styled(ServiceItemsGridPC)`
  display: grid;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;

export const NavWrapper = styled.div`
  display: none;

  @media (${MFMedia.tablet}) {
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  @media (${MFMedia.smallPC}) {
    margin: 0 0 12px 16px;
    background: #ffffff;
  }
`;

export const Fixed = styled.div`
  width: 100%;
  background-color: #ffffff;

  @media (${MFMedia.tablet}) {
    padding: 16px 19px;
    width: calc(100% - 30px);
    margin: 5px 0 0 15px;
    background-color: #ffffff;
    border-radius: 15px;
  }

  @media (${MFMedia.smallPC}) {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;

export const NavTitle = styled.div`
  color: #89929b;
  font-size: 16px;
  line-height: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (${MFMedia.tablet}) {
    font-weight: 600;
    color: #0b2646;
    font-size: 28px;
    position: static;
    transform: none;
  }
`;

export const Breadcrumbs = styled.div`
  color: #9aa4b2;
  margin-bottom: 12px;

  span {
    color: #4b5565;
  }
`;

export const BreadcrumbsLink = styled.a``;

export const ServiceCompanyExtraInfo = styled.div`
  background: #e0f2fe;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.003em;
  color: #202939;
  padding: 2px 4px;

  &:not(:nth-child(1)) {
    margin-left: 16px;
  }
`;

export const BlockTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0b2646;
`;
