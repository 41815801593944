import React from 'react';
import { useLocation } from 'react-router-dom';

import { Item, Wrapper } from './switch-nav.styled';

interface Props {
  items: any[];
  className?: string;
}

export const SwitchNav = React.memo(({ items, className }: Props) => {
  const { pathname } = useLocation();

  return (
    <Wrapper className={className}>
      {items.map((item) => (
        <Item
          selected={pathname.includes(item.link)}
          key={item.slug}
          to={item.link}
        >
          {item.title}
        </Item>
      ))}
    </Wrapper>
  );
});
