import React from 'react';
import {
  Wrapper,
  Rocket,
  Greetings,
  ContestExplanation,
} from './stages.styled';
import { Button } from '../components';

/** ШАГ БАЛГОДАРНОСТИ С РАКЕТОЙ ПОСЛЕ РЕДИРЕКТА С ЭКВАЙЕРА, КОГДА ОПЛАТИТЬ БОЛЬШЕ НЕЧЕГО **/
/** ../#/qr/payment/greetings/ **/
export const Stage8 = () => {
  const handleGoContest = () => {
    const a = document.createElement('a');
    a.setAttribute('href', 'https://roscluster.ru/pay-and-win/');
    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  };

  return (
    <Wrapper style={{ alignItems: 'center' }}>
      <Rocket />
      <Greetings>Спасибо, что воспользовались нашим сервисом!</Greetings>
      <ContestExplanation>
        Оплатил квитанцию в течение 3 дней с момента её выставления? Участвуй в
        розыгрыше и получи шанс выиграть 1000 рублей на свой лицевой счёт!
      </ContestExplanation>
      <Button onClick={() => handleGoContest()}>Подробнее</Button>
    </Wrapper>
  );
};
