import { MFMedia } from 'app/styles/media-breakpoints';
import styled, { keyframes } from 'styled-components/macro';

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 58px;
  height: 58px;
  border: 7px solid #9de2ff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${SpinAnimation} 1s linear infinite;
`;

export const LoaderBlock = styled.div`
  display: inline-block;
  margin-top: calc(50vh - 80px);
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  z-index: 500;

  @media (${MFMedia.smallPC}) {
    ${LoaderBlock} {
      ${Spinner} {
        width: 72px;
        height: 72px;
        border-width: 10px;
      }
    }
  }
`;

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.3;
  background-color: #000000;
  z-index: 400;
`;
