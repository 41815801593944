import { getConstantsSelector } from 'app/pages/auth/selectors';
import { IConstants } from 'app/types';
import { getConstantValue } from 'app/utils/getters';
import React from 'react';
import { useSelector } from 'react-redux';

export const useConstants = (category: keyof IConstants) => {
  const constants = useSelector(getConstantsSelector)[category] || [];
  return {
    constants,
    getConstantName: (value: string) => getConstantValue(constants, value),
  };
};
