import styled from 'styled-components/macro';
import { Props } from './button';
import { space, typography } from 'styled-system';

export const StyledButton = styled.button<Props>`
  width: 100%;
  background-color: #477fff;
  padding: 15px;
  border-radius: 12px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
  ${space};
  ${typography};

  &:active {
    box-shadow: 0 0 2px #477fff;
  }
`;
