import React, { useEffect } from 'react';
import { Button } from '../components';
import { Wrapper, Header, Explanation, Finish } from './stages.styled';
import { useHistory, useLocation } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { getRemainingAddresses } from '../qr-payment-slice';
import { useAppDispatch } from '../../../hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { makeRedirectState } from '../qr-payment-slice';

/** ШАГ ПОСЛЕ РЕДИРЕКТА С ЭКВАЙЕРА, КОГДА ЕСТЬ ЧТО ОПЛАТИТЬ ЕЩЁ **/
/** ../#/qr/payment/continuation/ **/
export const Stage10 = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useLocation();

  const getId = () => {
    let query = new URLSearchParams(params.search);
    return query.get('id');
  };

  useEffect(() => {
    let id = getId();
    // Если есть ещё, то /#/qr/payment/continuation/?id=123 если нет, то /#/qr/payment/continuation/
    if (!id) {
      history.push(qrRedirectRoutes.qr.payment.greetings.link);
    }
  }, []);

  const handleFinish = () => {
    history.push(qrRedirectRoutes.qr.payment.greetings.link);
  };

  const handleNext = () => {
    let id = getId();
    if (id) {
      dispatch(
        getRemainingAddresses({
          pay_action_id: id,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          dispatch(makeRedirectState(true));
          history.push(qrRedirectRoutes.qr.payment.address.link);
        });
    }
  };

  return (
    <Wrapper>
      <Header>Хотите продолжить?</Header>
      <Explanation>Вы можете продолжить оплату по другой услуге.</Explanation>
      <Button onClick={() => handleNext()}>Продолжить</Button>
      <Finish onClick={() => handleFinish()}>Завершить</Finish>
    </Wrapper>
  );
};
