import React from 'react';
import { getCurrentPrice } from '../../../../utils';
import { toFractionRubles } from 'app/utils/getters';
import {
  ServiceOldPrice,
  PriceBlock,
  ActualPrice,
  ServiceCost,
} from './service-price.styled';
import { IService } from '../../../../types';

interface Props {
  service: IService;
}

export const ServicePrice = ({ service }: Props) => {
  return (
    <ServiceCost>
      {!!service.price.sale && service.price.price_type !== 'contract_price' ? (
        <PriceBlock>
          <ActualPrice>{getCurrentPrice(service.price)} ₽</ActualPrice>
          <ServiceOldPrice>
            {toFractionRubles(service.price.value)} ₽
          </ServiceOldPrice>
        </PriceBlock>
      ) : service.price.price_type !== 'contract_price' ? (
        <ActualPrice>{getCurrentPrice(service.price)} ₽</ActualPrice>
      ) : (
        <ActualPrice>По договоренности</ActualPrice>
      )}
    </ServiceCost>
  );
};
