import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/hooks';
import { isNull } from 'lodash';
import {
  getCatalogServicesBySearch,
  clearCatalogServicesBySearch,
  setCurrentService,
  setCurrentCategory,
  setSearchValue,
  showServiceItemFullCard,
} from '../../../../catalog-slice';
import {
  getCatalogServicesBySearchSelector,
  getCurrentCategorySelector,
  getSearchValueSelector,
} from '../../../../selectors';
import {
  CancelSearchBtn,
  SearchPanelContainer,
  StyledSelect,
  ReturnBtn,
} from './search-panel.styled';
import { ArrowLeft } from '../../../../catalog.styled';

interface Props {
  setFocusSearchPanel: Function;
  focusSearchPanel: boolean;
}

export const SearchPanel = ({
  setFocusSearchPanel,
  focusSearchPanel,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentCategory = useSelector(getCurrentCategorySelector);
  const searchValue = useSelector(getSearchValueSelector);
  const servicesListBySearch = useSelector(getCatalogServicesBySearchSelector);
  const filteredList = useSelector(getCatalogServicesBySearchSelector);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [scrolledOnBottom, setScrolledOnBottom] = useState<boolean>(false);

  useEffect(() => {
    // сброс отфильтрованных поиском услуг
    return () => {
      dispatch(clearCatalogServicesBySearch());
      setFocusSearchPanel(false);
    };
  }, []);

  // поиск по вводу (как по категории, так и по всем услугам) и запись опций в react select
  useEffect(() => {
    if (searchValue.length >= 1) {
      dispatch(clearCatalogServicesBySearch());
      dispatch(
        getCatalogServicesBySearch({
          title: searchValue,
          position: currentCategory?.value,
        })
      );
    }
  }, [searchValue, currentCategory]);

  // поиск услуг только по категории
  useEffect(() => {
    if (currentCategory?.value !== 'all' && !isNull(currentCategory)) {
      dispatch(
        getCatalogServicesBySearch({ position: currentCategory?.value })
      );
    } else {
      dispatch(clearCatalogServicesBySearch());
    }
  }, [currentCategory]);

  useEffect(() => {
    /* Хотелось бы поместить содержимое данного useEffect в handleScrolledOnBottom,
       но пропс onMenuScrollToBottom в react-select не видит изменения в store (и в state).
       Похожая проблема https://github.com/JedWatson/react-select/issues/4618.
       Возможно обновление react-select решит проблему.
    */
    if (
      scrolledOnBottom &&
      focusSearchPanel &&
      servicesListBySearch.count !== servicesListBySearch.services.length
    ) {
      dispatch(
        getCatalogServicesBySearch({
          title: searchValue,
          position: currentCategory?.value,
        })
      );
      setScrolledOnBottom(false)
    }
  }, [scrolledOnBottom]);

  // обновление опций в react-select
  useEffect(() => {
    setOptions(
      servicesListBySearch.services.map((el: any) => ({
        label: el.title,
        value: el.title,
        id: el.id,
      }))
    );
  }, [servicesListBySearch]);

  // переход в карточку услуги при выборе опции react select
  const handleChangeSearchOption = (el: any) => {
    const finded = filteredList.services.find(
      (service: any) => service.id === el.id
    );
    if (finded) {
      dispatch(setCurrentService(finded));
      dispatch(showServiceItemFullCard(true));
    }
  };

  const handleScrolledOnBottom = () => {
    setScrolledOnBottom(true);
  };

  return (
    <SearchPanelContainer>
      {!isNull(currentCategory) && (
        <ReturnBtn>
          <ArrowLeft
            onClick={() =>
              dispatch(
                setCurrentCategory({
                  title: '',
                  value: 'all',
                })
              )
            }
          />
        </ReturnBtn>
      )}
      <StyledSelect
        options={options}
        onMenuScrollToBottom={handleScrolledOnBottom}
        noOptionsMessage={() => null}
        placeholder={'Поиск'}
        classNamePrefix="react-select"
        onFocus={() => setFocusSearchPanel(true)}
        menuIsOpen={searchValue.length >= 1}
        value={searchValue}
        onInputChange={(inputValue: string) =>
          dispatch(setSearchValue(inputValue))
        }
        focusSearchPanel={focusSearchPanel}
        onChange={(option: any) => handleChangeSearchOption(option)}
      />
      {focusSearchPanel && (
        <CancelSearchBtn
          onClick={() => {
            dispatch(clearCatalogServicesBySearch());
            setFocusSearchPanel(false);
            dispatch(setCurrentCategory(null));
          }}
        >
          Отменить
        </CancelSearchBtn>
      )}
    </SearchPanelContainer>
  );
};
