import { find } from 'lodash';
import React from 'react';
import { IMeter } from 'app/pages/meters/types';
import { getConstantValue } from '../../../../utils/getters';
import { formatReadingValue, isHeatDistributor } from '../../../../pages/meters/utils';

interface TooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  meters: IMeter[];
  constants: any;
}

export const TooltipBlack = React.memo(
  ({
     active,
     payload,
     label, // Название по оси X
     meters,
     constants,
   }: TooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {payload.map((item, index) => {
            // console.log('item', item)
            return (
              <div className="list-item" key={index}>
                <div className="label">
                  {getMeterNameById(
                    item.value,
                    constants,
                    meters,
                    item.name.substring(0, item.name.length - 1), // Отрезается index расхода, и отдается только id
                    item.name[item.name.length - 1] // Передается index расхода, который является последним символом name, тк name = meter.id + index(deltas)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }
);

const getMeterNameById = (
  value: number, // Показания
  constants: any,
  meters: IMeter[],
  id: string,
  _index: string
) => {
  const index = +_index;
  const meter = find(meters, { id });
  let name = find(constants.MeterTypeNamesShort, { value: meter?.type })?.text;
  if (meter && name) {
    name = name + ' ' + meter.serial_number;
    const valueStr = `${formatReadingValue(value, meter.type)} ${getConstantValue(
      constants.MeterTypeUnitNames,
      meter.type
    )}`;
    if (meter.type.includes('Three')) {
      // Трехтарифные счетчики
      switch (index) {
        case 0:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr} Пик</div>
            </>
          );
        case 1:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr} Ночь</div>
            </>
          );
        case 2:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr} Полупик</div>
            </>
          );
        default:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr}</div>
            </>
          );
      }
    } else if (meter.type.includes('Two')) {
      switch (index) {
        case 0:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr} День</div>
            </>
          );
        case 1:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr} Ночь</div>
            </>
          );
        default:
          return (
            <>
              <div>{name}</div>
              <div>{valueStr}</div>
            </>
          );
      }
    } else if (isHeatDistributor(meter.type)) {
      // Распределитель тепла. Для данного счетчика не нужен toFixed
      // на value, т.к. всегда возвращается целое число
      return (
        <>
          <div>{name}</div>
          <div>{value} усл. ед.</div>
        </>
        );
    } else {
      return (
        <>
          <div>{name}</div>
          <div>{valueStr}</div>
        </>
      );
    }
  }
  return meter?.type || '';
};
