import { IRequestReading } from '../pages/meters/types';
import { API } from './api';

export const GetMeters = () => API.get('/cabinet/meters/');

export const SendReadings = (data: { meters: IRequestReading[] }) =>
  API.post('/cabinet/meters/', data);

export const GetMetersReport = (params: {
  month_from: string;
  month_till: string;
}) => API.get('/cabinet/meters_report/', params);
