import React, { useEffect, useState } from 'react';
import { Button, PhoneInput } from '../components';
import {
  Wrapper,
  Header,
  Explanation,
  FootNote,
  ConfidenceLink,
} from './stages.styled';
import { getQrPaymentSelector } from '../selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { qrRedirectRoutes } from '../../../routesConfig';
import { useAppDispatch } from '../../../hooks';
import { initCall } from '../qr-payment-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { preparePhone } from '../utils';

/** ШАГ ВВОДА НОМЕРА ТЕЛЕФОНА **/
/** ../#/qr/payment/phone/ **/
export const Stage1 = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { phone } = useSelector(getQrPaymentSelector);
  const [isFirstAttempt, setIsFirstAttempt] = useState<boolean>(true);

  // useEffect(() => {
  //   for (var i = setInterval(''); i >= 0; i--) clearInterval(i);
  // }, []);

  const handleNext = () => {
    //TODO: С ЗАПУСКОМ ТАЙМЕРА В ЛОКАЛСТОРЕЙДЖ!
    setIsFirstAttempt(false);
    dispatch(
      initCall({
        guest_number: preparePhone(phone),
      })
    )
      .then(unwrapResult)
      .then(
        (response) => {
          history.push(qrRedirectRoutes.qr.payment.confirm.link);
        },
        (error) => {
          //...
        }
      );
  };

  return (
    <Wrapper>
      <Header>Введите Ваш номер телефона</Header>
      <Explanation>
        Укажите Ваш действующий номер телефона. На указанный номер телефона
        будет совершён звонок для подтверждения.
      </Explanation>
      <PhoneInput
        isError={(phone.includes('_') || phone[3] !== '9') && !isFirstAttempt}
      />
      <Button
        disabled={phone.includes('_') || !phone.length || phone[3] !== '9'}
        onClick={() => handleNext()}
      >
        Далее
      </Button>
      <FootNote>
        Нажимая кнопку «Далее», я даю согласие на обработку своих персональных
        данных в соответствии с{' '}
        <ConfidenceLink
          href={'https://eis24.me/privacy-policy/'}
          target={'_blank'}
        >
          Политикой конфиденциальности
        </ConfidenceLink>
      </FootNote>
    </Wrapper>
  );
};
