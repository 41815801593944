import { Button } from 'app/components/index';
import { useAppDispatch } from 'app/hooks';
import { getWarningState } from 'app/store/root-selectors';
import { hideWarning } from 'app/store/root-slice';
import React from 'react';
import { useSelector } from 'react-redux';

import { Image, SmallText, Title, TopSection, Wrapper } from './warning.styled';

export const Warning = React.memo(() => {
  const dispatch = useAppDispatch();
  const { show } = useSelector(getWarningState);

  if (!show) {
    return <></>;
  }

  const handleBack = () => {
    dispatch(hideWarning());
  };

  return (
    <Wrapper>
      <TopSection>
        <Image />
        <Title>Произошла ошибка</Title>
        <SmallText>
          Пожалуйста, обратитесь в{' '}
          <a href={'mailto:dispatcher@eis24.me'} title={'dispatcher@eis24.me'}>
            техническую поддержку
          </a>
        </SmallText>
      </TopSection>
      <Button onClick={handleBack} variant={'lightblue'} size={'lg'}>
        Вернуться
      </Button>
    </Wrapper>
  );
});
