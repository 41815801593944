import React, { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { useSelector } from 'react-redux';
import {
  getCatalogCategoriesListSelector,
  getCatalogServicesBySearchSelector,
  getCurrentCategorySelector,
  getSearchValueSelector,
} from '../../../../selectors';
import {
  getCatalogCategoriesList,
  setCurrentCategory,
} from '../../../../catalog-slice';
import { CategoryButtonsList } from './category-buttons-list';
import {
  CategoryBtnBoxMobile,
  CategoryMenuBtnBoxMobile,
  AllCategoryTitleBlock,
  AllCategoryTitle,
  CategoryBtnBoxPC,
  ReturnBtn,
} from './category-list.styled';
import { ArrowLeft } from '../../../../catalog.styled';

// популярные услуги временно замоканы
const tempMockPopular = [
  {
    value: 'repair_and_construction',
    text: 'Ремонт, строительство',
  },
  {
    value: 'master_for_an_hour',
    text: 'Мастер на час',
  },
  {
    value: 'installation_and_repair_of_equipment',
    text: 'Установка и ремонт техники',
  },
  {
    value: 'services_for_animals',
    text: 'Услуги для животных',
  },
  {
    value: 'beauty_and_health',
    text: 'Красота и здоровье',
  },
];

interface Props {
  focusSearchPanel: boolean;
}

export const CategoryList = ({ focusSearchPanel }: Props) => {
  const dispatch = useAppDispatch();
  const servicesListBySearch = useSelector(getCatalogServicesBySearchSelector);
  const categories = useSelector(getCatalogCategoriesListSelector);
  const currentCategory = useSelector(getCurrentCategorySelector);
  const searchValue = useSelector(getSearchValueSelector);

  useEffect(() => {
    dispatch(getCatalogCategoriesList());
  }, []);

  return (
    <>
      {currentCategory?.value === 'all' ? (
        <CategoryMenuBtnBoxMobile>
          <AllCategoryTitleBlock>
            <ReturnBtn onClick={() => dispatch(setCurrentCategory(null))}>
              <ArrowLeft />
              Назад
            </ReturnBtn>
            <AllCategoryTitle>Категории</AllCategoryTitle>
          </AllCategoryTitleBlock>
          <CategoryButtonsList categories={categories} />
        </CategoryMenuBtnBoxMobile>
      ) : (
        <CategoryBtnBoxMobile>
          {/* Популярные категории */}
          {focusSearchPanel &&
            !servicesListBySearch.services.length &&
            !searchValue.length &&
            (currentCategory?.value === 'all' || !currentCategory) && (
              <CategoryButtonsList categories={tempMockPopular} />
            )}
          {/* Категории в списке услуг */}
          {!focusSearchPanel && !currentCategory && (
            <CategoryButtonsList categories={categories} inCarousel />
          )}
        </CategoryBtnBoxMobile>
      )}
      {(!currentCategory || currentCategory.value === 'all') && (
        <CategoryBtnBoxPC>
          <CategoryButtonsList categories={categories} forPC />
        </CategoryBtnBoxPC>
      )}
    </>
  );
};
