import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Icon = styled.img`
  margin-bottom: 36px;
`;

export const Status = styled.div<{ debtor: boolean }>`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => (props.debtor ? '#EE1E1E' : '#26AB5B')};
`;

export const Text = styled.div`
  padding-top: 12px;
  font-size: 16px;
  color: #62789d;
`;
