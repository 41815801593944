import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import { useAppDispatch } from 'app/hooks';
import {
  getCatalogServicesBySearch,
  getCatalogServicesList,
  setCurrentCategory,
} from '../../catalog-slice';
import { isNull } from 'lodash';
import {
  getCatalogServiceListSelector,
  getCatalogServicesBySearchSelector,
  getCatalogStatusesSelector,
  getCurrentCatalogServiceSelector,
  getCurrentCategorySelector,
  getPostOrderResponseSelector,
  getSearchValueSelector,
} from '../../selectors';
import {
  ServiceCard,
  ServiceItemFullCard,
  SearchPanel,
  CategoryList,
  ServiceCardMobile,
  EmptySearchResult,
  OrderResponseModal,
} from './components';
import {
  Container,
  ServiceItemsGridPC,
  ServiceItemsGridMobile,
  NavWrapper,
  Fixed,
  NavTitle,
  Breadcrumbs,
  BreadcrumbsLink,
} from './list.styled';
import { Arrow } from '../../catalog.styled';
import { IService } from '../../types';

export const ServicesList = React.memo(() => {
  const refFixed = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const servicesList = useSelector(getCatalogServiceListSelector);
  const servicesListBySearch = useSelector(getCatalogServicesBySearchSelector);
  const currentCategory = useSelector(getCurrentCategorySelector);
  const currentService = useSelector(getCurrentCatalogServiceSelector); // открытая услуга
  const postOrderResponse = useSelector(getPostOrderResponseSelector);
  const { filtered, list } = useSelector(getCatalogStatusesSelector);
  const searchValue = useSelector(getSearchValueSelector);
  const [focusSearchPanel, setFocusSearchPanel] = useState<boolean>(false);

  useEffect(() => {
    if (!servicesList.services.length) {
      dispatch(getCatalogServicesList());
    }
  }, [location]);

  const handleScroll = (position: positionValues) => {
    if (!list.onLoad && !filtered.onLoad && position.top > 0.8) {
      if (
        focusSearchPanel &&
        servicesListBySearch.count !== servicesListBySearch.services.length
      ) {
        dispatch(
          getCatalogServicesBySearch({
            title: searchValue,
            position: currentCategory?.value,
          })
        );
      } else if (
        !focusSearchPanel &&
        servicesList.count !== servicesList.services.length
      ) {
        dispatch(getCatalogServicesList());
      }
    }
  };

  return (
    <>
      {!!currentService.id.length && <ServiceItemFullCard />}
      <Fixed ref={refFixed}>
        <NavWrapper>
          {!isNull(currentCategory) && currentCategory.value !== 'all' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Breadcrumbs>
                <BreadcrumbsLink
                  onClick={() => dispatch(setCurrentCategory(null))}
                >
                  Каталог
                </BreadcrumbsLink>{' '}
                / <span>{currentCategory?.text}</span>
              </Breadcrumbs>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Arrow onClick={() => dispatch(setCurrentCategory(null))} />
                <NavTitle>{currentCategory?.text}</NavTitle>
              </div>
            </div>
          ) : (
            <NavTitle>Каталог</NavTitle>
          )}
        </NavWrapper>
        {currentCategory?.value !== 'all' && (
          <SearchPanel
            setFocusSearchPanel={setFocusSearchPanel}
            focusSearchPanel={focusSearchPanel}
          />
        )}
      </Fixed>
      <Scrollbars
        autoHide
        onScrollFrame={handleScroll}
        style={{
          height: `calc(100% - ${refFixed.current?.clientHeight + 'px'})`,
        }}
      >
        <Container
          focusSearchPanel={focusSearchPanel}
          fixedBlockHeight={refFixed.current?.clientHeight}
        >
          <CategoryList focusSearchPanel={focusSearchPanel} />
          <ServiceItemsGridPC>
            {(!searchValue.length &&
            !servicesListBySearch.services.length &&
            (!currentCategory || currentCategory.value === 'all') &&
            servicesList.services.length
              ? servicesList.services
              : servicesListBySearch.services
            ).map((service: IService) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </ServiceItemsGridPC>
          <ServiceItemsGridMobile>
            {(!focusSearchPanel &&
            !servicesListBySearch.services.length &&
            !!servicesList.services.length &&
            !currentCategory
              ? servicesList.services
              : !!servicesListBySearch.services.length
              ? servicesListBySearch.services
              : []
            ).map((service: IService) => (
              <ServiceCardMobile key={service.id} service={service} />
            ))}
          </ServiceItemsGridMobile>
          {!servicesListBySearch.services.length &&
            !!searchValue.length &&
            filtered.loaded && <EmptySearchResult value={searchValue} search />}
          {!servicesListBySearch.services.length &&
            !searchValue.length &&
            !isNull(currentCategory) &&
            currentCategory.value !== 'all' &&
            filtered.loaded && (
              <EmptySearchResult value={currentCategory?.text} />
            )}
        </Container>
      </Scrollbars>
      {postOrderResponse === 'OK' && <OrderResponseModal />}
    </>
  );
});
