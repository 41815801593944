/**
 *
 * GradientHeader
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { layout, LayoutProps } from 'styled-system';
import { MFMedia } from '../../styles/media-breakpoints';

type Variant = 'violet' | 'lightgreen' | 'aquablue' | 'lightblue';

type Props = {
  title: string;
  note: string;
  variant: Variant;
  className?: string;
  arrowFunc?: () => void;
} & LayoutProps;

export const GradientHeader = ({
  title,
  note,
  variant,
  arrowFunc,
  ...props
}: Props) => (
  <Header {...props} gradient={variant}>
    <Title>
      {arrowFunc && <BackButton onClick={arrowFunc}>⟵</BackButton>}
      {title}
    </Title>
    <Note>{note}</Note>
  </Header>
);

const gradients = {
  violet: 'linear-gradient(94.67deg, #3C62C5 -10.18%, #C89CD2 98.45%)',
  lightgreen: 'linear-gradient(90.86deg, #9DD95C 46.89%, #28CE39 143.82%)',
  aquablue: 'linear-gradient(94.67deg, #54D8E0 -16.74%, #2389AD 102.66%)',
  lightblue: '#49B3F8',
};

const Header = styled.div.attrs({ role: 'heading' })<{ gradient: Variant }>`
  background: ${(props) => gradients[props.gradient]};
  padding: 15px 52px 0 20px;
  color: #ffffff;
  height: 156px;
  ${layout};
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 500;
`;

const Note = styled.div`
  font-size: 16px;
`;

const BackButton = styled.span`
  cursor: pointer;
  display: none;
  position: absolute;
  left: 10px;
  @media (${MFMedia.smallPC}) {
    display: inline;
  }
`;
