import { ButtonTabs } from 'app/components';
import styled from 'styled-components/macro';
import { ResetButtonMixin } from '../../styles/mixins';

export const Buttons = styled(ButtonTabs)`
  button,
  button:first-child,
  button:last-child {
    padding: 5px 20px;
    color: #999999;
    border-radius: 4px;
    border: 1px solid #999999;
    margin-left: 10px;

    :before {
      display: none;
    }
  }

  &&& > .button-tabs__selected {
    background-color: #e66464;
    color: #ffffff;
    border-color: transparent;
  }
`;

export const SubmitButton = styled.button`
  ${ResetButtonMixin};
  cursor: pointer;
  width: 131px;
  height: 32px;
  background: #64b5f6;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color .3s ease;
  
  &:hover {
    background-color: #58a9ea;
  }
`;

export const DateSection = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 45px;

  .indiana-scroll-container {
    width: 100%;
  }
`;
