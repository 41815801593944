import { useAppDispatch } from 'app/hooks';
import { getServerErrorState } from 'app/store/root-selectors';
import { hideServerError } from 'app/store/root-slice';
import React from 'react';
import { useSelector } from 'react-redux';
import usePortal from 'react-useportal';
import removeIcon from '../../layouts/navigation-layout/assets/remove-icon.svg';
import yellowError from '../../layouts/navigation-layout/assets/yellow-error.svg';

import { Panel, RemoveButton, Row, Title } from './server-error.styled';

export const ServerError = () => {
  const { Portal } = usePortal();
  const dispatch = useAppDispatch();
  const { show, text } = useSelector(getServerErrorState);

  if (!show) {
    return <></>;
  }

  return (
    <Portal>
      <Panel>
        <img src={yellowError} alt={'Ошибка'} />
        <Title>Ошибка</Title>
        {text.map((row, index) => (
          <Row key={index}>{row}</Row>
        ))}
        <RemoveButton onClick={() => dispatch(hideServerError())}>
          <img src={removeIcon} alt={'Закрыть'} />
        </RemoveButton>
      </Panel>
    </Portal>
  );
};
