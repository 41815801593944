import styled from 'styled-components/macro';
import { ResetButtonMixin } from 'app/styles/mixins';
import { Link } from 'react-router-dom';

export const StyledField = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 20px;
  `,
  Eye: styled.button`
    ${ResetButtonMixin};
    position: absolute;
    font-size: 17px;
    top: 13px;
    right: 38px;
    cursor: pointer;
    color: #334d6e;
    width: 26px;
    display: flex;
    justify-content: center;
  `,
  Placeholder: styled.div`
    color: #a7a7a7;
    font-size: 12px;
    margin-left: 10px;
  `,
  Input: styled.input<{ complete: boolean; error: boolean }>`
    width: 100%;
    height: 42px;
    border: 2px solid #ffffff;
    border-bottom: 2px solid #efeff4;
    border-bottom: ${(props) =>
      props.complete
        ? '2px solid #49CF82'
        : props.error
        ? '2px solid #F15044'
        : '2px solid #efeff4'};
    background: #ffffff;
    outline: none;
    padding: 13px 10px;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
    }
  `,
  Error: styled.div`
    margin-left: 10px;
    color: #f54b18;
    font-size: 10px;
  `,
  Icon: styled.div`
    position: absolute;
    //top: 25px;
    right: 5px;
  `,
  BlueLinkWrapper: styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 15px 0;
  `,
  BlueLink: styled(Link)`
    text-decoration: none;
    color: #238fd8;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
  `,
  Button: styled.button`
    ${ResetButtonMixin};
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    border: 2px solid #0d75ed;
    border-radius: 6px;
    color: #0d75ed;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;

    img {
      width: 24px;
    }

    &:disabled {
      cursor: unset;
      opacity: 0.6;
      color: #b6d6fa;
      border: 2px solid #b6d6fa;
    }
  `,
};
