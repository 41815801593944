import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { DateRange, IDateRangeDates } from '../date-range/date-range';

import {
  Buttons,
  SubmitButton,
  DateSection,
  Wrapper,
} from './date-header.styled';

interface Props {
  date_from: Date;
  date_till: Date;
  buttons?: any[];
  showButtons?: boolean;
  onChange?: (item: any) => void;
  onChangeDates: (dates: [Date, Date]) => void;
  onSubmit?: () => void;
  className?: string;
}

export const DateHeader = React.memo(
  ({
    date_from,
    date_till,
    showButtons = true,
    buttons = [],
    onChange = () => {},
    onSubmit,
    onChangeDates,
    className,
  }: Props) => {
    const handleChange = (dates: IDateRangeDates) => onChangeDates(dates);
    return (
      <Wrapper className={className}>
        <ScrollContainer>
          {showButtons && <Buttons onSelect={onChange} items={buttons} />}
        </ScrollContainer>
        <DateSection>
          <DateRange
            dateFrom={date_from}
            dateTill={date_till}
            onChange={handleChange}
          />
          <SubmitButton onClick={onSubmit}>Сформировать</SubmitButton>
        </DateSection>
      </Wrapper>
    );
  }
);
