import styled from 'styled-components/macro';
import { MFMedia } from 'app/styles/media-breakpoints';

export const PortalSectionWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 52px);
  width: 100vw;
  z-index: 400;
  background: rgba(1, 9, 23, 0.15);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);

  @media (${MFMedia.tablet}) {
    height: 100%;
  }
`;
export const PortalSection = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  border: 1px solid #d7d7d7;
`;

export const PortalText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #0b2646;
  padding: 0 20px;
`;

export const PortalCardHeader = styled.p`
  margin: 0;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #f8f9fb;

  @media (${MFMedia.tablet}) {
    height: 68px;
  }
`;

export const PortalCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 8px 8px 0 0;
  overflow-y: scroll;
`;

export const PortalCardFooter = styled.div`
  position: static;
  width: 100%;
  bottom: 0;
  height: 68px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #f8f9fb;

  @media (${MFMedia.tablet}) {
    position: absolute;
  }
`;
export const PortalBtn = styled.button`
  font-family: 'SF Pro normal', sans-serif;
  background: #49994c;
  border-radius: 8px;
  width: 67px;
  height: 36px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const PortalImg = styled.img`
  margin: 0 auto;
  max-width: 256px;
  max-height: 256px;
  width: calc(100% - 130px);

  @media (${MFMedia.tablet}) {
    width: calc(100% - 260px);
  }
`;

export const PopupFinish = styled.section`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  background-color: #f9f9f9;

  @media (${MFMedia.tablet}) {
    width: 450px;
    height: 499px;
    background-color: #ffffff;
    border-radius: 10px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
`;
