import styled from 'styled-components/macro';
import rocket from '../assets/rocket.svg';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Header = styled.div`
  width: 100%;
  font-size: 20px;
  color: #376ac0;
  margin-bottom: 28px;
  font-weight: 600;
`;

export const RedHeader = styled.div`
  width: 100%;
  font-size: 20px;
  color: #ee1e1e;
  margin-bottom: 28px;
  font-weight: 600;
`;

export const Explanation = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 36px;
  font-weight: 400;
`;

export const ContestExplanation = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 24px 0;
  font-weight: 400;
  text-align: center;

  @media (max-width: 320px) {
    margin: 12px 0;
  }
`;

export const ConfidenceLink = styled.a`
  font-size: 14px;
  font-weight: 400;
  color: #62789d;
  text-decoration: underline;
`;

export const FootNote = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-top: 18px;
`;

export const CallAgain = styled.div<{ isTimer: boolean }>`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-top: 36px;
  color: ${(props) => (props.isTimer ? '#62789D' : '#477FFF')};
  cursor: ${(props) => (props.isTimer ? '' : 'pointer')};
`;

export const Finish = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-top: 36px;
  color: #ee1e1e;
  cursor: pointer;
`;

export const AnotherAddress = styled.div`
  color: #477fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
  cursor: pointer;
`;

export const AddressArea = styled.div`
  background-color: #f4f7ff;
  border-radius: 12px;
  color: #163b79;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 12px;
  margin-bottom: 36px;
`;

export const Rocket = styled.img.attrs({
  src: rocket,
})`
  margin-bottom: 36px;

  @media (max-width: 320px) {
    margin-bottom: 12px;
    width: 180px;
  }
`;

export const Greetings = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #376ac0;
  text-align: center;
  line-height: 32px;
  font-style: normal;

  @media (max-width: 320px) {
    font-size: 18px;
  }
`;
