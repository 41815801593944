import styled from 'styled-components/macro';
import Select from 'react-select';
import { magnifyingGlass } from '../../../../assets';
import { MFMedia } from 'app/styles/media-breakpoints';
export const SearchPanelContainer = styled.div`
  padding: 0 0 8px 16px;
  width: calc(100% - 16px);
  display: flex;

  @media (${MFMedia.tablet}) {
    padding: 0;
    width: calc(100vw - 284px);
  }

  @media (${MFMedia.smallPC}) {
    margin: 0 16px;
    width: calc(100vw - 300px);
  }
`;

export const StyledSelect = styled(Select)<{ focusSearchPanel: boolean }>`
  width: 100%;

  .react-select__value-container {
    padding-left: 30px;
    background-image: url(${magnifyingGlass});
    background-size: 12px;
    background-position: 12px 11px;
    background-repeat: no-repeat;
    background-color: #f7f8fa;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .react-select__control {
    border: none;
    box-shadow: none;
  }

  .react-select__placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: ${(props) => (props.focusSearchPanel ? '#e3e8ef' : '#4B5565')};
  }

  .react-select__indicator-separator,
  .react-select__indicator {
    display: none;
  }

  .react-select__menu {
    width: calc(100vw - 100px);
    background-color: #f7f8fa;
  }
`;

export const CancelSearchBtn = styled.button`
  font-family: SF Pro normal, serif;
  margin-left: 16px;
  padding: 0;
  width: max-content;
  background: #ffffff;
  border: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const ReturnBtn = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  white-space: nowrap;

  @media (${MFMedia.tablet}) {
    display: none;
  }
`;
