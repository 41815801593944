import { API } from './api';

export const GetRequestList = (params: any) =>
  API.get('/cabinet/requests/', params);

export const GetRequest = (requestId: string) =>
  API.get(`/cabinet/requests/${requestId}/`);

export const CreateRequest = (data: any) =>
  API.post('/cabinet/requests/', data);

export const UpdateRequest = (requestId: string, data: any) =>
  API.patch(`/cabinet/requests/`, requestId, data);

export const UpdateRequestFiles = (id: string, params: FormData) =>
  API.patchFiles('/cabinet/requests/files/', id, params);

export const GetKindsTree = () =>
  API.get('/forms/requests/kinds_tree/');

export const GetKindsDescriptions = () =>
    API.get('/forms/requests/kinds_description/');