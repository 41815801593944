import { getRandomInt } from 'app/utils/getters';
import * as bgConfig from 'assets/backgrounds/background-config.json';
import * as favConfig from 'assets/favicons/favicon-config.json';

const importBgImage = async (host: string, imageName: string) =>
  await (() => import(`assets/backgrounds/${host}/${imageName}`))();

const importFavImage = async (imageName: string) =>
  await (() => import(`assets/favicons/${imageName}`))();

const backgroundConfig: { [key: string]: any } = bgConfig;

export const getBgImage = async () => {
  const { host } = window.location;
  const config = backgroundConfig.default[host];
  const imageUrls: any[] = [];
  const load = new Promise((resolve, reject) => {
    if (config) {
      config.images.forEach((imageName: string) => {
        importBgImage(host, imageName).then((res) => {
          imageUrls.push(res.default);
          if (imageUrls.length === config.amount) {
            resolve('');
          }
        });
      });
    } else {
      const defaultConfig = backgroundConfig.default.default;
      defaultConfig.images.forEach((imageName: string) => {
        importBgImage('default', imageName).then((res) => {
          imageUrls.push(res.default);
          if (imageUrls.length === defaultConfig.amount) {
            resolve('');
          }
        });
      });
    }
  });
  await load;
  return imageUrls[getRandomInt(0, imageUrls.length)];
};

const faviconConfig: { [key: string]: any } = favConfig;

export const getFaviconImage = async () => {
  const { host } = window.location;
  const config = faviconConfig.default[host];
  let imageUrl = '';
  const load = new Promise((resolve, reject) => {
    if (config) {
      importFavImage(config.favicon).then((res) => {
        imageUrl = res.default;
        resolve('');
      });
    } else {
      const defaultConfig = faviconConfig.default.default;
      importFavImage(defaultConfig.favicon).then((res) => {
        imageUrl = res.default;
        resolve('');
      });
    }
  });
  await load;
  return imageUrl;
};
