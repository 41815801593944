import React, { ButtonHTMLAttributes } from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';
import { StyledButton } from './button.styled';

export type Props = {} & SpaceProps &
  TypographyProps &
  ButtonHTMLAttributes<any>;

export const Button = React.memo(({ children, ...props }: Props) => (
  <StyledButton {...props}>{children}</StyledButton>
));
