import styled from 'styled-components/macro';
import { Props } from './phone-input';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 36px;
`;

export const Input = styled.input<Props>`
  outline: none;
  font-size: 16px;
  width: 100%;
  color: #163b79;
  border-radius: 12px;
  padding: 17px 12px;
  text-transform: lowercase;
  background-color: ${(props) => (props.isError ? '#FFF3F3' : '#F5F6FA')};
  border: ${(props) =>
    props.isError ? '1px solid #EE1E1E' : '1px solid #477fff'};
`;

export const Error = styled.div`
  color: #ee1e1e;
  font-size: 16px;
  font-weight: 400;
  padding-top: 16px;
`;
