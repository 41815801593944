import { MFMedia } from 'app/styles/media-breakpoints';
import { ResetButtonMixin } from '../../styles/mixins';
import styled from 'styled-components/macro';

import closeIcon from './assets/close.svg';
import loadIcon from './assets/download.svg';

export const Load = styled.img.attrs({ src: loadIcon })`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Close = styled.img.attrs({ src: closeIcon })`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Title = styled.div`
  margin-top: 26px;
  color: #334d6e;
  font-size: 23px;
`;

export const List = styled.div<{files: number}>`
  margin-top: 30px;
  height: ${(props) => props.files ? `${props.files*44}px` : '44px'};
  max-height: 200px;
  
  @media(${MFMedia.smallPC}) {
    max-height: 500px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  
  & > span {
    color: #000000;
    font-size: 12px;
  }

  & > button {
    ${ResetButtonMixin};
    cursor: pointer;
  }
`;

export const Fade = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.3;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 101;
`;

export const Wrapper = styled.div<{ isOpen: boolean }>`
  height: max-content;
  max-height: calc(100% - 100px);
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 52px;
  padding: ${(props) => (props.isOpen ? '24px 15px 60px 15px' : '0')};
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
  
  @media (${MFMedia.tablet}) {
    bottom: 0;
    max-height: 100%;
  };

  @media (${MFMedia.smallPC}) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 30px;
    right: unset;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);

    ${Load} {
      margin-left: 20px;
    }
  }
`;
