import styled from 'styled-components/macro';
import { MFMedia } from '../../styles/media-breakpoints';
import { blackArrow } from '../requests/assets';

export const Section = styled.div`
  overflow: hidden;
  width: 100%;
  //height: inherit;
`;

export const Arrow = styled.div`
  &:before {
    content: '⟵';
  }
  height: fit-content;
  position: absolute;
  left: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #4b506d;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;

  @media (${MFMedia.tablet}) {
    &:before {
      content: none;
    }
    background-image: url(${blackArrow});
    background-size: 18px 12px;
    width: 18px;
    height: 12px;
    position: static;
    margin-right: 19px;
  }
`;

export const ArrowLeft = styled.i`
  border: solid #4b5565;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin: 10px;
`;