import styled, { css } from 'styled-components/macro';
import { space, typography } from 'styled-system';

import { Props as ButtonProps } from './button';

const Size = {
  lg: css`
    max-width: 280px;
    height: 47px;
  `,
  md: css`
    max-width: 185px;
    height: 47px;`,
  sm: css``,
};

const Variant = {
  blue: css`
    background-color: #238fd8;
    color: #ffffff;
    border: 1px solid transparent;
  `,
  success: css`
    background-color: #9dd95c;
    color: #ffffff;
    border: 1px solid transparent;
  `,
  lightblue: css`
    background-color: #56c9fa;
    color: #ffffff;
    border: 1px solid transparent;
  `,
  cancel: css`
    background-color: transparent;
    color: #d93030;
    border: 1px solid #56c9fa;
    border-radius: 15px;
  `,
  empty: css`
    background: none;
    border: none;
    color: #5C7695;
  `
};

export const StyledButton = styled.button<ButtonProps>`
  ${(props) => Size[props.size]};
  ${(props) => Variant[props.variant]};
  &&& {
    ${(props) => (props.disabled ? 'background-color: #dadada' : '')};
  }
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  border-radius: ${(props) => (props.br ? '9px' : '0')};
  outline: none;
  font-size: 13px;
  ${space};
  ${typography};
`;
